import React from 'react';
import PropTypes from 'prop-types';

const Input = (props) => (
    <input
        id={props.id}
        type={props.type}
        className={`input ${props.className}`}
        onChange={(e) => props.handleChange(e.target.value)}
        value={props.value}
        placeholder={props.placeholder}
    />
);

Input.propTypes = {
    value: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    id: PropTypes.string,
};

Input.defaultProps = {
    type: 'text',
    value: '',
    placeholder: '',
};

export default Input;
