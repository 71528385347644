import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { RichTextEditor } from 'components';
import { updateStripe } from 'common/actions';

import styles from './index.module.scss';

class EditableTitle extends Component {
    handleChange(rawContentState, id) {
        this.props.updateStripe({
            id,
            title: rawContentState,
        });
    }

    moveTitle(direction) {
        const pos = { ...this.props.currentStripe.titlePosition } || {
            y: 'center',
            x: 'center',
        };

        switch (direction) {
            case 'top':
                if (pos.y === 'bottom') {
                    pos.y = 'center';
                } else {
                    pos.y = 'top';
                }
                break;
            case 'bottom':
                if (pos.y === 'top') {
                    pos.y = 'center';
                } else {
                    pos.y = 'bottom';
                }
                break;
            case 'right':
                if (pos.x === 'left') {
                    pos.x = 'center';
                } else {
                    pos.x = 'right';
                }
                break;
            case 'left':
                if (pos.x === 'right') {
                    pos.x = 'center';
                } else {
                    pos.x = 'left';
                }
                break;
            default:
                break;
        }

        this.props.updateStripe({
            ...this.props.currentStripe,
            titlePosition: pos,
        });
    }

    render() {
        const { className, currentStripe, customizable, size, t } = this.props;

        return (
            <RichTextEditor
                className={`${styles.title} ${className}`}
                initialRawEditorContent={currentStripe.title}
                initialSize={size}
                handleChange={(rawContentState, id) => this.handleChange(rawContentState, id)}
                placeholder={t(`story.items.${currentStripe.type}.placeholder`)}
                customizable={customizable}
                movable={currentStripe.isTitleMovable && customizable}
                onMoveClick={(dir) => this.moveTitle(dir)}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    currentStripe: state.currentStripe,
});

const mapDispatchToProps = (dispatch) => ({
    updateStripe: (stripe) => {
        dispatch(updateStripe(stripe));
    },
});

EditableTitle.propTypes = {
    currentStripe: PropTypes.object.isRequired,
    updateStripe: PropTypes.func.isRequired,
    className: PropTypes.string,
    customizable: PropTypes.bool,
    size: PropTypes.string,
    t: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditableTitle));
