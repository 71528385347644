import moment from 'moment';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import auth from 'common/auth';
import { sendUser } from 'common/user/api';
import {
    getDurationUnit,
    getDurationValue,
    buildIsoDuration,
    getCurrentLicense,
    formatCurrentUserLicense,
    buildUserLicense,
    getDefaultLicense,
} from 'services/licenseService';
import { Select, ValidateButton } from 'components';
import { capture } from 'services/errorMonitoring';

import styles from './index.module.scss';

const getDaysLeftMessage = ({ endDate, startDate, duration }) => {
    if (!(endDate || (startDate && duration))) {
        return 'Cannot calculation remaining days';
    }
    const now = moment();
    const calculatedEndDate = endDate ? moment(endDate) : moment(startDate).add(moment.duration(duration)._data);
    return `${Math.round(moment.duration(calculatedEndDate.diff(now)).asDays())} days left`;
};

class LicenseForm extends Component {
    constructor(props) {
        super(props);
        const { user, licensePlanData } = props;
        const currentLicense = getCurrentLicense(user);
        this.state = {
            updatedUserLicense: currentLicense.origin
                ? formatCurrentUserLicense(currentLicense)
                : getDefaultLicense(licensePlanData),
            err: '',
            message: '',
        };
    }

    handleChange = (key, value) => {
        const updatedUserLicense = {
            ...this.state.updatedUserLicense,
            [key]: value,
        };
        if (key === 'unlimitedAccess' && value) {
            updatedUserLicense.duration = undefined;
            updatedUserLicense.endDate = '';
        }
        if (key === 'duration') {
            updatedUserLicense.endDate = '';
            updatedUserLicense.unlimitedAccess = false;
        }
        if (key === 'endDate') {
            updatedUserLicense.duration = undefined;
            updatedUserLicense.unlimitedAccess = false;
        }
        this.setState({
            updatedUserLicense,
            message: '',
            err: '',
        });
    };

    handleSubmit = (event) => {
        event.preventDefault();
        const { updatedUserLicense } = this.state;
        const { user } = this.props;
        try {
            const newLicense = buildUserLicense(updatedUserLicense, getCurrentLicense(user));
            const updateUser = {
                id: user.id,
                licenses: [newLicense],
            };
            sendUser(updateUser, auth.getToken())
                .then(() =>
                    this.setState({
                        message: 'User has been successfully updated',
                        err: '',
                    })
                )
                .catch((err) => {
                    capture(err, "Couldn't update user license");
                    this.setState({
                        err: err.response.body.error.message,
                    });
                });
        } catch (error) {
            capture(error, "Couldn't update user license");
            this.setState({
                err: error.message,
            });
        }
    };

    render() {
        const {
            updatedUserLicense: {
                origin,
                startDate,
                duration,
                endDate,
                unlimitedAccess,
                prolongationStartDate,
                prolongationDuration,
            },
        } = this.state;
        const { className, licensePlanData } = this.props;
        return (
            <form onSubmit={this.handleSubmit} className={className}>
                <label className={styles.labelContainer}>
                    <div className={styles.label}>Origin</div>
                    <Select
                        value={origin}
                        onChange={(value) => {
                            this.handleChange('origin', value);
                        }}
                        options={licensePlanData.origins.map((origin) => ({
                            value: origin,
                            label: origin,
                        }))}
                    />
                </label>
                <label className={styles.labelContainer}>
                    <div className={styles.label}>Start date</div>
                    <input className={styles.dateField} type="date" value={startDate} disabled />
                </label>
                <label className={styles.labelContainer}>
                    <div className={styles.label}>Duration</div>
                    <input
                        min={0}
                        type="number"
                        value={getDurationValue(duration)}
                        onChange={(event) =>
                            this.handleChange(
                                'duration',
                                buildIsoDuration(event.target.value || 0, getDurationUnit(duration))
                            )
                        }
                    />
                    <Select
                        className={styles.durationUnit}
                        value={getDurationUnit(duration)}
                        onChange={(value) => {
                            this.handleChange('duration', buildIsoDuration(getDurationValue(duration), value));
                        }}
                        options={licensePlanData.durationUnits.map((unit) => ({
                            value: unit,
                            label: unit,
                        }))}
                    />
                    {startDate && duration && (
                        <div className={styles.daysLeft}>
                            {getDaysLeftMessage({
                                startDate,
                                duration,
                            })}
                        </div>
                    )}
                </label>

                <label className={styles.labelContainer}>
                    <div className={styles.label}>End date</div>
                    <input
                        className={styles.dateField}
                        type="date"
                        value={endDate}
                        onChange={(event) => this.handleChange('endDate', event.target.value)}
                    />
                    {endDate && <div className={styles.daysLeft}>{getDaysLeftMessage({ endDate })}</div>}
                </label>
                <div className={styles.labelContainer}>
                    <div className={styles.label}>Unlimited</div>
                    <input
                        type="checkbox"
                        checked={!!unlimitedAccess}
                        onChange={(event) => this.handleChange('unlimitedAccess', event.target.checked)}
                    />
                </div>
                <label className={styles.labelContainer}>
                    <div className={styles.label}>Prolongation start date</div>
                    <input className={styles.dateField} type="date" value={prolongationStartDate} disabled />
                </label>
                <label className={styles.labelContainer}>
                    <div className={styles.label}>Prolongation duration</div>
                    <input
                        min={0}
                        type="number"
                        value={getDurationValue(prolongationDuration)}
                        onChange={(event) =>
                            this.handleChange(
                                'prolongationDuration',
                                buildIsoDuration(event.target.value || 0, getDurationUnit(prolongationDuration))
                            )
                        }
                    />
                    <Select
                        className={styles.durationUnit}
                        value={getDurationUnit(prolongationDuration)}
                        onChange={(value) => {
                            this.handleChange(
                                'prolongationDuration',
                                buildIsoDuration(getDurationValue(prolongationDuration), value)
                            );
                        }}
                        options={licensePlanData.durationUnits.map((unit) => ({
                            value: unit,
                            label: unit,
                        }))}
                    />
                    {prolongationStartDate && prolongationDuration && (
                        <div className={styles.daysLeft}>
                            {getDaysLeftMessage({
                                startDate: prolongationStartDate,
                                duration: prolongationDuration,
                            })}
                        </div>
                    )}
                </label>

                <ValidateButton type="submit" onClick={this.handleSubmit}>
                    Submit
                </ValidateButton>
                <div className={`${styles.message} ${this.state.err ? styles.error : ''}`}>
                    {this.state.err || this.state.message}
                </div>
            </form>
        );
    }
}

LicenseForm.propTypes = {
    className: PropTypes.string,
    licensePlanData: PropTypes.object,
    user: PropTypes.object,
};

export default LicenseForm;
