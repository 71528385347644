import findIndex from 'lodash.findindex';
import { put, select, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { setDesignPopinType, openPublishModal, openTitleModal } from 'common/design/actions';
import { currentPopinTypeSelector } from 'common/design/reducers';

import { setCurrentStripe } from 'common/currentStripe/actions';
import { patchStoryRequest, updateStoryRequest } from 'common/story/saga';
import { storySelector } from 'common/story/reducers';

import designItems, { GIVE_A_TITLE, PUBLISH } from 'config/designItems';

export function* designNextStepRequest() {
    yield call(patchStoryRequest, { message: { isSilent: true } });
    const currentPopinType = yield select(currentPopinTypeSelector);
    if (currentPopinType.name === GIVE_A_TITLE) return yield put(openTitleModal());
    if (currentPopinType.name === PUBLISH) return yield put(openPublishModal());
    const nextPopinTypeIndex = findIndex(designItems, { name: currentPopinType.name }) + 1;
    const popinType = designItems[nextPopinTypeIndex];
    return yield put(setDesignPopinType(popinType));
}

export function* designPreviousStepRequest() {
    yield call(patchStoryRequest, { message: { isSilent: true } });
    const story = yield select(storySelector);
    const currentPopinType = yield select(currentPopinTypeSelector);
    if (currentPopinType.name === designItems[0].name) {
        return yield put(push(`/story/${story.id}`));
    }
    const previousPopinTypeIndex = findIndex(designItems, { name: currentPopinType.name }) - 1;
    const popinType = designItems[previousPopinTypeIndex];
    return yield put(setDesignPopinType(popinType));
}

export function* grantDesignAccessRequest() {
    const story = yield select(storySelector);
    yield call(updateStoryRequest, {
        payload: {
            id: story.id,
            hasDesignAccess: true,
        },
    });
    yield put(setCurrentStripe(story.stripes[0]));
    return yield put(push(`/design/${story.id}`));
}
