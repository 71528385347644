import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import styles from './index.module.scss';

class FactsCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({ value: nextProps.value });
    }

    onChange(name, value) {
        this.setState({ value });
        this.props.handleChange(name, value);
    }

    render() {
        const { title, help, name, className, t } = this.props;
        const { value } = this.state;
        return (
            <div className={className}>
                <div className={styles.card}>
                    <div className={styles.factItem}>
                        <div className={styles.factTitleContainer}>
                            <h3 className={styles.factTitle}>{title}</h3>
                            <p className={styles.factHelp}>{help}</p>
                        </div>
                    </div>
                    <textarea
                        className={styles.factTextarea}
                        value={value}
                        onChange={(e) => this.onChange(name, e.target.value)}
                        placeholder={t('facts.type_here')}
                    />
                </div>
            </div>
        );
    }
}

FactsCard.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    value: PropTypes.string,
    name: PropTypes.string,
    help: PropTypes.any,
    handleChange: PropTypes.func,
    t: PropTypes.func,
};

export default withTranslation()(FactsCard);
