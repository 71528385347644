import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';

import styles from './index.module.scss';

const CancelButton = ({ onClick, children, className, t }) => (
    <button onClick={onClick} className={classNames(styles.button, className)}>
        {children || t('button.cancel')}
    </button>
);

CancelButton.propTypes = {
    onClick: PropTypes.func,
    children: PropTypes.node,
    className: PropTypes.string,
    t: PropTypes.func,
};

export default withTranslation()(CancelButton);
