import { Component, createRef } from 'react';
import debounce from 'lodash.debounce';

// based on https://github.com/digidem/react-dimensions v1.3.1

function getDimensions(element) {
    return [element.clientWidth, element.clientHeight];
}

function Dimensions() {
    return (HocComponent) => {
        return class DimensionsHOC extends Component {
            constructor(props) {
                super(props);
                this.element = createRef();
                this.state = {};
            }

            componentDidMount() {
                this.updateDimensionsImmediate();
                window.addEventListener('resize', this.onResize, false);
            }

            componentWillUnmount() {
                window.removeEventListener('resize', this.onResize);
            }

            updateDimensionsImmediate = () => {
                const dimensions = getDimensions(this.element.current.parentNode);

                if (dimensions[0] !== this.state.containerWidth || dimensions[1] !== this.state.containerHeight) {
                    this.setState({
                        containerWidth: dimensions[0],
                        containerHeight: dimensions[1],
                    });
                }
            };

            updateDimensions = debounce(this.updateDimensionsImmediate, 100);

            onResize = () => {
                if (this.rqf) return;
                this.rqf = window.requestAnimationFrame(() => {
                    this.rqf = null;
                    this.updateDimensions();
                });
            };

            render() {
                const { containerWidth, containerHeight } = this.state;

                const wrapperStyle = {
                    height: '100%',
                    width: '100%',
                    padding: 0,
                    border: 0,
                };
                return (
                    <div style={wrapperStyle} ref={this.element}>
                        {(containerWidth || containerHeight) && <HocComponent {...this.props} {...this.state} />}
                    </div>
                );
            }
        };
    };
}

export default Dimensions;
