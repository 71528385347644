import React from 'react';
import PropTypes from 'prop-types';
import { StoryModal, ActionButton } from 'components';
import { withTranslation } from 'react-i18next';
import styles from './index.module.scss';

const customStyles = {
    content: {
        boxSizing: 'border-box',
        width: '100%',
        maxWidth: '400px',
        minHeight: '100px',
        position: 'relative',
        textAlign: 'center',
        padding: '20px',
    },
};

const MobileWarningModal = ({ isOpen, onRequestClose, t }) => (
    <StoryModal customStyles={customStyles} isOpen={isOpen} onRequestClose={onRequestClose}>
        <div className={styles.title}>{t('mobileWarning.title')}</div>
        <div className={styles.subTitle}>{t('mobileWarning.subTitle')}</div>
        <ActionButton onClick={onRequestClose} className={styles.closeButton}>
            {t('button.ok')}
        </ActionButton>
    </StoryModal>
);

MobileWarningModal.propTypes = {
    isOpen: PropTypes.bool,
    onRequestClose: PropTypes.func,
    t: PropTypes.func,
};

MobileWarningModal.defaultProps = {
    isOpen: false,
    onRequestClose: () => {},
};

export default withTranslation()(MobileWarningModal);
