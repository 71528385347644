import en from './locale-en';
import fr from './locale-fr';

const translations = {
    en: {
        translation: en,
    },
    fr: {
        translation: fr,
    },
};

export default translations;
