import types from './actionTypes';

const initialState = {
    user: {},
    error: {},
};

export default function update(state = initialState, action) {
    switch (action.type) {
        case types.SET_USER:
            return { ...state, user: action.user };
        case types.SET_LOGIN_ERROR:
            return { ...state, error: action.payload };
        case types.RESET_LOGIN_ERROR:
            return { ...state, error: {} };
        default:
            return state;
    }
}
