import { put, select, call } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import { authenticatedRequest } from 'common/middlewares';
import { capture } from 'services/errorMonitoring';
import { displayToastrFromSaga as displayToastr } from 'services/toastrService';
import { accessSelector } from './reducers';
import { setBookAccessCredentials } from './actions';
import { getBookAccess, updateBookAccess } from './api';

export function* getBookAccessRequest() {
    const access = yield authenticatedRequest(getBookAccess);
    yield put(setBookAccessCredentials(access[0]));
}

export function* updateBookAccessRequest() {
    try {
        const access = yield select(accessSelector);
        yield authenticatedRequest(updateBookAccess, access);
        yield call(displayToastr, 'save.saved');
    } catch (error) {
        yield call(displayToastr, 'save.error', toastr.error);
        capture(error, "Couldn't update book access");
    }
}
