import React from 'react';
import PropTypes from 'prop-types';
import ReduxToastr from 'react-redux-toastr';

/* css copied from react-redux-toastr and adapted to current needs:
 * => success, error messages
 * => bottom-left and "bottom center" (for editor) positions
 * if 7.4.1 version is replaced or usage modified css should be updated
 */
import './index.scss';

const Toastr = ({ position }) => (
    <ReduxToastr
        timeOut={2000}
        newestOnTop={false}
        preventDuplicates
        position={position}
        transitionIn="fadeIn"
        transitionOut="fadeOut"
    />
);

Toastr.defaultProps = {
    position: 'bottom-center',
};

Toastr.propTypes = {
    position: PropTypes.string,
};

export default Toastr;
