const trans = {
    congratulations: 'Félicitations !',
    column: 'colonne',
    line: 'ligne',
    configure_your_stripe: 'Configurer votre slide',
    public: 'Public',
    private: 'Privé',
    french: 'Français',
    english: 'Anglais',
    update: {
        success: 'Mis à jour avec succès',
        failure: 'Mise à jour a échoué',
    },
    button: {
        my_pitches: 'Tous mes pitchs',
        add_story: 'Créer un nouveau pitch',
        export: 'Exporter en PPTX',
        next: 'Suivant',
        back: 'Retour',
        preview: 'Visualiser',
        add_stripe: 'Ajouter une slide',
        customize_stripe: 'Modifier cette slide',
        browse: 'Parcourir',
        ok: 'ok',
        cancel: 'annuler',
        delete: 'supprimer',
        remove_background_image: "Supprimer l'image",
        save_crop: 'Valider la sélection',
        share: 'Partager',
        name: 'Nommer',
        copy: 'Copier',
        save: 'Sauvegarder',
        later: 'Plus tard',
        start: 'Démarrer',
        reset: 'Réinitialiser',
        reload: 'Recharger',
    },
    app: {
        error: 'Oups, il y a eu une erreur, merci de recharger la page',
        offline: 'Attention : votre connexion ne semble pas stable',
    },
    browerNotSupported: {
        title: "Désolés, votre navigateur est incompatible avec l'application Storymakers.",
        subTitle:
            'Nous vous recommandons de créer vos pitchs sur Mac ou PC, avec les navigateurs Chrome ou Firefox à jour.',
    },
    mobileWarning: {
        title: "Désolés, l'application Storymakers n'est pas optimisée pour un usage sur mobile ou tablette.",
        subTitle:
            'Nous vous recommandons de créer vos pitchs sur Mac ou PC, avec les navigateurs Chrome ou Firefox à jour.',
    },
    saveError: {
        title: 'Une erreur est survenue.',
        subTitle: 'Merci de recharger la page.',
    },
    homepage: {
        welcome: '{{name}}, \n bienvenue.',
        your_stories: 'mes pitchs',
        logout: 'Se déconnecter',
        story_delete: {
            message: 'Voulez-vous vraiment supprimer ce pitch ?',
            success: 'Votre pitch a été supprimé',
            error: "Votre pitch n'a pas pu être supprimé",
        },
    },
    login: {
        title: 'Connectez-vous à votre compte',
        email: 'Adresse email',
        password: 'Mot de passe',
        error:
            "Oups, il semble que vous n'avez pas entré le bon email ou mot de passe. Vous avez du mal à retrouver l'email associé à votre compte ? Nous sommes là pour vous aider à hello@storymakers.eu",
        terms_and_conditions_accept: "J'accepte les",
        terms_and_conditions: 'Conditions générales',
        book_image_title: "Vous n'avez pas encore le livre ?",
        book_image_sub_title: 'Découvrez tout ce qu’il faut savoir pour créer un pitch percutant.',
        button: {
            amazon: "J'achète le livre",
            login: 'Connexion',
        },
        lost_password: 'Mot de passe oublié ?',
        new_password: {
            title: "C'est bon ! Vous êtes prêt pour vous connecter",
            subtitle: "Merci d'utiliser votre nouveau mot de passe",
        },
        register: {
            link: 'Créez-le ici',
            message: "Vous n'avez pas de compte ?",
        },
    },
    start_with_book: {
        title: "Tester l'appli avec mon livre",
        subtitle:
            "Avec votre livre, vous avez un accès gratuit à l'offre premium de notre appli pour 7 jours. Aucune information de paiement demandée !",
        placeholder: {
            first_name: 'Prénom',
            last_name: 'Nom',
            email: 'Email',
            password: 'Mot de passe',
            password_confirmation: 'Confirmation',
            word_from_book: 'Dans votre livre, quel est le dernier mot à la page {{pageNumber}} ?',
        },
        button: 'Je démarre avec mon livre',
        error: {
            default: "Oups, une erreur s'est glissée",
            key_word: "Désolés, vous n'avez pas entré le bon mot…",
            email:
                "Un compte existe déjà pour cette adresse email. Si c'est la vôtre, connectez-vous avec votre mot de passe. ",
        },
        login: {
            link: 'Connectez-vous ici',
            message: 'Vous avez déjà un compte ?',
        },
    },
    lost_password: {
        title: 'Réinitialiser votre mot de passe',
        subtitle:
            'Pas de souci ! Nous allons vous envoyer des instructions pour changer votre mot de passe. Si vous n’avez plus accès à votre email, nous sommes là pour vous aider à hello@storymakers.eu',
        button: 'Réinitialiser votre mot de passe',
        placeholder: 'Votre adresse email',
        error: {
            default: "Oups, une erreur s'est glissée",
            email:
                'Désolés, nous n’avons pas trouvé de compte avec cet email. Vous avez du mal à retrouver l’email associé à votre compte ? Nous sommes là pour vous aider à hello@storymakers.eu',
        },
        confirmation: {
            title: 'C’est fait !',
            subtitle:
                "Nous avons envoyé un email à l'adresse {{email}} avec des instructions pour changer de mot de passe.\n\nSi notre message n’apparaît pas rapidement dans votre messagerie, merci de vérifier votre boîte de spam.",
        },
    },
    reset_password: {
        title: 'Changer votre mot de passe',
        button: 'Envoyer',
        placeholder: {
            password: 'Nouveau mot de passe',
            password_confirmation: 'Confirmation de mot de passe',
        },
        error: {
            default: "Ce lien n'est plus actif. Merci de demander un nouveau lien de réinitialisation.",
            link: 'Cliquez ici',
        },
    },
    license: {
        modal: {
            title: 'Votre abonnement a expiré…',
            subTitle:
                'Mais parce qu’on est contents de vous revoir – et que vous voulez peut-être accéder à vos pitchs, on est ravis de vous offrir {{duration}} d’accès en plus*.',
            expirationSubTitle:
                'On est bien tristes de ne plus vous compter parmi nous. Si vous voulez créer de nouveaux pitchs avec l’application, contactez-nous pour renouveler votre abonnement.',
            footer: {
                contactUs: 'Pour prolonger votre abonnement : ',
                contactUsLink: 'contactez-nous',
                exportPPTX: 'Si vous nous quittez, pensez à exporter vos pitchs au format PPTX.',
            },
            button: {
                accept: "Génial, j'en profite",
                interested: 'Oui, je suis intéressé !',
                decline: 'Non merci',
            },
            error: 'Oups, il y a eu une erreur, merci de faire une nouvelle demande',
        },
    },
    step: {
        facts: 'FACTS',
        story: 'STORY',
        design: 'DESIGN',
    },
    intro: {
        title_line1: 'Suivez les 3 étapes du',
        title_line2: 'FAST DESIGN Canvas™',
    },
    pitch: {
        fill_notes: {
            title: 'Maintenant, vous pouvez \n résumer votre projet en \n remplissant les champs libres.',
        },
        label: {
            subject: 'Je crée ce pitch pour présenter',
            innovation: "L'innovation ou l'idée remarquable que je veux partager est",
            hero: "L'(les) innovateur (s) de mon pitch est (sont)",
            target: "Le message s'adresse à",
            action: 'Et à la fin de mon pitch je voudrais que mon public',
        },
    },
    facts: {
        items: {
            facts: {
                title: 'Brief',
                position: '1/2',
                instructions:
                    "La séquence Facts vous permet de créer votre brief. Vous pouvez remplir ces boîtes dans l'ordre que vous souhaitez.\n\n Les 6 questions vous permettront de vous concentrer sur les informations clés à inclure dans votre pitch. Une fois que vous aurez clarifié vos idées ici, la construction de votre récit vous semblera bien plus simple à l'étape suivante.",
                tip:
                    'Si vous préparez votre pitch avec plusieurs personnes, nous vous recommandons dans un premier temps de répondre à ces questions individuellement. Idéalement, consacrez 5 à 15 minutes à cette page.',
                example: `- Sujet : le nouveau Programme d'innovation de La Firme
        - Innovation : favoriser une culture d'innovation à La Firme à travers des ateliers de design
        - Cible : les 1500 employés de La Firme
        - Action : s'inscrire à la prochaine session de formation
        - Contenus : citations du PDG de La Firme et des participants du Programme, une courte vidéo du lancement, le lien vers le formulaire d'enregistrement pour la prochaine session du Programme d'innovation
        - Images : photos de l'événement de lancement du Programme, gros plans des participants et du PDG, visuels des prototypes créés par les participants `,
            },
            pitch: {
                title: 'Pitch de base',
                position: '2/2',
                instructions:
                    "Une fois que vous avez défini votre public cible et l'intention de votre pitch, voyez comment vous pouvez résumer tout cela en 4 phrases courtes. \n\n Ce sont les premières fondations de votre histoire, vous êtes en bonne voie !",
                tip:
                    "Pour ceux qui travaillent en groupe, voici le moment où vous pouvez comparer et ajuster vos réponses afin d'être certains que vous êtes tous d'accord sur les objectifs de votre pitch.",
                example: `Je crée ce pitch pour présenter le nouveau Programme d'innovation de La Firme
        L'innovation ou l'idée remarquable que je veux partager est la nouvelle culture d'innovation de la Firme
        Le message s'adresse aux 1500 employés de La Firme
        Et à la fin de mon pitch je voudrais que mon public s'inscrive à la prochaine session de formation`,
            },
        },
        fill_notes: {
            title: "Remplissez ces notes \n pour vous concentrer sur l'essentiel",
            what: {
                title: 'Quoi ?',
                help:
                    'Quel est le sujet de votre pitch ‒ et quelle idée ou point remarquable allez-vous mettre en avant ?',
            },
            for_whom: {
                title: 'Pour qui ?',
                help:
                    'Pour qui allez-vous faire ce pitch ? Soyez précis sur le public visé et l’action que vous souhaitez qu’il accomplisse à la fin de votre pitch.',
            },
            with_what: {
                title: 'Avec quoi ?',
                help:
                    'Avec quoi allez-vous créer ce pitch ? Énumérez les contenus et images qui pourraient vous être utiles.',
            },
        },
        facts_items: {
            subject: {
                title: 'sujet',
                help:
                    'À propos de quel projet, événement, produit… voulez-vous créer un pitch ? \n Résumez ceci en 3 à 5 mots',
            },
            innovation: {
                title: 'innovation',
                help: 'Quelle innovation ou idée remarquable est mise en oeuvre dans ce projet, événement, produit…?',
            },
            target: {
                title: 'cible',
                help: 'À quel public sera présenté ce pitch ? \n Soyez précis.',
            },
            action: {
                title: 'action',
                help: 'Quelle action attendez-vous de votre public cible ?',
            },
            hero: {
                title: 'innovateur',
                help: "Qui porte l'innovation ou l'idée remarquable ? Ce peut être une personne, équipe, organisation…",
            },
            content: {
                title: 'contenus',
                help:
                    'Existe-t-il des citations (de vos clients, utilisateurs, partenaires), des documents, vidéos ou pages web que vous pourriez ajouter à votre pitch ? \n Listez-les ici.',
            },
            pictures: {
                title: 'images',
                help:
                    'Identifiez une large sélection de visuels que vous pourriez utiliser pour ce pitch (photos, schémas simples, illustrations) ou préparez-vous à en créer. \n Listez-les ici.',
            },
        },
        type_here: 'Tapez ici',
    },
    story: {
        instructions: 'Instructions',
        tip: 'Conseils',
        example: 'Exemple',
        items: {
            title: {
                placeholder: 'Tapez votre texte ici',
                title: 'Titre',
                position: '',
                instructions: 'Donnez un titre court et percutant à votre Story.',
                tip: "Vous pouvez revenir à toutes vos stripes plus tard, donc faites simple pour l'instant.",
                example: "Le Programme d'innovation de La Firme",
            },
            context: {
                placeholder: 'Tapez votre texte ici',
                title: 'Contexte',
                position: '1/7',
                instructions:
                    'Écrivez 1 ou 2 lignes sur le contexte du pitch (une situation, tendance, circonstance), en lien avec le problème ou point de douleur auquel répond votre solution (projet, événement, produit…).\n Le début de votre pitch doit répondre à au moins 2 des 3 questions suivantes : Qui ? Quand ? Où ?',
                tip:
                    "Utilisez une ou deux slides pour cela. 1 slide = 1 idée, jamais plus ! Si vous avez 2 idées, créez 2 slides, en utilisant le bouton « Ajouter une slide » en bas à gauche de l'écran.\n Vous pouvez revenir à toutes vos slides plus tard, donc faites simple pour l'instant",
                example: "Aujourd'hui, La Firme lance un nouveau Programme d'accélération de l'innovation.",
            },
            challenge: {
                placeholder: 'Tapez votre texte ici',
                title: 'Problème',
                position: '2/7',
                instructions: 'Quel est le problème à résoudre ? Ce problème doit découler directement du contexte.',
                tip:
                    "Idéalement c'est un défi auquel votre public est également confronté ou pour lequel il peut avoir de l'empathie.",
                example:
                    'Car nous sommes tous confrontés à un défi de taille : innover dans un contexte de disruption.',
            },
            news: {
                placeholder: 'Tapez votre texte ici',
                title: 'Bonne nouvelle \n& mission supérieure',
                position: '3/7',
                instructions:
                    "Annoncez la bonne nouvelle : vous avez une solution ! Dites brièvement que le problème a été relevé (ou qu'il peut l'être) et par qui (une personne, une équipe, une organisation…). Précisez aussi la motivation - ou mission supérieure - qui sous-tend la solution.",
                tip:
                    "Ne rentrez pas dans les détails ici. L'objectif de cette slide est de susciter l'intérêt de votre public pour votre solution et votre démarche. Créez du suspense, donnez-leur envie de faire défiler votre pitch !",
                example:
                    "Chez La Firme nous avons relevé ce défi. Nous croyons que l'innovation doit être portée par chacun.",
            },
            proposition: {
                placeholder: 'Tapez votre texte ici',
                title: 'Proposition de valeur',
                position: '4/7',
                instructions: 'Formulez la proposition de valeur de votre solution en une phrase seulement.',
                tip:
                    'Votre proposition de valeur doit être à la fois courte et exhaustive. Voici la structure la plus courante : <Nom du projet> est un outil / une méthode / une solution / un événement qui permet <à usager cible> de <réaliser telle action>.',
                example:
                    'Le Programme Innovation est une formation pour les managers qui veulent développer leur leadership entrepreneurial.',
            },
            solution: {
                placeholder: 'Tapez votre texte ici',
                title: 'Étapes vers la solution',
                position: '5/7',
                instructions: 'Expliquez comment votre solution fonctionne.',
                tip:
                    "Donnez un aperçu d'ensemble du processus ou les étapes vers la solution sur une slide. Puis détaillez brièvement les différentes étapes et actions qui ont été prises sur plusieurs slides, en utilisant le bouton « ajouter une slide » situé en bas à gauche de l'écran.",
                example: `Slide 1 : M. X, PDG de La Firme, a lancé le Programme Innovation avec Harvard ! Une session de 3 mois pour notre équipe de direction.
   Slide 2 : La méthode ? Un processus en 5 étapes.
   Slide 3 : Etape #1 Savoir raisonner comme un entrepreneur.
   Slide 4 : Etape #2 …`,
            },
            outcome: {
                placeholder: 'Tapez votre texte ici',
                title: 'Résultat positif',
                position: '6/7',
                instructions:
                    'Quel est le résultat positif (attendu ou obtenu) pour les personnes qui pourraient adopter cette solution ?',
                tip:
                    'Vous pouvez associer ce résultat avec un autre projet, événement, produit...qui ouvre de nouvelles possibilités pour le public.',
                example:
                    '50 manageurs ont déjà rejoint la session #1 de notre Programme. Ils ont initié 10 projets remarquables qui seront développés à présent dans notre entreprise.',
            },
            action: {
                placeholder: 'Tapez votre texte ici',
                title: "Appel à l'action",
                position: '7/7',
                instructions:
                    "Rédigez un appel à l'action pour votre public.\n Quel rôle chacun peut-il avoir dans cette histoire ? Comment peut-on participer, soutenir, suivre ce projet, événement, entreprise…? ",
                tip: "Formulez l'appel à l'action de manière très simple et claire.",
                example:
                    'Vous voulez rejoindre les innovateurs de La Firme ? Les inscriptions sont ouvertes pour la session #2 du Programme. Contactez-nous ! ',
            },
        },
    },
    design: {
        access_modal: {
            title: "Bravo ! Vous avez atteint la fin de l'étape « Story » - c'était le plus dur !",
            content:
                "Prêts pour réaliser le design de votre pitch ? Passez à l'étape suivante !\n Vous pourrez revenir aux étapes Facts et Story à tout moment.",
        },
        data: 'Données',
        caption: 'Légende',
        background_image: 'Image de fond',
        background_color: 'Couleur de fond',
        stripe_behaviour: 'Comportement de la slide',
        image_behaviour: "comportement de l'image",
        color_filter: 'Filtre couleur',
        scroll: 'Défile',
        fixed: 'Fixe',
        dark: 'Sombre',
        light: 'Clair',
        dark_color: 'Noir',
        light_color: 'Blanc',
        none: 'Aucun',
        cover: 'En arrière-plan',
        contain: 'Intégrale',
        // image_drag_and_drop: 'Glissez-déposez\nvotre image ici',
        image_drag_and_drop: 'Sélectionnez votre image',
        error: {
            file_size: 'Ce fichier est trop lourd, les images doivent faire max. 20 Mo.',
            file_type: 'Merci de ne télécharger que des images',
        },
        iframe_label: 'Insérer une vidéo',
        iframe_sublabel: 'Celle-ci doit être hébergée sur Dailymotion, Vimeo ou YouTube',
        iframe_placeholder: 'Copiez-collez le code de votre iframe ici',
        choose_stripe: 'Choisissez votre slide',
        stripe_type: {
            plain: 'Simple',
            key_data: 'Données clés',
            transition: 'Transition',
            quotation: 'Citation',
            video: 'Vidéo',
        },
        link_modal: {
            title: 'Créer un lien',
            input: 'Entrez une URL',
            validate: 'OK',
            placeholder: 'http://',
        },
        quotation: {
            placeholder: {
                title: 'Tapez votre texte ici',
                quotation: 'Tapez votre citation ici',
                author: 'Nom de la personne citée',
            },
            button: {
                new: 'Nouvelle \n citation',
            },
        },
        editor: {
            title: 'Titre',
            subtitle: 'Sous-titre',
            paragraph: 'Paragraphe',
        },
        items: {
            add_pictures: {
                title: 'Images',
                position: '1/9',
                example:
                    'Photos du lieu du Programme, des participants (portraits et photos de groupe), des prototypes.',
                instructions:
                    "Insérez des images dans votre pitch. \nQuels visuels pourriez-vous ajouter aux slides existantes pour leur donner plus d'impact ?",
                tip: `- Vous pouvez télécharger des visuels dans vos slides à tout moment en utilisant le bouton « Modifier cette slide » situé en haut à droite de l'écran. Si tous vos visuels ne sont pas prêts, vous pouvez revenir à cette étape plus tard.
   - Trouvez des photos gratuites sur unsplash.com
   - Combinez different types de visuels : portraits, photos sur le vif, photos de groupes, paysages et objets, infographies, illustrations, photos d'arrière-plan très simples.
    Si possible, sélectionnez des photos de personnes qui ont réellement participé au projet, événement, produit… que vous présentez.`,
            },
            insert_key_data: {
                title: 'Données clés',
                position: '2/9',
                instructions: `Donnez de la saillance aux données et aux mots clés.
   Pour accroître l'impact visuel de votre message, accentuez certains concepts, chiffres, actions.`,
                tip: `- Utilisez au moins 2 slides « Données clés » dans votre pitch. Vous pouvez transformer une slide existante en une slide « Données clés » en la sélectionnant dans la colonne de gauche puis en cliquant sur le bouton « Données clés » dans le menu du haut. Vous pouvez aussi ajouter de nouvelles slides à votre pitch et les transformer de la même manière.
   - Les slides « Données clés » fonctionnent souvent bien dans les sections « Contexte », « Etapes vers la solution » et « Résultat positif ».`,
            },
            shape_your_story: {
                title: 'Mise en forme',
                position: '3/9',
                instructions: `Formatez votre contenu et réduisez-le.
   Améliorez la qualité visuelle de votre contenu. Quels mots ou phrases pouvez-vous supprimer, raccourcir, ré-ordonner ?`,
                tip:
                    "Le vide est aussi important que le contenu - faites de la place…pour y mettre du vide ! Et ne révélez pas trop de détails, cela permettra à votre public d'imaginer une nouvelle histoire dans son propre contexte.",
            },
            core_message: {
                title: 'Message principal',
                position: '4/9',
                example:
                    'Démarrez votre pitch avec une phrase engageante : « La Firme est prête pour le changement. Et vous ? » ',
                instructions: `Améliorez la trame du pitch.
   Faites défiler votre pitch et vérifiez que les transitions entre les titres principaux sont fluides. Ils doivent se lire comme des phrases qui se suivent naturellement.`,
                tip: `- Votre public peut-il comprendre le message principal du pitch en 20 secondes ?
   - Utilisez des connecteurs (mais, parce que, donc…), des indicateurs spatio-temporels  (aujourd'hui, en 2017, le mois prochain…), et des slides « Transition » pour créer un argumentaire clair et logique. Vous pouvez transformer une slide existante en une slide « Transition » en la sélectionnant dans la colonne de gauche puis en cliquant sur le bouton « Transition » dans le menu du haut. Vous pouvez aussi ajouter de nouvelles slides à votre pitch et les transformer de la même manière.
   - Prenez le temps de retravailler votre première slide : elle doit être engageante et intrigante, et donner envie à votre public de faire défiler le pitch.`,
            },
            secondary_message: {
                title: 'Message secondaire',
                position: '5/9',
                example:
                    "Ajoutez quelques faits supplémentaires sur les 10 projets remarquables initiés par les manageurs (À quel domaine d'innovation appartiennent-ils ? À quel stade de développement sont-ils ? Comment s'intitulent-ils ?…). Ces éléments peuvent figurer dans une slide « Données clés » par exemple.",
                instructions: `Concentrez-vous de nouveau sur les faits.
   Votre public peut-il trouver toutes les informations qu'il pourrait rechercher en lisant les sous-titres et les légendes ?`,
                tip: `- Sur la dernière slide de votre pitch pensez à insérer des informations de contact, des formulaires d'enregistrement, ou toute autre information qui pourrait être pertinente pour compléter votre appel à l'action.
   - Utilisez des liens hypertextes pour donner un accès rapide aux adresses email, aux formulaires en ligne ou autres pages web.`,
            },
            videos_and_resources: {
                title: 'Ressources',
                position: '6/9',
                example:
                    'Insérez des ressources en lien avec Le Programme (études de cas, recommandations de lecture, blogs…).',
                instructions: `Mettez une cerise sur le gâteau !
   Quelle information supplémentaire (pages web ou fichiers PDF) votre public pourrait-il être ravi de trouver ?`,
                tip:
                    'À ce stade vous devez vous focaliser plus que tout sur les intérêts de votre public. Identifiez des resources en ligne ou des fichiers à télécharger sur un sujet précis.',
            },
            lists: {
                title: 'Listes',
                position: '7/9',
                instructions: `Créez des listes courtes.
   Tous les lecteurs sont attirés par des informations nouvelles et pertinentes. Donnez plus de relief aux informations clés en utilisant des puces ou en créant des listes numérotées.`,
                tip: "Dans l'idéal retenez trois items par liste, ni plus, ni moins !",
            },
            references: {
                title: 'Références',
                position: '8/9',
                example:
                    "Sélectionnez une courte citation du PDG de La Firme dans laquelle il partage sa vision de l'avenir de l'entreprise.",
                instructions: `Insérez des témoignages.
   Quels retours positifs pouvez-vous partager sur votre projet, événement, produit…?`,
                tip:
                    "Les références et les témoignages clients ont plus de poids que les faits. Ajoutez des citations courtes de vos partenaires, vos clients ou sponsors… Pour insérer une citation, cliquez sur le bouton « Ajouter une slide » en bas à gauche de l'écran puis sur le bouton « Citation » dans le menu du haut.",
            },
            engagement: {
                title: 'Engagement',
                position: '9/9',
                instructions: `Donnez l'opportunité à votre public d'avoir un rôle dans cette histoire.
   Expliquez comment chacun peut rejoindre votre projet, soutenir votre idée, s'inscrire à votre événement (en tant que participant, client, spectateur, partenaire…).`,
                tip:
                    'Faites-les rêver avec vous ! Choisissez des formulations engageantes comme  : Et si on développait ensemble un nouveau… ? Si vous rejoignez… / Si vous souscrivez… / nous pourrions faire … et … ensemble !',
            },
            give_a_title: {
                title: 'Presque fini ! \n Nommez votre pitch',
                instructions:
                    "Donnez un titre court à votre pitch ainsi qu'un sous-titre. Ils apparaîtront dans le pied de page de votre pitch en ligne.",
                tip:
                    "Lorsque vous cherchez un bon titre (et sous-titre), vous pouvez par la même occasion trouver un slogan pour votre projet. Accordez-vous quelques minutes pour y réfléchir. Un titre simple peut aussi très bien faire l'affaire (après tout il ne s'agit ici que du pied de page !).",
            },
            publish: {
                title: 'Votre pitch est prêt à partager !',
                instructions:
                    "Que ce soit votre premier brouillon ou une version avancée de votre pitch, la meilleure manière de savoir s'il est clair et convaincant est de le partager avec d'autres personnes. Alors n'attendez plus et collectez autant de retours que possible !",
                tip:
                    "Avant de peaufiner la première vesion de votre pitch, recueillez les avis de quelques personnes de confiance. Demandez-leur  de vous indiquer les faiblesses de votre pitch ainsi que des idées d'améliorations.",
            },
        },
    },
    name: {
        title: 'Presque fini ! \n Nommez votre pitch',
        label: {
            title: "Donnez un titre à votre pitch (il s'affichera dans le pied de page)",
            subtitle: 'Ajoutez un sous-titre (il apparaîtra juste en-dessous)',
            credits: 'Ajoutez les crédits (pour vos crédits photo et crédits de réalisation)',
        },
    },
    publish: {
        title: 'Partagez votre pitch',
        description_public:
            'Formidable, votre pitch est prêt à être partagé ! \n Voici un lien à partager avec les personnes de votre choix.',
        description_private:
            "Ce pitch est juste pour vous (pour le moment !). \n En mode privé, le lien URL ne sera pas accessible à d'autres personnes.",
        label_public: "Copier l'adresse du lien",
        label_private: 'Vous devez choisir le mode public pour partager votre pitch',
        message: {
            copied: 'Copié dans le presse-papiers',
        },
        label: {
            title: "Donnez un titre à votre pitch (il s'affichera dans le pied de page)",
            subtitle: 'Ajoutez un sous-titre (il apparaîtra juste en-dessous)',
            credits: 'Ajoutez les crédits (pour vos crédits photo et crédits de réalisation)',
        },
    },
    footer: {
        credits: 'Crédits',
        propelled_by: 'Proudly propelled by',
    },
    register: {
        title: 'Enregistrer un nouvel utilisateur',
    },
    save: {
        in_progress: 'Enregistrement en cours…',
        saved: 'Modifications enregistrées',
        error_reload:
            "Vos modifications n'ont pas été enregistrées. Si le problème persiste merci de recharger la page.",
        error: "Erreur de connexion, vos modifications n'ont pas été enregistrées",
    },
    export: {
        downloaded: 'Pitch téléchargé',
        error: 'Erreur de téléchargement, merci de re-tenter !',
    },
    preview: {
        title: 'FÉLICITATIONS, VOTRE PITCH EST PRÊT A ÊTRE PARTAGÉ',
        content:
            "Vous pouvez copier l'URL ci-dessus et partager votre pitch avec vos contacts.\n\n" +
            "Si vous avez besoin d'accéder à votre pitch hors-ligne, " +
            "vous pouvez l'exporter sous la forme d'un fichier ZIP." +
            ' Il sera consultable à tout moment.',
        warning: 'AVIS AUX UTILISATEURS DE WINDOWS :',
        warning_content: " n'oubliez pas de dézipper (extraire) le fichier !",
        validate_button: 'Oui, exporter',
        cancel_button: 'Non merci',
        download: {
            title: 'Nous avons zippé tous les fichiers de votre pitch, vous pouvez maintenant le télécharger.',
            validate_button: 'Télécharger votre pitch !',
            cancel_button: 'Annuler',
        },
    },
    book_acces: {
        title: 'Accès via le livre',
        page_number: 'Numéro de page',
        english_word: 'Le mot en anglais',
        french_word: 'Le mot en français',
    },
    form: {
        error: {
            required_field: 'Champ requis',
            min_character: 'Merci d’entrer au moins {{charNumber}} caractères',
            identical_passwords: 'Entrez 2 mots de passe identiques',
            invalid_email: 'Adresse mail invalide',
        },
    },
};

export default trans;
