import actionTypes from './actionTypes';
import storyActionTypes from '../story/actionTypes';

const initialState = {};

export default function update(state = initialState, action) {
    switch (action.type) {
        case storyActionTypes.SET_STRIPE_DESIGN:
            return state.id === action.payload.id
                ? {
                      ...state,
                      ...action.payload,
                  }
                : state;
        case storyActionTypes.UPDATE_STRIPE:
            return state.id === action.payload.id
                ? {
                      ...state,
                      ...action.payload,
                  }
                : state;
        case actionTypes.SET_CURRENT_STRIPE:
        case storyActionTypes.ADD_STRIPE:
            return action.payload;
        default:
            return state;
    }
}

export function currentStripeSelector(state) {
    return state.currentStripe;
}
