import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './index.module.scss';

const ValidateButton = (props) => {
    const { onClick, children, className, type } = props;
    return (
        <button
            {...props}
            type={type}
            onClick={onClick}
            className={classNames(styles.button, className, props.disabled ? styles.disabled : {})}
        >
            {children}
        </button>
    );
};

ValidateButton.propTypes = {
    onClick: PropTypes.func,
    children: PropTypes.node,
    className: PropTypes.string,
    type: PropTypes.string,
    disabled: PropTypes.bool,
};

ValidateButton.defaultProps = {
    type: 'button',
};

export default ValidateButton;
