import throttle from 'lodash.throttle';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { BackButton, NextButton, HelpZone, Header, Toastr } from 'components';
import { updateStoryFacts, updateStoryRequest } from 'common/actions';
import factsItems from 'config/factsItems';
import { FACTS } from 'config/steps';
import FactsCard from './components/FactsCard';

import styles from './index.module.scss';

class FactsPage extends Component {
    constructor(props) {
        super(props);
        this.facts = {
            ...props.story.facts,
        };

        const saveStoryChanges = () => this.props.updateStoryFacts(this.facts);
        this.throttledSaveStoryChanges = throttle(saveStoryChanges, 1000, {
            trailing: true,
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.facts = {
            ...nextProps.story.facts,
            ...this.facts,
        };
    }

    handleChange(name, value) {
        this.facts[name] = value;
        this.throttledSaveStoryChanges();
    }

    previousStep() {
        const { story } = this.props;
        this.props.updateStoryRequest(
            {
                id: story.id,
                facts: this.facts,
            },
            `/intro/${story.id}`
        );
    }

    nextStep() {
        const { story } = this.props;
        this.props.updateStoryRequest(
            {
                id: story.id,
                facts: this.facts,
            },
            `/pitch/${story.id}`
        );
    }

    render() {
        const { t } = this.props;
        const elements = [
            'what',
            { isFactItem: true, key: 0 },
            { isFactItem: true, key: 1 },
            'for_whom',
            { isFactItem: true, key: 2 },
            { isFactItem: true, key: 3 },
            'with_what',
            { isFactItem: true, key: 4 },
            { isFactItem: true, key: 5 },
        ];
        return (
            <div className="factsPage">
                <Header currentStep={FACTS} />
                <div className="factsContainer">
                    <div className="factsContent">
                        <h1 className="title">{t('facts.fill_notes.title')}</h1>
                        {elements.map((element) =>
                            element.isFactItem ? (
                                <FactsCard
                                    key={element.key}
                                    name={factsItems[element.key]}
                                    value={this.facts[factsItems[element.key]]}
                                    className={styles.cardContainer}
                                    title={t(`facts.facts_items.${factsItems[element.key]}.title`)}
                                    help={t(`facts.facts_items.${factsItems[element.key]}.help`)}
                                    handleChange={(name, value) => this.handleChange(name, value)}
                                />
                            ) : (
                                <div key={element} className={styles.cardContainer}>
                                    <div className={styles.howTo}>
                                        <h2 className={styles.intro}>{t(`facts.fill_notes.${element}.title`)}</h2>
                                        <span className={styles.info}>{t(`facts.fill_notes.${element}.help`)}</span>
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                    <HelpZone page={FACTS} type="facts" className="factsHelpZone" />
                </div>
                <div className="buttons">
                    <div className="button">
                        <BackButton onClick={() => this.previousStep()} />
                    </div>
                    <div className="button">
                        <NextButton onClick={() => this.nextStep()} />
                    </div>
                </div>
                <Toastr position="bottom-left" />
            </div>
        );
    }
}

FactsPage.propTypes = {
    history: PropTypes.object,
    story: PropTypes.object,
    updateStoryRequest: PropTypes.func,
    updateStoryFacts: PropTypes.func,
    t: PropTypes.func,
};

const mapStateToProps = (state) => ({
    story: state.story,
});

const mapDispatchToProps = (dispatch) => ({
    updateStoryRequest: (story, redirectUrl) => {
        dispatch(updateStoryRequest(story, redirectUrl, { isSilent: true }));
    },
    updateStoryFacts: (facts) => dispatch(updateStoryFacts(facts)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FactsPage)));
