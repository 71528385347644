import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Icon, Textarea } from 'components';
import getTextColor from 'services/colorService';
import { updateStripeQuotationSize } from 'common/story/actions';

import styles from './index.module.scss';

class QuotationBox extends Component {
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { dimensionAware, exportNumber, stripe, index } = this.props;
        // the dimensions of the box are needed during the pptx generation
        // the sidebar stripes are used for the mesurement (with the props "dimensionAware")
        // when the pptx generation is launched the exportNumber is incremented
        // when the exportNumber changes the mesurements are made and the quotationBoxDimensions props is updated
        // the api call is made only if all quotationBoxDimensions are available
        // after the pptx generation the quotationBoxDimensions props is reset
        // idem for: titleBoxDimensions and dataBoxDimensions
        if (dimensionAware && exportNumber !== nextProps.exportNumber) {
            const { offsetHeight, offsetWidth } = this.boxContainer;
            const { offsetHeight: quotationHeight } = this.quotationContainer;
            const quotationBoxDimensions = {
                width: offsetWidth,
                height: offsetHeight,
                quotationHeight,
            };
            this.props.updateStripeQuotationSize({
                stripeId: stripe.id,
                quotationIndex: index,
                quotationBoxDimensions,
            });
        }
    }

    render() {
        const { deletable, onDelete, handleChange, editable, stripe, index, t } = this.props;
        const quotation = stripe.quotations[index];
        const color = getTextColor(stripe);
        return (
            <div
                className={classNames(
                    {
                        [styles.quotationBox]: true,
                        [styles.quotationBoxDouble]: deletable,
                        [styles.quotationBoxEdit]: editable,
                    },
                    styles[`${color}Color`]
                )}
                ref={(node) => (this.boxContainer = node)}
            >
                <Icon className={styles.iconQuotationMark} iconName="quotation_mark" />
                <div className={styles.quotationText}>
                    {editable ? (
                        <Textarea
                            className={`${styles.quotation} ${styles[`${color}Color`]}`}
                            size="small"
                            minRows={2}
                            placeholder={t('design.quotation.placeholder.quotation')}
                            value={quotation.quotation}
                            handleChange={(value) => handleChange(value, 'quotation')}
                        />
                    ) : (
                        <p className={styles.quotationDisplay} ref={(node) => (this.quotationContainer = node)}>
                            {`${quotation.quotation} `}
                        </p>
                    )}
                    {editable ? (
                        <Textarea
                            className={`${styles.author} ${styles[`${color}Color`]}`}
                            size="small"
                            value={quotation.author}
                            placeholder={t('design.quotation.placeholder.author')}
                            handleChange={(value) => handleChange(value, 'author')}
                        />
                    ) : (
                        <p className={styles.authorDisplay}>{`${quotation.author} `}</p>
                    )}
                    {editable && deletable && (
                        <button className={styles.delete} onClick={() => onDelete()}>
                            {t('button.delete')}
                        </button>
                    )}
                </div>
            </div>
        );
    }
}

QuotationBox.propTypes = {
    deletable: PropTypes.bool,
    editable: PropTypes.bool,
    onDelete: PropTypes.func,
    handleChange: PropTypes.func,
    updateStripeQuotationSize: PropTypes.func,
    stripe: PropTypes.object,
    index: PropTypes.number,
    exportNumber: PropTypes.number,
    dimensionAware: PropTypes.bool,
    t: PropTypes.func,
};

const mapStateToProps = (state) => ({
    exportNumber: state.export.exportNumber,
});

const mapDispatchToProps = (dispatch) => ({
    updateStripeQuotationSize: (stripe) => {
        dispatch(updateStripeQuotationSize(stripe));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(QuotationBox));
