import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';

const StoryModalLabel = (props) => (
    <label className={styles.label} htmlFor={props.name}>
        {props.label}
        {props.comment && <span className={styles.comment}>{` (${props.comment})`}</span>}
    </label>
);

StoryModalLabel.propTypes = {
    label: PropTypes.string.isRequired,
    comment: PropTypes.string,
    name: PropTypes.string.isRequired,
};

export default StoryModalLabel;
