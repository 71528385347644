import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { BackButton, NextButton } from 'components';
import { closeDesignAccessModal, grantDesignAccessRequest } from 'common/actions';

import styles from './index.module.scss';

const DesignAccessModal = ({ t, closeDesignAccessModal, grantDesignAccessRequest }) => (
    <div className={styles.container}>
        <div className={styles.title}>{t('design.access_modal.title')}</div>
        <div className={styles.content}>{t('design.access_modal.content')}</div>
        <div className={styles.buttons}>
            <BackButton onClick={() => closeDesignAccessModal()} />
            <NextButton onClick={() => grantDesignAccessRequest()} className={styles.nextButton} />
        </div>
    </div>
);

DesignAccessModal.propTypes = {
    closeDesignAccessModal: PropTypes.func,
    grantDesignAccessRequest: PropTypes.func,
    t: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
    closeDesignAccessModal: () => {
        dispatch(closeDesignAccessModal());
    },
    grantDesignAccessRequest: () => {
        dispatch(grantDesignAccessRequest());
    },
});

export default connect(null, mapDispatchToProps)(withTranslation()(DesignAccessModal));
