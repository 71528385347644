import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Icon } from 'components';
import { withTranslation } from 'react-i18next';

import styles from './index.module.scss';

const BackToHome = ({ className, history, t }) => (
    <div className={`${styles.container} ${className}`} onClick={() => history.push('/')}>
        <Icon className={styles.icon} iconName="allpitches" />
        <div>{t('button.my_pitches')}</div>
    </div>
);

BackToHome.propTypes = {
    className: PropTypes.string,
    history: PropTypes.object,
    t: PropTypes.func,
};

BackToHome.defaultProps = {
    className: '',
};

export default withRouter(withTranslation()(BackToHome));
