import { PLAIN, KEY_DATA, TRANSITION, QUOTATION } from 'config/stripeTypes';

export default function isStripeExportReady(stripe) {
    const { design, iframe, titleBoxDimensions, data, dataBoxDimensions, quotations, quotationBoxDimensions } = stripe;
    if (design === PLAIN) {
        if (iframe) return true;
        return !!(titleBoxDimensions && titleBoxDimensions.height);
    }
    if (design === TRANSITION) return !!(titleBoxDimensions && titleBoxDimensions.height);
    if (design === KEY_DATA) {
        if (!(titleBoxDimensions && titleBoxDimensions.height)) return false;
        let hasAllDataBoxDimensions = true;
        data.forEach((line, lineIndex) =>
            line.forEach((box, boxIndex) => {
                if (!(dataBoxDimensions[lineIndex] && dataBoxDimensions[lineIndex][boxIndex])) {
                    hasAllDataBoxDimensions = false;
                }
            })
        );
        return hasAllDataBoxDimensions;
    }
    if (design === QUOTATION) {
        if (!(titleBoxDimensions && titleBoxDimensions.height)) return false;
        let hasAllQuotationBoxDimensions = true;
        quotations.forEach((quotations, quoationIndex) => {
            if (!quotationBoxDimensions[quoationIndex]) hasAllQuotationBoxDimensions = false;
        });
        return hasAllQuotationBoxDimensions;
    }
    return true;
}
