import { call, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import auth from 'common/auth';
import paths from 'config/paths';
import { capture } from 'services/errorMonitoring';

export function* authenticatedRequest(method, ...args) {
    try {
        const token = yield auth.getToken();
        const results = yield call(method, ...args, token);
        return results;
    } catch (error) {
        capture(error, "Couldn't make an authenticated request");
        if (error.status === 401) {
            yield auth.reset();
            yield put(push(paths.LOGIN));
        } else {
            throw error;
        }
    }
}
