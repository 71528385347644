import React from 'react';
import PropTypes from 'prop-types';
import { RichTextEditor } from 'components';
import classNames from 'classnames';

import styles from './index.module.scss';

const DisplayedTitle = (props) => {
    const { className, title, style, size, dimensionAware, stripe } = props;
    return (
        <div style={style} className={classNames({ [styles.absolute]: style })}>
            <RichTextEditor
                className={`${styles.title} ${className}`}
                initialRawEditorContent={title}
                initialSize={size}
                readOnly
                dimensionAware={dimensionAware}
                stripe={stripe}
            />
        </div>
    );
};

DisplayedTitle.propTypes = {
    title: PropTypes.any,
    className: PropTypes.string,
    style: PropTypes.object,
    size: PropTypes.string,
    dimensionAware: PropTypes.bool,
    stripe: PropTypes.object,
};

DisplayedTitle.defaultProps = {
    dimensionAware: false,
};

export default DisplayedTitle;
