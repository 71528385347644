const design = {
    DEFAULT_STRIPE_BACKGROUND: '#A2B4BC',
    MAIN_COLOR: '#032330',
    WHITE_COLOR: '#FFFFFF',
    // QUOTATION_STRIPE_BACKGROUND: '#0b191f', // obsolete
    BASE_STRIPE_WIDTH: 1024,
    BASE_STRIPE_HEIGHT: 693,
    FOOTER_WIDTH: 1280,
    FOOTER_HEIGHT: 420,
    TRANSITION_EDITOR_HEIGHT: 150,
    BACKGROUND_SCROLL: 'scroll',
    BACKGROUND_FIXED: 'fixed',
    IMAGE_COVER: 'cover',
    IMAGE_CONTAIN: 'contain',
    LIGHT: 'light',
    DARK: 'dark',
    FILTER_DARK: 'dark',
    FILTER_DARK_RBGA: 'rgba(3, 35, 48, 0.65)',
    FILTER_LIGHT: 'light',
    FILTER_LIGHT_RBGA: 'rgba(255, 255, 255, 0.65)',
    FILTER_NONE: 'none',
    HIGHLIGHT_COLOR: '#ebff0f',
    IMAGE_TYPE: {
        THUMBNAIL: 'THUMBNAIL',
        EDITOR: 'EDITOR',
        PREVIEW: 'PREVIEW',
        CROP: 'CROP',
    },
};

export default design;
