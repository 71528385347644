import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'components';
import styles from './index.module.scss';

class PitchItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value,
        };
    }

    // @todo: is state needed?
    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({ value: nextProps.value });
    }

    handleChange(name, value) {
        this.setState({ value });
        this.props.handleChange(name, value);
    }

    render() {
        const { label, name, index } = this.props;
        return (
            <div className={styles.pitchItem}>
                <div className={styles.number}>
                    <div>{index}</div>
                </div>
                <label className={styles.label}>{label}</label>
                <Input
                    className={styles.pitchInput}
                    handleChange={(value) => this.handleChange(name, value)}
                    value={this.state.value}
                />
            </div>
        );
    }
}

PitchItem.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    name: PropTypes.string,
    index: PropTypes.number,
    handleChange: PropTypes.func,
};

export default PitchItem;
