import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import auth from 'common/auth';
import { sendUser } from 'common/user/api';
import { logoutRequest } from 'common/user/actions';
import PitchItem from 'pages/PitchPage/PitchItem';
import ValidateButton from 'components/buttons/ValidateButton';
import { capture } from 'services/errorMonitoring';

import styles from './index.module.scss';
import pitchStyles from '../../pages/PitchPage/PitchItem/index.module.scss';

class UserForm extends Component {
    constructor(props) {
        super(props);
        this.state = { err: '', message: '', storyMaker: {} };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!nextProps.user) {
            return;
        }

        this.setState({
            ...this.state,
            storyMaker: nextProps.user,
        });
    }

    sendStoryMaker(user) {
        sendUser(user, auth.getToken())
            .then(() =>
                this.setState({
                    message: 'User has been successfully updated',
                    err: '',
                })
            )
            .catch((err) => {
                capture(err, "Couldn't update storymaker");
                if (err.status === 401) return this.props.logoutRequest();
                return this.setState({
                    err: err.response.body.error.message,
                });
            });
    }

    handleChange(key, val) {
        this.setState({
            message: '',
            err: '',
            storyMaker: {
                ...this.state.storyMaker,
                [key]: val,
            },
        });
    }

    render() {
        var fields = [
            { label: 'firstname', name: 'firstName' },
            { label: 'lastname', name: 'lastName' },
            { label: 'email', name: 'email' },
            { label: 'password', name: 'password' },
        ];

        var storyMaker = this.state.storyMaker;

        return (
            <form>
                {fields.map((field, index) => (
                    <PitchItem
                        key={index}
                        label={field.label}
                        name={field.name}
                        index={index + 1}
                        value={storyMaker[field.name]}
                        handleChange={(key, val) => this.handleChange(key, val)}
                    />
                ))}
                <div className={pitchStyles.pitchItem}>
                    <div className={pitchStyles.number}>
                        <div>5</div>
                    </div>
                    <label className={pitchStyles.label}>suspended</label>
                    <input
                        type="checkbox"
                        className={`${pitchStyles.pitchInput} ${styles.userFormCheckbox}`}
                        checked={storyMaker.suspended}
                        onClick={() => this.handleChange('suspended', !storyMaker.suspended)}
                    />
                </div>

                <ValidateButton onClick={() => this.sendStoryMaker(storyMaker)}>Submit</ValidateButton>
                <div className={styles.userFormMessage}>{this.state.err || this.state.message}</div>
            </form>
        );
    }
}

UserForm.propTypes = {
    logoutRequest: PropTypes.func,
    user: PropTypes.object,
};

UserForm.defaultProps = {
    user: { suspended: false },
};

const mapDispatchToProps = (dispatch) => ({
    logoutRequest: () => {
        dispatch(logoutRequest());
    },
});

export default connect(null, mapDispatchToProps)(UserForm);
