import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import { isInStoryTunnel } from 'services/storyService';
import { patchStoryRequest } from 'common/actions';
import { FACTS, STORY, DESIGN } from 'config/steps';

import styles from './index.module.scss';

const STEPS = [{ name: FACTS, isCompleted: true }, { name: STORY, isCurrent: true }, { name: DESIGN }];

const ProgressBar = ({ story, story: { id }, className, currentStep, patchStoryRequest, t }) => {
    function isCompleted(step) {
        if (step === FACTS) {
            return true;
        }
        if (!isInStoryTunnel(story)) return true;
        return false;
    }

    function navigateTo(step) {
        if (step !== currentStep && isCompleted(step)) patchStoryRequest(`/${step}/${id}`);
    }

    return (
        <div className={`${styles.container} ${className}`}>
            {STEPS.map(({ name }, index) => (
                <div
                    key={name}
                    onClick={() => navigateTo(name)}
                    className={classNames(
                        styles.section,
                        !(currentStep === name) && isCompleted(name) ? styles.sectionClickable : '',
                        isCompleted(name) ? styles.sectionCompleted : '',
                        currentStep === name ? styles.sectionCurrent : ''
                    )}
                >
                    <div className={styles.header}>
                        <div className={styles.title}>{`0${index + 1}. ${t(`step.${name}`)}`}</div>
                    </div>
                    <div className={styles.bar} />
                </div>
            ))}
        </div>
    );
};

const mapStateToProps = (state) => ({
    story: state.story,
});

const mapDispatchToProps = (dispatch) => ({
    patchStoryRequest: (redirectUrl) => {
        dispatch(patchStoryRequest(redirectUrl, { isSilent: true }));
    },
});

ProgressBar.propTypes = {
    story: PropTypes.object,
    currentStep: PropTypes.string,
    className: PropTypes.string,
    t: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ProgressBar));
