import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Icon } from 'components';

import styles from './index.module.scss';

const NextButton = ({ withArrow, onClick, className, children, disabled, t }) => (
    <button
        onClick={() => !disabled && onClick()}
        className={classNames(styles.button, className, disabled ? styles.disabled : {})}
    >
        {children ? children : t('button.next')}
        {withArrow && <Icon className={styles.icon} iconName="arrow" />}
    </button>
);

NextButton.propTypes = {
    withArrow: PropTypes.bool,
    onClick: PropTypes.func,
    className: PropTypes.string,
    children: PropTypes.node,
    disabled: PropTypes.bool,
    t: PropTypes.func,
};

NextButton.defaultProps = {
    withArrow: true,
    disabled: false,
};

export default withTranslation()(NextButton);
