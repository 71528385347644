import React from 'react';
import PropTypes from 'prop-types';
import { Loader, StoryModal } from 'components';

const ExportModal = ({ isOpen, onRequestClose }) => (
    <StoryModal isOpen={isOpen} onRequestClose={onRequestClose}>
        <Loader />
    </StoryModal>
);

ExportModal.propTypes = {
    isOpen: PropTypes.bool,
    onRequestClose: PropTypes.func,
};

ExportModal.defaultProps = {
    isOpen: false,
    onRequestClose: () => {},
};

export default ExportModal;
