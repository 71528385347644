import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import paths from 'config/paths';
import { Input, ValidateButton, LoginBox, Link, LostPasswordModal, LicenseExpirationModal } from 'components';
import { loginRequest, resetLoginError } from 'common/actions';

import styles from './index.module.scss';

class LoginPage extends Component {
    static propTypes = {
        resetLoginError: PropTypes.func.isRequired,
        loginRequest: PropTypes.func.isRequired,
        loginError: PropTypes.string,
    };

    static getDerivedStateFromProps(props) {
        return props.suspended ? { isExpirationModalOpen: true } : {};
    }

    constructor(props) {
        super(props);
        const {
            location: { state },
        } = props;
        this.state = {
            email: !!state && !!state.email ? state.email : '',
            password: '',
            isPasswordModalOpen: false,
            isExpirationModalOpen: false,
        };
    }

    componentDidMount() {
        this.props.resetLoginError();
    }

    login(e) {
        e.preventDefault();
        const redirectTo = this.props.location.state?.from?.pathname;
        const { email, password } = this.state;
        this.props.loginRequest({ email, password }, redirectTo);
    }

    togglePasswordModal = () => {
        const { isPasswordModalOpen } = this.state;
        this.setState({ isPasswordModalOpen: !isPasswordModalOpen });
    };

    closeExpirationModal = () => {
        this.props.resetLoginError();
        this.setState({ isExpirationModalOpen: false });
    };

    render() {
        const {
            t,
            loginError,
            locale,
            location: { state },
        } = this.props;
        const { isPasswordModalOpen, isExpirationModalOpen } = this.state;
        const loginWithNewPassword = !!state && !!state.email;
        return (
            <div className={styles.container}>
                <LicenseExpirationModal isOpen={isExpirationModalOpen} onRequestClose={this.closeExpirationModal} />
                <LostPasswordModal
                    locale={locale}
                    isOpen={isPasswordModalOpen}
                    onRequestClose={this.togglePasswordModal}
                />
                <div className={styles.content}>
                    <LoginBox withVisual={!loginWithNewPassword} height="400px">
                        <form onSubmit={(e) => this.login(e)} className={styles.formContainer}>
                            <div className={classNames('modalTitle', loginWithNewPassword ? '' : styles.loginTitle)}>
                                {loginWithNewPassword ? t('login.new_password.title') : t('login.title')}
                            </div>
                            {loginWithNewPassword && (
                                <div className="modalSubtitle">{t('login.new_password.subtitle')}</div>
                            )}
                            <Input
                                placeholder={t('login.email')}
                                type="email"
                                handleChange={(value) => this.setState({ email: value.toLowerCase() })}
                                className="loginInput"
                                value={this.state.email}
                            />
                            <Input
                                placeholder={t('login.password')}
                                type="password"
                                handleChange={(value) => this.setState({ password: value })}
                                className="loginInput"
                                value={this.state.password}
                            />
                            <div className={`errorMessage ${styles.loginError}`}>{loginError && t('login.error')}</div>
                            <ValidateButton type="submit" className={styles.button}>
                                {t('login.button.login')}
                            </ValidateButton>
                        </form>
                        <div className={styles.links}>
                            <Link onClick={this.togglePasswordModal} link={t('login.lost_password')} />
                            <Link
                                to={paths.START_WITH_BOOK}
                                link={t('login.register.link')}
                                message={t('login.register.message')}
                            />
                        </div>
                    </LoginBox>
                </div>
            </div>
        );
    }
}

LoginPage.propTypes = {
    location: PropTypes.object,
    t: PropTypes.func,
};

const mapStateToProps = (state) => {
    console.log(state.user, 'user');
    return {
        loginError: state.user.error?.login,
        suspended: state.user.error?.suspended,
        locale: state.translations.locale,
    };
};

const mapDispatchToProps = (dispatch) => ({
    loginRequest: (user, redirectTo) => {
        dispatch(loginRequest(user, redirectTo));
    },
    resetLoginError: () => {
        dispatch(resetLoginError());
    },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LoginPage)));
