import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'components';
import { withTranslation } from 'react-i18next';

import styles from './index.module.scss';

function AddQuotationButton(props) {
    const { t, onClick } = props;
    return (
        <div onClick={onClick} className={styles.button}>
            <Icon className={styles.icon} iconName="add" />
            <div>{t('design.quotation.button.new')}</div>
        </div>
    );
}

AddQuotationButton.propTypes = {
    onClick: PropTypes.func,
    t: PropTypes.func,
};

export default withTranslation()(AddQuotationButton);
