import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
    AddButton,
    BackButton,
    NextButton,
    DraggableStripeContainer,
    Storybar,
    EditorContainer,
    StripeFactory,
    StoryModal,
    Toastr,
    Icon,
} from 'components';
import { DESIGN } from 'config/steps';
import designItems, { GIVE_A_TITLE, PUBLISH } from 'config/designItems';
import TitleForm from './components/TitleForm';
import {
    designNextStepRequest,
    designPreviousStepRequest,
    addStripeRequest,
    closeTitleModal,
    setDesignPopinType,
} from 'common/actions';
import { cancelTimer } from 'services/timerService';

import styles from './index.module.scss';

const publishDesignItem = designItems.find((item) => item.name === PUBLISH);

class DesignPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            timer: undefined,
        };
    }

    componentWillUnmount() {
        cancelTimer(this.timer);
        this.props.setDesignPopinType(designItems[0]);
    }

    closeAndNexStep = () => {
        const { closeTitleModal, setDesignPopinType } = this.props;
        closeTitleModal();
        cancelTimer(this.timer);
        this.setState({ timer: setTimeout(() => setDesignPopinType(publishDesignItem), 500) });
    };

    render() {
        const { currentStripe, addStripe, nextStep, previousStep, design, t } = this.props;
        const { name: designName, subTypes } = design.popinType;
        return (
            <div className={styles.storyContainer}>
                <StoryModal isOpen={design.titleModalIsOpen} onRequestClose={() => this.closeAndNexStep()}>
                    <TitleForm onRequestClose={() => this.closeAndNexStep()} />
                </StoryModal>
                <Storybar>
                    <DraggableStripeContainer allStripesDraggable allStripesDeletable />
                    <AddButton onClick={() => addStripe()} />
                </Storybar>
                <EditorContainer
                    page={DESIGN}
                    type={designName || designItems[0].name}
                    isStep={false}
                    subTypes={subTypes}
                    currentStep={DESIGN}
                >
                    {StripeFactory.editor(currentStripe, true, true)}
                    <Toastr />
                </EditorContainer>
                <div className="buttons">
                    <div className="button">
                        <BackButton onClick={() => previousStep()} />
                    </div>
                    <div className="button">
                        {designName !== GIVE_A_TITLE && designName !== PUBLISH && (
                            <NextButton onClick={() => nextStep()} />
                        )}
                        {designName === GIVE_A_TITLE && (
                            <NextButton withArrow={false} onClick={() => nextStep()}>
                                {t('button.name')}
                            </NextButton>
                        )}
                        {designName === PUBLISH && (
                            <NextButton withArrow={false} onClick={() => nextStep()}>
                                <Icon className={styles.icon} iconName="share" />
                                {t('button.share')}
                            </NextButton>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

DesignPage.propTypes = {
    design: PropTypes.object,
    currentStripe: PropTypes.object,
    addStripe: PropTypes.func,
    nextStep: PropTypes.func,
    previousStep: PropTypes.func,
    closeTitleModal: PropTypes.func,
    setDesignPopinType: PropTypes.func.isRequired,
    t: PropTypes.func,
};

const mapStateToProps = (state) => ({
    design: state.design,
    currentStripe: state.currentStripe,
});

const mapDispatchToProps = (dispatch) => ({
    nextStep: () => {
        dispatch(designNextStepRequest());
    },
    previousStep: () => {
        dispatch(designPreviousStepRequest());
    },
    addStripe: () => {
        dispatch(addStripeRequest());
    },
    closeTitleModal: () => {
        dispatch(closeTitleModal());
    },
    setDesignPopinType: (type) => dispatch(setDesignPopinType(type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DesignPage));
