import request from 'superagent';
import config from '../config';

export function loginUser(email, password) {
    return request
        .post(`${config.baseUrl}/storymakers/login`)
        .send({ email, password })
        .then((response) => response.body);
}

export function fetchUser(userId, tokenKey) {
    return request
        .get(`${config.baseUrl}/storymakers/${userId}`)
        .retry(2)
        .set('Authorization', tokenKey)
        .then((response) => response.body);
}

export function checkToken(tokenKey) {
    return request
        .get(`${config.baseUrl}/storymakers/check-token/`)
        .set('Authorization', tokenKey)
        .then((response) => response.body);
}

export function fetchUsers(limit, skip, search, tokenKey) {
    return request
        .get(
            `${config.baseUrl}/storymakers/?${
                search ? `filter[where][$text][search]=${search}` : `filter[limit]=${limit}&filter[skip]=${skip}`
            }`
        )
        .set('Authorization', tokenKey)
        .then((response) => response.body);
}

export function countUsers(tokenKey) {
    return request
        .get(`${config.baseUrl}/storymakers/count`)
        .set('Authorization', tokenKey)
        .then((response) => response.body);
}

export function logoutUser(tokenKey) {
    return request
        .post(`${config.baseUrl}/storymakers/logout`)
        .set('Authorization', tokenKey)
        .then((response) => response.body);
}

export function resetPassword(email) {
    return request
        .post(`${config.baseUrl}/storymakers/reset-password`)
        .send(email)
        .then((response) => response.body);
}

export function sendUser(user, tokenKey) {
    return request
        .put(`${config.baseUrl}/Storymakers`)
        .set('Authorization', tokenKey)
        .send(user)
        .then((response) => response.body);
}
