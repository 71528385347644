import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import styles from './index.module.scss';

const StoryModal = (props) => (
    <Modal
        ariaHideApp={false}
        className={`${styles.modal} ${props.className}`}
        overlayClassName={styles.modalOverlay}
        isOpen={props.isOpen}
        onRequestClose={() => props.onRequestClose()}
        style={props.customStyles}
    >
        {props.children}
    </Modal>
);

StoryModal.propTypes = {
    children: PropTypes.node,
    isOpen: PropTypes.bool,
    onRequestClose: PropTypes.func,
    className: PropTypes.string,
    customStyles: PropTypes.object,
};

export default StoryModal;
