import React, { lazy, Suspense } from 'react';
import { Loader } from 'components';

const RichTextEditor = lazy(() => import('./RichTextEditor'));

const LazyLoadedRichTextEditor = (props) => (
    <Suspense fallback={<Loader />}>
        <RichTextEditor {...props} />
    </Suspense>
);

export default LazyLoadedRichTextEditor;
