import React, { Component, lazy, Suspense } from 'react';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { initMonitoring } from 'services/errorMonitoring';
import history from 'common/history';
import paths from 'config/paths';
import {
    AppPageContainer,
    AppRoute,
    BrowserNotSupportedModal,
    MobileWarningModal,
    FallbackComponent,
    PageContainer,
    Loader,
} from 'components';
import { HomePage, LoginPage, IntroPage, FactsPage, PitchPage, StoryPage, DesignPage } from './pages';
import createStore from 'common/store';
import { ErrorBoundary } from 'services/errorMonitoring';
import { isMobile, isBrowserCompatible } from 'services/platformService';

import './resources/index.scss';

const StartWithBookPage = lazy(() => import('pages/StartWithBookPage'));
const NewPasswordPage = lazy(() => import('pages/NewPasswordPage'));
const AdminPage = lazy(() => import('pages/AdminPage'));
const AccountPage = lazy(() => import('pages/AccountPage'));
const PreviewPage = lazy(() => import('pages/PreviewPage'));

initMonitoring(history);

const { store, persistor } = createStore(history);

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayMobileWarning: isMobile,
            displayBrowserWarning: !isBrowserCompatible,
        };
    }

    closeMobileModal() {
        this.setState({ displayMobileWarning: false });
    }

    closeBrowserModal() {
        this.setState({ displayBrowserWarning: false });
    }

    render() {
        return (
            <ErrorBoundary fallback={FallbackComponent}>
                <Provider store={store}>
                    <PersistGate loading={<Loader fullPage />} persistor={persistor}>
                        <ConnectedRouter history={history}>
                            <Suspense fallback={<Loader fullPage />}>
                                <DndProvider backend={HTML5Backend}>
                                    <Switch>
                                        <Route path={paths.PREVIEW}>
                                            <PageContainer>
                                                <AppRoute
                                                    path={`${paths.PREVIEW}:storyId/:stripeId?`}
                                                    component={PreviewPage}
                                                />
                                            </PageContainer>
                                        </Route>
                                        <Route path="/">
                                            <AppPageContainer>
                                                {isMobile && (
                                                    <MobileWarningModal
                                                        isOpen={this.state.displayMobileWarning}
                                                        onRequestClose={() => this.closeMobileModal()}
                                                    />
                                                )}
                                                {!isMobile && !isBrowserCompatible && (
                                                    <BrowserNotSupportedModal
                                                        isOpen={this.state.displayBrowserWarning}
                                                        onRequestClose={() => this.closeBrowserModal()}
                                                    />
                                                )}
                                                <AppRoute exact isPrivate path="/" component={HomePage} />
                                                <AppRoute path={paths.LOGIN} component={LoginPage} />
                                                <AppRoute path={paths.START_WITH_BOOK} component={StartWithBookPage} />
                                                <AppRoute path={paths.RESET_PASSWORD} component={NewPasswordPage} />
                                                <AppRoute isPrivate path="/intro/:storyId" component={IntroPage} />
                                                <AppRoute isPrivate path="/facts/:storyId" component={FactsPage} />
                                                <AppRoute isPrivate path="/pitch/:storyId" component={PitchPage} />
                                                <AppRoute isPrivate path="/story/:storyId" component={StoryPage} />
                                                <AppRoute isPrivate path="/design/:storyId" component={DesignPage} />
                                                <AppRoute isPrivate path="/register" component={AdminPage} />
                                                <AppRoute isPrivate path="/account/:userId" component={AccountPage} />
                                            </AppPageContainer>
                                        </Route>
                                    </Switch>
                                </DndProvider>
                            </Suspense>
                        </ConnectedRouter>
                    </PersistGate>
                </Provider>
            </ErrorBoundary>
        );
    }
}

export default App;
