import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Icon } from 'components';
import styles from './index.module.scss';

const ConfirmationMessage = ({ children, className, isError }) => (
    <div className={classNames(styles.container, className, isError ? styles.error : '')}>
        {!isError && <Icon className={styles.icon} iconName="check" />}
        <div>{children}</div>
    </div>
);

ConfirmationMessage.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    isError: PropTypes.bool,
};

ConfirmationMessage.defaultProps = {
    children: '',
    className: '',
    isError: false,
};

export default ConfirmationMessage;
