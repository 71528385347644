import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Icon } from 'components';
import i18n from 'i18n';

import styles from './index.module.scss';
import combinedStyles from './combined.module.scss';

class CombinedHelpZone extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openType: props.types[0],
        };
    }

    toggleOpen(type) {
        const { openType } = this.state;
        this.setState({ openType: type === openType ? null : type });
    }

    render() {
        const { openType } = this.state;
        const { className, page, types, t } = this.props;
        return (
            <div className={`${combinedStyles.container} ${className}`}>
                <div className={combinedStyles.header}>
                    <div className={styles.step}>{t(`step.${page}`)}</div>
                </div>
                <div className={combinedStyles.content}>
                    {types.map((type, index) => (
                        <Fragment key={type}>
                            <div
                                className={`${combinedStyles.titleContainer} ${
                                    type === openType ? combinedStyles.openTitleContainer : ''
                                }`}
                                onClick={() => {
                                    this.toggleOpen(type);
                                }}
                            >
                                <div className={combinedStyles.position}>{`0${index + 1}.`}</div>
                                <div className={combinedStyles.title}>{t(`${page}.items.${type}.title`)}</div>
                                <Icon
                                    iconName="arrow"
                                    className={`${combinedStyles.arrow} ${
                                        type === openType ? combinedStyles.arrowUp : combinedStyles.arrowDown
                                    }`}
                                />
                            </div>
                            {type === openType && (
                                <div className={combinedStyles.scrollingArea}>
                                    <div className={`${styles.contentContainer} ${combinedStyles.contentContainer}`}>
                                        <div className={styles.subTitle}>{t('story.instructions')}</div>
                                        <div className={styles.description}>
                                            {t(`${page}.items.${type}.instructions`)}
                                        </div>
                                        <div className={styles.subTitle}>{t('story.tip')}</div>
                                        <div className={styles.description}>{t(`${page}.items.${type}.tip`)}</div>
                                        {i18n.exists(`${page}.items.${type}.example`) && (
                                            <div className={styles.subTitle}>{t('story.example')}</div>
                                        )}
                                        {i18n.exists(`${page}.items.${type}.example`) && (
                                            <div className={styles.description}>
                                                {t(`${page}.items.${type}.example`)}
                                            </div>
                                        )}
                                    </div>
                                    <div className={combinedStyles.fadeout} />
                                </div>
                            )}
                        </Fragment>
                    ))}
                </div>
                <div className={combinedStyles.buttonContainer} />
            </div>
        );
    }
}

CombinedHelpZone.propTypes = {
    page: PropTypes.string.isRequired,
    types: PropTypes.array.isRequired,
    className: PropTypes.string,
    t: PropTypes.func,
};

export default withTranslation()(CombinedHelpZone);
