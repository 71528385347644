import STORY from './story/actionTypes';
import STORIES from './stories/actionTypes';
import CURRENT_STRIPE from './currentStripe/actionTypes';
import DESIGN from './design/actionTypes';
import USER from './user/actionTypes';
import PREVIEW from './preview/actionTypes';
import BOOK_ACCESS from './bookAccess/actionTypes';

const actionTypes = {
    STORY,
    STORIES,
    CURRENT_STRIPE,
    DESIGN,
    USER,
    PREVIEW,
    BOOK_ACCESS,
};

export default actionTypes;
