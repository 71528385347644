import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { StoryModal, ActionButton, Icon } from 'components';

import styles from './index.module.scss';

const BrowserNotSupportedModal = ({ isOpen, onRequestClose, t }) => (
    <StoryModal className={styles.modal} isOpen={isOpen} onRequestClose={onRequestClose}>
        <div className={styles.container}>
            <Icon className={styles.icon} iconName="browser" />
            <div className={styles.title}>{t('browerNotSupported.title')}</div>
            <div className={styles.subTitle}>{t('browerNotSupported.subTitle')}</div>
            <ActionButton onClick={onRequestClose} className={styles.closeButton}>
                {t('button.ok')}
            </ActionButton>
        </div>
    </StoryModal>
);

BrowserNotSupportedModal.propTypes = {
    isOpen: PropTypes.bool,
    onRequestClose: PropTypes.func,
    t: PropTypes.func,
};

BrowserNotSupportedModal.defaultProps = {
    isOpen: false,
    onRequestClose: () => {},
};

export default withTranslation()(BrowserNotSupportedModal);
