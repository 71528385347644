import throttle from 'lodash.throttle';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { BackButton, NextButton, Header, HelpZone, Toastr } from 'components';
import pitchItems from 'config/pitchItems';
import { updateStoryPitch, updateStoryRequest } from 'common/actions';
import PitchItem from './PitchItem';
import { FACTS } from 'config/steps';

class PitchPage extends Component {
    constructor(props) {
        super(props);
        this.pitch = {
            ...props.story.pitch,
        };

        const saveStoryChanges = () => this.props.updateStoryPitch(this.pitch);
        this.throttledSaveStoryChanges = throttle(saveStoryChanges, 1000, {
            trailing: true,
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.pitch = {
            ...nextProps.story.pitch,
            ...this.pitch,
        };
    }

    handleChange(name, value) {
        this.pitch[name] = value;
        this.throttledSaveStoryChanges();
    }

    previousStep() {
        const { story } = this.props;
        this.props.updateStoryRequest(
            {
                id: story.id,
                pitch: this.pitch,
            },
            `/facts/${story.id}`
        );
    }

    nextStep() {
        const { story } = this.props;
        this.props.updateStoryRequest(
            {
                id: story.id,
                pitch: this.pitch,
            },
            `/story/${story.id}`
        );
    }

    render() {
        const { t } = this.props;
        return (
            <div className="factsPage">
                <Header currentStep={FACTS} />
                <div className="factsContainer">
                    <div className="factsContent">
                        <h1 className="title">{t('pitch.fill_notes.title')}</h1>
                        <div>
                            {pitchItems.map((pitchItem, index) => (
                                <PitchItem
                                    key={pitchItem}
                                    index={index + 1}
                                    name={pitchItem}
                                    label={t(`pitch.label.${pitchItem}`)}
                                    handleChange={(name, value) => this.handleChange(name, value)}
                                    value={this.pitch[pitchItem]}
                                />
                            ))}
                        </div>
                    </div>
                    <HelpZone page={FACTS} type="pitch" className="factsHelpZone" />
                </div>
                <div className="buttons">
                    <div className="button">
                        <BackButton onClick={() => this.previousStep()} />
                    </div>
                    <div className="button">
                        <NextButton onClick={() => this.nextStep()} />
                    </div>
                </div>
                <Toastr position="bottom-left" />
            </div>
        );
    }
}

PitchPage.propTypes = {
    history: PropTypes.object,
    story: PropTypes.object,
    updateStoryRequest: PropTypes.func,
    updateStoryPitch: PropTypes.func,
    t: PropTypes.func,
};

const mapStateToProps = (state) => ({
    story: state.story,
});

const mapDispatchToProps = (dispatch) => ({
    updateStoryRequest: (story, redirectUrl) => {
        dispatch(updateStoryRequest(story, redirectUrl, { isSilent: true }));
    },
    updateStoryPitch: (pitch) => dispatch(updateStoryPitch(pitch)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PitchPage)));
