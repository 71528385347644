import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './index.module.scss';

const Input = (props) => (
    <div className={`${styles.container} ${props.containerClassName}`}>
        <input
            readOnly={props.readOnly}
            checked={props.checked}
            placeholder={props.placeholder}
            type={props.type}
            {...props.input}
            className={classNames('input', props.className, props.meta.touched && props.meta.error ? 'inputError' : '')}
        />
        <div className="errorMessage">{props.meta.touched && props.meta.error}</div>
    </div>
);

Input.propTypes = {
    placeholder: PropTypes.string,
    className: PropTypes.string,
    containerClassName: PropTypes.string,
    meta: PropTypes.object,
    input: PropTypes.object,
    type: PropTypes.string,
    readOnly: PropTypes.bool,
    checked: PropTypes.bool,
};

Input.defaultProps = {
    placeholder: '',
    className: '',
    containerClassName: '',
    meta: {},
    input: {},
    type: 'text',
};

export default Input;
