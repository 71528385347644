import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AutorizedTextarea from 'react-textarea-autosize';

import classNames from 'classnames';
import styles from './index.module.scss';

class Textarea extends Component {
    onChange(value) {
        const { handleChange } = this.props;
        handleChange(value);
    }

    render() {
        const { className, size, borderSolid, borderRadius, placeholder, value, minRows, style } = this.props;
        return (
            <AutorizedTextarea
                className={classNames(styles.textarea, className, styles[`${size}Textarea`], {
                    [styles.borderSolid]: borderSolid,
                    [styles.borderRadius]: borderRadius,
                })}
                placeholder={placeholder}
                value={value}
                onChange={(e) => this.onChange(e.target.value)}
                minRows={minRows}
                style={style}
            />
        );
    }
}

Textarea.propTypes = {
    value: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    size: PropTypes.string,
    minRows: PropTypes.number,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    borderSolid: PropTypes.bool,
    borderRadius: PropTypes.bool,
};

Textarea.defaultProps = {
    size: 'large',
    value: '',
    borderSolid: false,
    borderRadius: false,
    withHeight: false,
};

export default Textarea;
