import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import paths from 'config/paths';
import { resetPassword } from 'common/user/api';
import { Icon, StoryModal } from 'components';
import LostPasswordForm from './components/LostPasswordForm';
import styles from './index.module.scss';
import { capture } from 'services/errorMonitoring';

const defaultState = {
    error: null,
    emailSent: false,
    email: '',
};

class LostPasswordModal extends Component {
    constructor(props) {
        super(props);
        this.state = { ...defaultState };
    }

    onClose = () => {
        const { onRequestClose } = this.props;
        this.setState({ ...defaultState });
        onRequestClose();
    };

    resetRequest = ({ email }) => {
        const { locale, t } = this.props;
        this.setState({ error: null });
        resetPassword({
            email,
            baseUrl: `${window.location.origin}${paths.RESET_PASSWORD}`,
            locale,
        })
            .then(() => {
                this.setState({ emailSent: true, email });
            })
            .catch((err) => {
                capture(err, "Couldn't reset password");
                if (err.status === 404) {
                    this.setState({ error: t('lost_password.error.email') });
                } else {
                    this.setState({ error: t('lost_password.error.default') });
                }
            });
    };
    render() {
        const { error, emailSent, email } = this.state;
        const { isOpen, t } = this.props;
        return (
            <StoryModal isOpen={isOpen} onRequestClose={this.onClose}>
                {!emailSent && (
                    <div className={styles.modal}>
                        <div className="modalTitle">{t('lost_password.title')}</div>
                        <div className="modalSubtitle">{t('lost_password.subtitle')}</div>
                        <LostPasswordForm onSubmit={this.resetRequest} requestError={error} />
                    </div>
                )}
                {emailSent && (
                    <div className={styles.modal}>
                        <Icon className={styles.check} iconName="check" />
                        <div className="modalTitle">{t('lost_password.confirmation.title')}</div>
                        <div className="modalSubtitle">{t('lost_password.confirmation.subtitle', { email })}</div>
                    </div>
                )}
            </StoryModal>
        );
    }
}

LostPasswordModal.propTypes = {
    isOpen: PropTypes.bool,
    onRequestClose: PropTypes.func,
    locale: PropTypes.string.isRequired,
    t: PropTypes.func,
};

LostPasswordModal.defaultProps = {
    isOpen: false,
    onRequestClose: () => {},
};

export default withTranslation()(LostPasswordModal);
