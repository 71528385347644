import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import paths from 'config/paths';
import { ActionButton, ExportModal, PublishModal, ProgressBar } from 'components';
import { patchStoryRequest, exportPPTXRequest } from 'common/actions';
import { updateExport } from 'common/export/actions';
import { openPublishModal, closePublishModal } from 'common/design/actions';
import { isMobile } from 'services/platformService';

import styles from './index.module.scss';

const TopBar = (props) => (
    <div className={styles.container}>
        <div className={styles.actions}>
            <div className={styles.privacyIndicator}>
                <div className={classNames(styles.indicatorColor, props.story.published ? styles.public : '')} />
                <div className={styles.name}>{props.story.published ? props.t('public') : props.t('private')}</div>
            </div>
            <ActionButton flat onClick={props.openPublishModal} className={styles.button} iconName="share">
                {props.t('button.share')}
            </ActionButton>
            {props.isStoryLoaded && (
                <ActionButton
                    flat
                    onClick={() => props.exportPPTXRequest()}
                    className={styles.button}
                    iconName="export"
                >
                    {props.t('button.export')}
                </ActionButton>
            )}
            {props.isStoryLoaded && (
                <Link
                    to={`${paths.PREVIEW}${props.story.id}/${isMobile ? '' : props.currentStripe.id}`}
                    target="_blank"
                    className={styles.link}
                >
                    <ActionButton
                        flat
                        onClick={() => props.patchStoryRequest()}
                        className={styles.button}
                        iconName="preview"
                    >
                        {props.t('button.preview')}
                    </ActionButton>
                </Link>
            )}
            <ExportModal
                isOpen={props.isExportModalOpen}
                onRequestClose={() => {
                    props.updateExport({ isModalOpen: false });
                }}
            />
            <PublishModal
                isOpen={props.isPublishModalOpen}
                onRequestClose={props.closePublishModal}
                story={props.story}
            />
        </div>
        <div className={styles.progressBarContainer}>
            <ProgressBar currentStep={props.currentStep} />
        </div>
    </div>
);

const mapStateToProps = (state) => ({
    story: state.story,
    currentStripe: state.currentStripe,
    isExportModalOpen: state.export.isModalOpen,
    isPublishModalOpen: state.design.publishModalIsOpen,
    isStoryLoaded: !!state.story.id,
});

const mapDispatchToProps = (dispatch) => ({
    patchStoryRequest: () => {
        dispatch(patchStoryRequest(undefined, { isSilent: true }));
    },
    exportPPTXRequest: () => {
        dispatch(exportPPTXRequest());
    },
    updateExport: (payload) => {
        dispatch(updateExport(payload));
    },
    openPublishModal: () => {
        dispatch(openPublishModal());
    },
    closePublishModal: () => {
        dispatch(closePublishModal());
    },
});

TopBar.propTypes = {
    story: PropTypes.object,
    currentStripe: PropTypes.object,
    patchStoryRequest: PropTypes.func,
    exportPPTXRequest: PropTypes.func,
    updateExport: PropTypes.func,
    openPublishModal: PropTypes.func,
    closePublishModal: PropTypes.func,
    isExportModalOpen: PropTypes.bool,
    isPublishModalOpen: PropTypes.bool,
    isStoryLoaded: PropTypes.bool,
    currentStep: PropTypes.string,
    t: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TopBar));
