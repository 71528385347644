const storyItems = ['context', 'challenge', 'news', 'proposition', 'solution', 'outcome', 'action'];

export default storyItems;

// V1 of the method
// [
//   'title',
//   'context',
//   'challenge',
//   'news',
//   'solution',
//   'outcome',
//   'action',
// ]
