export const GIVE_A_TITLE = 'give_a_title';
export const PUBLISH = 'publish';
const designItems = [
    {
        name: 'design',
        subTypes: [
            'add_pictures',
            'insert_key_data',
            'shape_your_story',
            'core_message',
            'secondary_message',
            'videos_and_resources',
            'lists',
            'references',
            'engagement',
        ],
    },
    { name: GIVE_A_TITLE },
    { name: PUBLISH },
];

export default designItems;
