import React from 'react';
import PropTypes from 'prop-types';
import Dimensions from 'components/hocs/Dimensions';
import design from 'config/design';
import { getFileUrl } from 'services/imageService';
import scaleCalculator from 'services/scaleCalculator';
import { ColorFilter } from '../components';
import classNames from 'classnames';

import styles from './index.module.scss';

const SidebarStripeContainer = (props) => {
    const { containerWidth, containerHeight, currentStripe, children } = props;
    const scale = scaleCalculator(containerWidth, containerHeight, design.BASE_STRIPE_WIDTH, design.BASE_STRIPE_HEIGHT);
    const { backgroundImage, backgroundColor, colorFilter, backgroundImageCropValues } = currentStripe;
    const imageUrl = backgroundImage
        ? getFileUrl(backgroundImage.secure_url, backgroundImageCropValues, design.IMAGE_TYPE.THUMBNAIL)
        : null;
    return (
        <div
            style={{
                backgroundColor,
                backgroundImage: `url(${imageUrl})`,
                transform: `translate(-50%, -50%) scale(${scale})`,
                width: `${design.BASE_STRIPE_WIDTH}px`,
                height: `${design.BASE_STRIPE_HEIGHT}px`,
            }}
            className={classNames(styles.container, {
                [styles.imageContained]: currentStripe.imageBehaviour === design.IMAGE_CONTAIN,
            })}
        >
            {colorFilter && backgroundImage && <ColorFilter type={colorFilter} />}
            {children}
            {props.title && <div className={styles.title}>{props.title}</div>}
        </div>
    );
};

SidebarStripeContainer.propTypes = {
    currentStripe: PropTypes.object.isRequired,
    children: PropTypes.node,
    containerWidth: PropTypes.number,
    containerHeight: PropTypes.number,
    title: PropTypes.string,
};

export default Dimensions()(SidebarStripeContainer);
