import Stripe from './Stripe';

export default class PlainStripe extends Stripe {
    constructor(payload = {}) {
        super(payload);
        const { title } = payload;
        this.title = title || '';
        this.isTitleMovable = true;
    }
}
