import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import auth from 'common/auth';
import paths from 'config/paths';
import { StoryRoute, LicenseProlongationModal } from 'components';

const AppRoute = ({ component, isPrivate, t, ...rest }) => (
    <Route
        {...rest}
        render={(props) => <InnerComponent isPrivate={isPrivate} component={component} t={t} {...props} />}
    />
);

const InnerComponent = ({ t, component, isPrivate, ...props }) => {
    const shouldPush = props.history.action === 'PUSH';
    if (!isPrivate || auth.loggedIn()) {
        return (
            <StoryRoute>
                <LicenseProlongationModal key={props.history.location.key} />
                {React.createElement(component, props)}
            </StoryRoute>
        );
    }

    return <Redirect push={shouldPush} to={{ pathname: paths.LOGIN, state: { from: props.location } }} />;
};

AppRoute.propTypes = {
    isPrivate: PropTypes.bool,
    t: PropTypes.func,
};

InnerComponent.propTypes = {
    isPrivate: PropTypes.bool,
    history: PropTypes.object,
    location: PropTypes.object,
    t: PropTypes.func,
};

export default withTranslation()(AppRoute);
