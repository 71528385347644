import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import { NextButton } from 'components';

import styles from './index.module.scss';

const IntroPage = ({ history, locale, story, t }) => (
    <div className={classNames(styles.container, locale === 'fr' ? styles.containerFR : '')}>
        <div className={styles.title}>
            <div className={styles.titleFirstLine}>{t('intro.title_line1')}</div>
            <div>{t('intro.title_line2')}</div>
        </div>
        <NextButton className={styles.startButton} onClick={() => history.push(`/facts/${story.id}`)}>
            {t('button.start')}
        </NextButton>
    </div>
);

IntroPage.propTypes = {
    history: PropTypes.object,
    story: PropTypes.object,
    locale: PropTypes.string,
    t: PropTypes.func,
};

const mapStateToProps = (state) => ({
    locale: state.translations.locale,
    story: state.story,
});

export default withRouter(connect(mapStateToProps)(withTranslation()(IntroPage)));
