import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { setCurrentStoryRequest, setStory } from 'common/story/actions';

class StoryRoute extends Component {
    constructor(props) {
        super(props);
        const { story } = props;
        if (story) this.props.setStory(story);
    }

    componentDidMount() {
        const {
            match: { params },
        } = this.props;
        if (params && params.storyId) this.setCurrentStory(params.storyId);
    }

    componentDidUpdate(prevProps) {
        const {
            match: { params },
        } = this.props;
        if (params?.storyId && params?.storyId !== prevProps.match.params?.storyId) {
            this.setCurrentStory(params.storyId);
        }
    }

    setCurrentStory(storyId) {
        this.props.setCurrentStoryRequest(storyId);
    }

    render() {
        const { children } = this.props;
        return children;
    }
}

StoryRoute.propTypes = {
    setCurrentStoryRequest: PropTypes.func,
    setStory: PropTypes.func,
    match: PropTypes.object,
    story: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => ({
    setCurrentStoryRequest: (storyId) => {
        dispatch(setCurrentStoryRequest(storyId));
    },
    setStory: (story) => {
        dispatch(setStory(story));
    },
});

export default withRouter(connect(null, mapDispatchToProps)(StoryRoute));
