import remove from 'lodash.remove';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateStripe } from 'common/actions';
import Quotation from 'models/Quotation';
import AddQuotationButton from './components/AddQuotationButton';
import QuotationBox from './components/QuotationBox';
import { DisplayedTitle, EditableTitle } from '../components';

import styles from './index.module.scss';

class QuotationStripe extends Component {
    addQuotation() {
        const quotation = this.props.stripe.quotations[0];
        this.updateStore([{ ...quotation }, new Quotation()]);
    }

    removeQuotation(id) {
        const { quotations } = this.props.stripe;
        this.updateStore(remove(quotations, (quotation) => quotation.id !== id));
    }

    updateQuotation(value, key, id) {
        const { quotations } = this.props.stripe;
        const updatedQuotations = [];
        for (let i = 0; i < quotations.length; i += 1) {
            if (quotations[i].id === id) {
                updatedQuotations.push({ ...quotations[i], [key]: value });
            } else {
                updatedQuotations.push({ ...quotations[i] });
            }
        }
        this.updateStore(updatedQuotations);
    }

    updateStore(quotations) {
        this.props.updateStripe({
            id: this.props.stripe.id,
            quotations,
        });
    }

    render() {
        const { editable, stripe, customizable, dimensionAware } = this.props;
        const length = stripe.quotations.length;
        return (
            <div className={styles.container}>
                <div>
                    {editable && (
                        <EditableTitle
                            className={styles.title}
                            size="large"
                            customizable={customizable}
                            stripe={stripe}
                        />
                    )}
                    {!editable && (
                        <DisplayedTitle
                            className={`${styles.title} ${styles.titleDisplay}`}
                            size="large"
                            title={stripe.title}
                            dimensionAware={dimensionAware}
                            stripe={stripe}
                        />
                    )}
                    <div className={styles.content}>
                        <QuotationBox
                            editable={editable}
                            stripe={stripe}
                            deletable={length > 1}
                            onDelete={() => this.removeQuotation(stripe.quotations[0].id)}
                            handleChange={(value, key) => this.updateQuotation(value, key, stripe.quotations[0].id)}
                            dimensionAware={dimensionAware}
                            index={0}
                        />
                        {stripe.quotations.length === 1 ? (
                            editable && <AddQuotationButton onClick={() => this.addQuotation()} />
                        ) : (
                            <QuotationBox
                                editable={editable}
                                stripe={stripe}
                                deletable
                                onDelete={() => this.removeQuotation(stripe.quotations[1].id)}
                                handleChange={(value, key) => this.updateQuotation(value, key, stripe.quotations[1].id)}
                                dimensionAware={dimensionAware}
                                index={1}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

QuotationStripe.propTypes = {
    editable: PropTypes.bool,
    stripe: PropTypes.object.isRequired,
    updateStripe: PropTypes.func,
    customizable: PropTypes.bool,
    dimensionAware: PropTypes.bool,
};

QuotationStripe.propTypes = {
    dimensionAware: false,
};

const mapDispatchToProps = (dispatch) => ({
    updateStripe: (stripe) => {
        dispatch(updateStripe(stripe));
    },
});

export default connect(null, mapDispatchToProps)(QuotationStripe);
