import React from 'react';
import PropTypes from 'prop-types';
import { ProgressBar } from 'components';
import styles from './index.module.scss';

const Header = ({ currentStep }) => (
    <div className={styles.header}>
        <ProgressBar className={styles.progressBar} currentStep={currentStep} />
    </div>
);

Header.propTypes = {
    currentStep: PropTypes.string.isRequired,
};

export default Header;
