import React from 'react';
import PropTypes from 'prop-types';

import design from 'config/design';
import styles from './index.module.scss';

const ColorFilter = ({ type }) => (
    <div
        className={styles.filter}
        // dark for true or "dark" values
        style={{
            backgroundColor: type === design.FILTER_LIGHT ? design.FILTER_LIGHT_RBGA : design.FILTER_DARK_RBGA,
        }}
    />
);

ColorFilter.propTypes = {
    type: PropTypes.string.isRequired,
};

export default ColorFilter;
