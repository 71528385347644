import config from 'config/design';
import { TRANSITION } from 'config/stripeTypes';

function colorService(stripe) {
    const { titleColor } = stripe;
    if (titleColor) return titleColor;
    return stripe.design === TRANSITION ? config.DARK : config.LIGHT;
}

export default colorService;
