import update from 'immutability-helper';
import findIndex from 'lodash.findindex';
import cloneDeep from 'lodash.clonedeep';
import actionTypes from './actionTypes';

const initialState = {
    stripes: [],
    facts: {},
    pitch: {},
    titleBoxDimensions: {},
    dataBoxDimensions: [],
    quotationBoxDimensions: [],
    published: false,
    saveErrorOccured: false,
};

export default function storyReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.ADD_STRIPE: {
            let updatedStripes = [];
            if (action.position !== undefined) {
                const newStripePosition = action.position + 1;
                updatedStripes = [
                    ...state.stripes.slice(0, newStripePosition),
                    action.payload,
                    ...state.stripes.slice(newStripePosition),
                ];
            } else {
                updatedStripes = [...state.stripes, action.payload];
            }
            return {
                ...state,
                stripes: updatedStripes,
            };
        }
        case actionTypes.SET_STORY:
            return {
                ...action.payload,
            };
        case actionTypes.UPDATE_STORY:
            return {
                ...state,
                ...action.payload,
            };
        case actionTypes.UPDATE_STORY_FACTS:
            return {
                ...state,
                facts: {
                    ...state.facts,
                    ...action.payload,
                },
            };
        case actionTypes.UPDATE_STORY_PITCH:
            return {
                ...state,
                pitch: {
                    ...state.pitch,
                    ...action.payload,
                },
            };
        case actionTypes.UPDATE_STRIPE_TITLE_BOX_SIZE:
        case actionTypes.UPDATE_STRIPE:
        case actionTypes.SET_STRIPE_DESIGN: {
            const index = findIndex(state.stripes, { id: action.payload.id });
            if (index === -1) {
                return state;
            }
            const stripes = [
                ...state.stripes.slice(0, index),
                { ...state.stripes[index], ...action.payload },
                ...state.stripes.slice(index + 1),
            ];
            return { ...state, stripes };
        }
        case actionTypes.UPDATE_STRIPE_DATA_BOX_SIZE: {
            const { stripeId, x, y, dataBoxDimensions } = action.payload;
            const stripes = state.stripes.map((stripe) => {
                if (stripe.id !== stripeId) return stripe;
                let newDataBoxDimensions = [[]];
                if (stripe.dataBoxDimensions) newDataBoxDimensions = cloneDeep(stripe.dataBoxDimensions);
                if (!newDataBoxDimensions[x]) newDataBoxDimensions[x] = [];
                newDataBoxDimensions[x][y] = dataBoxDimensions;
                return { ...stripe, dataBoxDimensions: newDataBoxDimensions };
            });
            return { ...state, stripes };
        }
        case actionTypes.UPDATE_STRIPE_QUOTATION_SIZE: {
            const { stripeId, quotationIndex, quotationBoxDimensions } = action.payload;
            const stripes = state.stripes.map((stripe) => {
                if (stripe.id !== stripeId) return stripe;
                const newQuotationBoxDimensions = stripe.quotationBoxDimensions
                    ? [...stripe.quotationBoxDimensions]
                    : [];
                newQuotationBoxDimensions[quotationIndex] = quotationBoxDimensions;
                return { ...stripe, quotationBoxDimensions: newQuotationBoxDimensions };
            });
            return { ...state, stripes };
        }
        case actionTypes.REMOVE_STRIPE: {
            const index = findIndex(state.stripes, { id: action.stripe.id });
            if (index === -1) {
                return state;
            }

            return {
                ...state,
                stripes: [...state.stripes.slice(0, index), ...state.stripes.slice(index + 1)],
            };
        }
        case actionTypes.MOVE_STRIPE: {
            const { oldStripePosition, newStripePosition } = action;
            const { stripes } = state;
            const movedStripe = stripes[oldStripePosition];
            const previousStripePosition = newStripePosition - (newStripePosition < oldStripePosition ? 1 : 0);

            return update(state, {
                stripes: {
                    $splice: [
                        [oldStripePosition, 1],
                        [
                            newStripePosition,
                            0,
                            {
                                ...movedStripe,
                                type: newStripePosition > 0 ? stripes[previousStripePosition].type : movedStripe.type,
                            },
                        ],
                    ],
                },
            });
        }

        case actionTypes.RESET_STRIPE_DIMENSIONS: {
            return {
                ...state,
                stripes: state.stripes.map((stripe) => ({
                    ...stripe,
                    titleBoxDimensions: {},
                    dataBoxDimensions: [],
                    quotationBoxDimensions: [],
                })),
            };
        }
        case actionTypes.SET_SAVE_ERROR:
            return {
                ...state,
                saveErrorOccured: true,
            };
        case actionTypes.RESET_STORY:
            return initialState;
        default:
            return state;
    }
}

export function storySelector(state) {
    return state.story;
}
