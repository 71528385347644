import keys from 'lodash.keys';
import { REHYDRATE } from 'redux-persist';
import availableTranslations from 'resources/i18n/translations';
import i18n from 'i18n';
import types from './actionTypes';

const locales = keys(availableTranslations); // ['en', 'fr']

const navLang = navigator.language || navigator.userLanguage;
const frenchLocale = navLang.includes('fr');

const initialState = {
    locale: frenchLocale ? locales[1] : locales[0],
    nextLocale: frenchLocale ? locales[0] : locales[1],
};

export default function update(state = initialState, action = '') {
    switch (action.type) {
        case REHYDRATE:
            const locale = action.payload && action.payload.locale ? action.payload.locale : initialState.locale;
            i18n.changeLanguage(locale);
            return state;
        case types.CHANGE_LOCALE:
            i18n.changeLanguage(state.nextLocale);
            const i = locales.indexOf(state.nextLocale) + 1;
            const nextLocale = i < locales.length ? locales[i] : locales[0];
            return {
                locale: state.nextLocale,
                nextLocale,
            };
        default:
            return state;
    }
}
