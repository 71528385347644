import { v4 } from 'node-uuid';
import config from 'config/design';
import storyItems from 'config/storyItems';
import stripeTypes from 'config/stripeTypes';

export default class Stripe {
    constructor(payload = {}) {
        const { id, backgroundColor, design, type, backgroundBehaviour, backgroundImage, colorFilter } = payload;
        this.id = id || v4();
        this.type = type || storyItems[0];
        this.design = design || stripeTypes[0];
        this.backgroundColor = backgroundColor || config.DEFAULT_STRIPE_BACKGROUND;
        this.quotations = undefined;
        this.data = undefined;
        this.isTitleMovable = false;
        this.colorFilter = colorFilter || '';
        this.backgroundImage = backgroundImage || undefined;
        this.backgroundBehaviour = backgroundBehaviour || config.BACKGROUND_FIXED;
        this.titlePosition = { x: 'center', y: 'center' };
        this.iframe = '';
        this.titleColor = config.LIGHT;
    }
}
