import { fork, takeLatest, all } from 'redux-saga/effects';

import {
    patchStoryRequest,
    updateStoryRequest,
    deleteStoryRequest,
    exportPPTXRequest,
    storyNextStepRequest,
    storyPreviousStepRequest,
    addStripeRequest,
    createStoryRequest,
    saveStoryPeriodically,
    getStoriesRequest,
    setCurrentStoryRequest,
    removeStripeRequest,
} from './story/saga';
import { loginRequest, logoutRequest, fetchUserRequest } from './user/saga';
import { designNextStepRequest, designPreviousStepRequest, grantDesignAccessRequest } from './design/saga';
import { getBookAccessRequest, updateBookAccessRequest } from './bookAccess/saga';
import actionTypes from './actionTypes';

export default function* rootSaga() {
    yield all([
        fork(fetchUserRequest),
        fork(saveStoryPeriodically),
        takeLatest(actionTypes.STORY.PATCH_STORY_REQUEST, patchStoryRequest),
        takeLatest(actionTypes.STORY.UPDATE_STORY_REQUEST, updateStoryRequest),
        takeLatest(actionTypes.STORY.DELETE_STORY_REQUEST, deleteStoryRequest),
        takeLatest(actionTypes.STORY.STORY_NEXT_STEP_REQUEST, storyNextStepRequest),
        takeLatest(actionTypes.STORY.STORY_PREVIOUS_STEP_REQUEST, storyPreviousStepRequest),
        takeLatest(actionTypes.STORY.ADD_STRIPE_REQUEST, addStripeRequest),
        takeLatest(actionTypes.STORY.CREATE_STORY_REQUEST, createStoryRequest),
        takeLatest(actionTypes.STORY.EXPORT_PPT_REQUEST, exportPPTXRequest),
        takeLatest(actionTypes.STORY.REMOVE_STRIPE_REQUEST, removeStripeRequest),
        takeLatest(actionTypes.DESIGN.DESIGN_NEXT_STEP_REQUEST, designNextStepRequest),
        takeLatest(actionTypes.DESIGN.DESIGN_PREVIOUS_STEP_REQUEST, designPreviousStepRequest),
        takeLatest(actionTypes.DESIGN.GRANT_DESIGN_ACCESS_REQUEST, grantDesignAccessRequest),
        takeLatest(actionTypes.STORIES.GET_STORIES_REQUEST, getStoriesRequest),
        takeLatest(actionTypes.STORY.SET_CURRENT_STORY_REQUEST, setCurrentStoryRequest),
        takeLatest(actionTypes.USER.LOGIN_REQUEST, loginRequest),
        takeLatest(actionTypes.USER.LOGOUT_REQUEST, logoutRequest),
        takeLatest(actionTypes.BOOK_ACCESS.GET_BOOK_ACCESS_REQUEST, getBookAccessRequest),
        takeLatest(actionTypes.BOOK_ACCESS.UPDATE_BOOK_ACCESS_REQUEST, updateBookAccessRequest),
    ]);
}
