import React from 'react';
import PropTypes from 'prop-types';
import { DisplayedTitle, EditableTitle } from '../components';
import styles from './index.module.scss';

const PlainStripe = (props) => {
    let posX = 'center';
    let posY = 'center';
    if (props.stripe.titlePosition) {
        posX = props.stripe.titlePosition.x;
        posY = props.stripe.titlePosition.y;
    }

    const titleStyle = {
        justifyContent: posX === 'right' ? 'flex-end' : posX === 'left' ? 'flex-start' : 'center',
        alignItems: posY === 'top' ? 'flex-start' : posY === 'bottom' ? 'flex-end' : 'center',
    };
    if (props.preview && props.stripe.iframe && props.stripe.iframe.length > 5) {
        return (
            <div className={styles.container} style={titleStyle}>
                <iframe
                    title="External video"
                    width="640"
                    height="360"
                    src={`${props.stripe.iframe}?disablekb=1`}
                    frameBorder="0"
                    allowFullScreen
                />
            </div>
        );
    } else if (props.stripe.iframe && props.stripe.iframe.length > 5) {
        return (
            <div className={styles.container} style={titleStyle}>
                <div className={styles.fakeIframe}>
                    <p>iframe</p>
                </div>
            </div>
        );
    }
    return (
        <div className={styles.container} style={titleStyle}>
            {props.editable ? (
                <EditableTitle stripe={props.stripe} size="large" customizable={props.customizable} />
            ) : (
                <DisplayedTitle
                    size="large"
                    title={props.stripe.title}
                    dimensionAware={props.dimensionAware}
                    stripe={props.stripe}
                />
            )}
        </div>
    );
};

PlainStripe.propTypes = {
    editable: PropTypes.bool,
    stripe: PropTypes.object,
    customizable: PropTypes.bool,
    preview: PropTypes.bool,
    dimensionAware: PropTypes.bool,
};

PlainStripe.defaultProps = {
    dimensionAware: false,
};

export default PlainStripe;
