import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
    createStoryRequest,
    getStoriesRequest,
    resetStory,
    deleteStoryRequest,
    resetRemoteStory,
} from 'common/actions';
import { AddButton, AddStoryButton, Storybar, ConfirmationModal, Toastr } from 'components';
import StoryList from './components/StoryList';

import styles from './index.module.scss';

class HomePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            storyToDelete: null,
            isDeleteModalOpen: false,
        };
    }

    componentDidMount() {
        this.props.getStoriesRequest();
        this.props.resetStory();
        this.props.resetRemoteStory();
    }

    closeModal() {
        this.setState({ isDeleteModalOpen: false });
    }

    startStoryDelete(story) {
        this.setState({ isDeleteModalOpen: true, storyToDelete: story });
    }

    sendStoryDelete() {
        const { storyToDelete } = this.state;
        const { deleteStory } = this.props;
        deleteStory(storyToDelete.id);
        this.closeModal();
    }

    render() {
        const { isDeleteModalOpen, storyToDelete } = this.state;
        const {
            t,
            user: { firstName },
        } = this.props;
        return (
            <div className={styles.storyContainer}>
                <ConfirmationModal
                    isOpen={isDeleteModalOpen}
                    onRequestClose={() => {
                        this.closeModal();
                    }}
                    onValidate={() => this.sendStoryDelete()}
                >
                    <div className={styles.deleteModalMessage}>{t('homepage.story_delete.message')}</div>
                    {storyToDelete && storyToDelete.title && (
                        <div className={styles.deleteModalStoryTitle}>{storyToDelete.title}</div>
                    )}
                </ConfirmationModal>
                <Storybar className={styles.sidebar}>
                    <div className={styles.yourStories}>{t('homepage.your_stories')}</div>
                    <StoryList onStoryDelete={(story) => this.startStoryDelete(story)} />
                    <div>
                        <AddButton label="button.add_story" onClick={() => this.props.createStory()} />
                    </div>
                </Storybar>
                <div className={styles.homepage}>
                    <div className={styles.content}>
                        <h1 className={styles.welcome}>
                            {t('homepage.welcome', {
                                name: firstName || 'Storymaker',
                            })}
                        </h1>
                        <AddStoryButton onClick={() => this.props.createStory()} />
                    </div>
                    <Toastr position="bottom-left" />
                </div>
            </div>
        );
    }
}

HomePage.propTypes = {
    getStoriesRequest: PropTypes.func,
    deleteStory: PropTypes.func,
    resetStory: PropTypes.func,
    createStory: PropTypes.func,
    user: PropTypes.object,
    t: PropTypes.func,
};

const mapStateToProps = (state) => ({
    user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
    createStory: () => {
        dispatch(createStoryRequest());
    },
    deleteStory: (storyId) => {
        dispatch(deleteStoryRequest(storyId));
    },
    getStoriesRequest: () => {
        dispatch(getStoriesRequest());
    },
    resetStory: () => {
        dispatch(resetStory());
    },
    resetRemoteStory: () => {
        dispatch(resetRemoteStory());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(HomePage));
