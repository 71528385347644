import moment from 'moment';

export const isDatePassed = (date) => {
    const now = moment();
    const timeDiff = now.diff(date);
    return timeDiff > 0;
};

export const getDurationEndDate = (duration, date) => {
    const start = date ? moment(date) : moment();
    return start.add(moment.duration(duration));
};
