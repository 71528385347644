import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Toggle from 'react-toggle';
import copy from 'copy-to-clipboard';
import classNames from 'classnames';
import { StoryModal, ActionButton, ConfirmationMessage } from 'components';
import { updateStoryRequest } from 'common/actions';
import paths from 'config/paths';
import { cancelTimer } from 'services/timerService';

import './toggle.css';
import styles from './index.module.scss';

class PublishModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isCopyMessageVisible: false,
            timer: undefined,
        };
    }

    componentWillUnmount() {
        cancelTimer(this.timer);
    }

    copy(text) {
        copy(text);
        this.setState({
            isCopyMessageVisible: true,
            timer: setTimeout(() => {
                this.setState({ isCopyMessageVisible: false });
            }, 3000),
        });
    }

    render() {
        const { onRequestClose, story, updateStoryRequest, isOpen, t } = this.props;
        const { isCopyMessageVisible } = this.state;
        const link = `${window.location.origin}${paths.PREVIEW}${story.id}`;
        return (
            <StoryModal isOpen={isOpen} onRequestClose={onRequestClose} className={styles.modal}>
                <div className={styles.title}>{t('publish.title')}</div>
                <div className={styles.toggleContainer}>
                    <div
                        className={classNames(
                            styles.toggleLabel,
                            styles.toggleLabelLeft,
                            story.published ? styles.toggleLabelInactive : ''
                        )}
                    >
                        {t('private')}
                    </div>
                    <Toggle
                        checked={story.published}
                        icons={false}
                        onChange={() =>
                            updateStoryRequest({
                                id: story.id,
                                published: !story.published,
                            })
                        }
                    />
                    <div
                        className={classNames(
                            styles.toggleLabel,
                            styles.toggleLabelRight,
                            !story.published ? styles.toggleLabelInactive : ''
                        )}
                    >
                        {t('public')}
                    </div>
                </div>
                <div className={styles.description}>
                    {story.published ? t('publish.description_public') : t('publish.description_private')}
                </div>
                <div className={styles.link}>
                    <div className={classNames(styles.linkLabel, !story.published ? styles.linkLabelInactive : '')}>
                        {t(`publish.label_${story.published ? 'public' : 'private'}`)}
                    </div>
                    <div className={classNames(styles.linkInput, !story.published ? styles.linkInputInactive : '')}>
                        <div
                            className={classNames(styles.inputField, !story.published ? styles.inputFieldInactive : '')}
                            ref={(node) => (this.link = node)}
                        >
                            {link}
                        </div>
                        <ActionButton
                            disabled={!story.published}
                            className={styles.copyButton}
                            disabledClassName={styles.disabledCopyButton}
                            onClick={() => this.copy(this.link.textContent)}
                        >
                            {t('button.copy')}
                        </ActionButton>
                    </div>
                    <ConfirmationMessage
                        className={classNames(
                            styles.confirmationMessage,
                            !isCopyMessageVisible ? styles.confirmationMessageHidden : ''
                        )}
                    >
                        {t('publish.message.copied')}
                    </ConfirmationMessage>
                </div>
            </StoryModal>
        );
    }
}

PublishModal.propTypes = {
    isOpen: PropTypes.bool,
    onRequestClose: PropTypes.func,
    story: PropTypes.object,
    t: PropTypes.func,
};

PublishModal.defaultProps = {
    isOpen: false,
    onRequestClose: () => {},
    story: {},
};

const mapDispatchToProps = (dispatch) => ({
    updateStoryRequest: (data, redirectUrl) => {
        dispatch(updateStoryRequest(data, redirectUrl));
    },
});

export default connect(null, mapDispatchToProps)(withTranslation()(PublishModal));
