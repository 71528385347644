import request from 'superagent';
import config from '../config';

export function getMyStories(tokenId) {
    return request
        .get(`${config.baseUrl}/stories/getMyStories`)
        .retry(2)
        .set('Authorization', tokenId)
        .then((response) => response.body);
}

export function getStory(id, tokenId) {
    return request
        .get(`${config.baseUrl}/stories/${id}`)
        .retry(2)
        .set('Authorization', tokenId)
        .then((response) => response.body);
}

// delete pptx on aws after download
export function deletePPTX(fileKey) {
    return request.delete(`${config.exportPPTXBaseUrl}/delete/${fileKey}`).retry(2);
}

export function downloadPPTX(story) {
    return request
        .post(`${config.exportPPTXBaseUrl}/generate`)
        .retry(2)
        .send(story)
        .set('Content-Type', 'application/json')
        .then(({ body: { url, fileKey } }) => {
            return request
                .get(url)
                .responseType('arraybuffer')
                .then((res) => ({ fileStream: res.xhr.response, fileKey }));
        });
}

export function updateStory(story, tokenKey) {
    return request
        .put(`${config.baseUrl}/stories/${story.id}`)
        .set('Authorization', tokenKey)
        .send(story)
        .then((response) => response.body);
}

export function createStory(story, tokenKey) {
    return request
        .post(`${config.baseUrl}/stories`)
        .set('Authorization', tokenKey)
        .send(story)
        .then((response) => response.body);
}

export function patchStory(storyId, patch, tokenKey) {
    return request
        .patch(`${config.baseUrl}/stories/${storyId}`)
        .set('Authorization', tokenKey)
        .send({ patch })
        .then((response) => response.body);
}

export function uploadImage(file) {
    return request
        .post(`${config.CLOUDINARY_BASE_URL}/upload`)
        .field('upload_preset', config.CLOUDINARY_UPLOAD_PRESET)
        .field('file', file)
        .then((response) => response.body);
}

export function deleteImage(storyId, stripeId, imageId, newImage, tokenKey) {
    return request
        .delete(`${config.baseUrl}/stories/${storyId}/stripes/${stripeId}/images/${imageId}`)
        .set('Authorization', tokenKey)
        .send(newImage)
        .then((response) => response.body);
}
