import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { CancelButton, StoryModal, ValidateButton, Icon } from 'components';

import styles from './index.module.scss';

const LicenseExpirationModal = ({ isOpen, onRequestClose, t }) => {
    return (
        <StoryModal className={styles.modal} isOpen={isOpen} onRequestClose={onRequestClose}>
            <div className={styles.topContainer}>
                <Icon className={styles.icon} iconName="expired" />
            </div>
            <div className={styles.content}>
                <div className={styles.title}>{t('license.modal.title')}</div>
                <div className={styles.subTitle}>{t('license.modal.expirationSubTitle')}</div>
            </div>
            <div className={styles.buttons}>
                <ValidateButton
                    className={styles.licenseButtons}
                    onClick={() => {
                        global.window.location.href = 'https://storymakers.eu/#contact-us';
                    }}
                >
                    {t('license.modal.button.interested')}
                </ValidateButton>
                <CancelButton className={styles.licenseButtons} onClick={onRequestClose}>
                    {t('license.modal.button.decline')}
                </CancelButton>
            </div>
        </StoryModal>
    );
};

LicenseExpirationModal.propTypes = {
    onRequestClose: PropTypes.func,
    isOpen: PropTypes.bool,
    t: PropTypes.func,
};

export default withTranslation()(LicenseExpirationModal);
