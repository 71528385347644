import actionTypes from './actionTypes';

export function setRemoteStory(payload) {
    return {
        type: actionTypes.SET_REMOTE_STORY,
        payload,
    };
}

export function resetRemoteStory() {
    return {
        type: actionTypes.RESET_REMOTE_STORY,
    };
}
