import { applyMiddleware, createStore, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware, { END } from 'redux-saga';
import { persistStore } from 'redux-persist';
import { createLogger } from 'redux-logger';

import { sentryReduxEnhancer } from 'services/errorMonitoring';
import createRootReducer from './reducers';
import rootSaga from './sagas';

const store = (history) => {
    const middlewares = [];
    const logger = createLogger();

    middlewares.push(logger);
    middlewares.push(routerMiddleware(history));

    const sagaMiddleware = createSagaMiddleware();
    middlewares.push(sagaMiddleware);

    const enhancers = [sentryReduxEnhancer];

    // Enable Redux devtools for development
    // https://github.com/gaearon/redux-devtools#chrome-extension
    if (process.env.NODE_ENV === 'development') {
        const devToolsExtension = global.window.__REDUX_DEVTOOLS_EXTENSION__;
        if (typeof devToolsExtension === 'function') {
            enhancers.push(devToolsExtension());
        }
    }

    // const store = createStore(connectRouter(history)(reducer), compose(applyMiddleware(...middlewares), ...enhancers));
    const store = createStore(createRootReducer(history), compose(applyMiddleware(...middlewares), ...enhancers));

    const persistor = persistStore(store, null);

    sagaMiddleware.run(rootSaga);

    // when the app is close, dispatch a END action to cancel saga
    store.close = () => store.dispatch(END);

    // this allow hot reloading of the reducers
    if (module.hot) {
        module.hot.accept('./reducers', () => {
            const reducers = require('./reducers').default; // eslint-disable-line global-require
            store.replaceReducer(reducers(store.asyncReducers));
        });
    }

    return { persistor, store };
};

export default store;
