const factsItems = [
    'subject',
    'innovation',
    'target',
    'action',
    // 'hero', deprecated V1
    'content',
    'pictures',
];

export default factsItems;
