import types from './actionTypes';

const initialState = {
    story: false,
};

export default function update(state = initialState, action) {
    switch (action.type) {
        case types.UPDATE_LOADING:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
}
