import { call, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { authenticatedRequest } from 'common/middlewares';
import { setLoginError, resetLoginError } from 'common/actions';
import auth from 'common/auth';
import paths from 'config/paths';
import { setUser } from './actions';
import { loginUser, fetchUser, logoutUser } from './api';
import { capture } from 'services/errorMonitoring';

export function* loginRequest(payload) {
    try {
        yield put(resetLoginError());

        const token = yield call(loginUser, payload.user.email, payload.user.password);
        yield auth.set(token.id, token.userId);
        const user = yield authenticatedRequest(fetchUser, token.userId);
        yield put(setUser(user));
        yield put(push(payload.redirectTo || '/'));
    } catch (error) {
        capture(error, "Couldn't login");
        if (error.response && error.response.statusCode === 401) {
            yield put(setLoginError({ login: error.response.statusText }));
        } else if (error.response && error.response.statusCode === 403) {
            yield put(setLoginError({ suspended: error.response.statusText }));
        }
    }
}

export function* fetchUserRequest() {
    if (auth.loggedIn()) {
        try {
            const user = yield authenticatedRequest(fetchUser, auth.getUserId());
            yield put(setUser(user));
        } catch (error) {
            capture(error, "Couldn't fetch user");
        }
    }
}

export function* logoutRequest() {
    try {
        yield authenticatedRequest(logoutUser);
    } catch (error) {
        capture(error, "Couldn't log out");
    } finally {
        yield auth.reset();
        yield put(setUser({}));
        yield put(push(paths.LOGIN));
    }
}
