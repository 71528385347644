import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { StoryModal, ActionButton, Icon } from 'components';

import styles from './index.module.scss';

const SaveErrorModal = ({ isOpen, t }) => {
    const reloadPage = () => {
        window.location.reload();
    };
    const onRequestClose = () => {};

    return (
        <StoryModal className={styles.modal} isOpen={isOpen} onRequestClose={onRequestClose}>
            <div className={styles.content}>
                <Icon className={styles.icon} iconName="danger" />
                <div className={styles.title}>{t('saveError.title')}</div>
                <div className={styles.subTitle}>{t('saveError.subTitle')}</div>
                <ActionButton onClick={reloadPage} className={styles.closeButton}>
                    {t('button.reload')}
                </ActionButton>
            </div>
        </StoryModal>
    );
};

SaveErrorModal.propTypes = {
    onRequestClose: PropTypes.func,
    isOpen: PropTypes.bool,
    t: PropTypes.func,
};

export default withTranslation()(SaveErrorModal);
