import { setUser as setUserForMonitoring } from 'services/errorMonitoring';

import actionTypes from './actionTypes';

export const setUser = (user) => {
    setUserForMonitoring(user);
    return {
        type: actionTypes.SET_USER,
        user,
    };
};

export const loginRequest = (user, redirectTo) => ({
    type: actionTypes.LOGIN_REQUEST,
    user,
    redirectTo,
});

export const logoutRequest = () => ({
    type: actionTypes.LOGOUT_REQUEST,
});

export const setLoginError = (payload) => ({
    type: actionTypes.SET_LOGIN_ERROR,
    payload,
});
export const resetLoginError = () => ({
    type: actionTypes.RESET_LOGIN_ERROR,
});
