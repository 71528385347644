import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Textarea } from 'components';
import { updateStripeDataBoxSize } from 'common/story/actions';

import styles from './index.module.scss';

class KeyData extends Component {
    handleChange(type, value) {
        const { handleChange } = this.props;
        handleChange(type, value);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { dimensionAware, exportNumber, stripe, x, y } = this.props;
        // the dimensions of the box are needed during the pptx generation
        // the sidebar stripes are used for the mesurement (with the props "dimensionAware")
        // when the pptx generation is launched the exportNumber is incremented
        // when the exportNumber changes the mesurements are made and the dataBoxDimensions props is updated
        // the api call is made only if all dataBoxDimensions are available
        // after the pptx generation the dataBoxDimensions props is reset
        // idem for: titleBoxDimensions and quotationBoxDimensions
        if (dimensionAware && exportNumber !== nextProps.exportNumber) {
            const { offsetHeight, offsetWidth } = this.boxContainer;
            const { offsetHeight: dataHeight } = this.dataContainer;
            this.props.updateStripeDataBoxSize({
                stripeId: stripe.id,
                x,
                y,
                dataBoxDimensions: {
                    boxWidth: offsetWidth,
                    boxHeight: offsetHeight,
                    dataHeight,
                },
            });
        }
    }

    render() {
        const { value, editable, columns, lines, t } = this.props;
        // calculated based on fixed 1024px stripe width and inner paddings + margins
        // key data boxes are limited to max 800px
        const dataTextareaWidths = [780, 442, 280];
        const dataTextareaWidth = {
            width: `${dataTextareaWidths[columns - 1]}px`,
        };
        return (
            <div className={styles.container}>
                {editable && (
                    <div
                        className={classNames({
                            [styles.keyDataContainer]: true,
                            [styles.keyDataContainerEdit]: true,
                        })}
                    >
                        <Textarea
                            withHeight
                            style={dataTextareaWidth}
                            className={styles.data}
                            placeholder={t('design.data')}
                            size="large"
                            value={value.data}
                            handleChange={(value) => this.handleChange('data', value)}
                        />
                        {lines < 4 && (
                            <Textarea
                                withHeight
                                className={styles.paragraph}
                                placeholder={t('design.caption')}
                                size="small"
                                value={value.paragraph}
                                handleChange={(value) => this.handleChange('paragraph', value)}
                            />
                        )}
                    </div>
                )}
                {!editable && (
                    <div
                        ref={(node) => (this.boxContainer = node)}
                        className={classNames({
                            [styles.keyDataContainer]: true,
                            [styles.boxShadow]: true,
                        })}
                    >
                        <p className={styles.dataDisplay} ref={(node) => (this.dataContainer = node)}>
                            {value.data}
                        </p>
                        {value.paragraph && <p className={styles.paragraphDisplay}>{value.paragraph}</p>}
                    </div>
                )}
            </div>
        );
    }
}

KeyData.propTypes = {
    handleChange: PropTypes.func,
    value: PropTypes.object,
    editable: PropTypes.bool,
    columns: PropTypes.number,
    lines: PropTypes.number,
    dimensionAware: PropTypes.bool,
    stripe: PropTypes.object,
    x: PropTypes.number,
    y: PropTypes.number,
    updateStripeDataBoxSize: PropTypes.func,
    exportNumber: PropTypes.number,
    t: PropTypes.func,
};

KeyData.defaultProps = {
    dimensionAware: false,
};

const mapStateToProps = (state) => ({
    exportNumber: state.export.exportNumber,
});

const mapDispatchToProps = (dispatch) => ({
    updateStripeDataBoxSize: (payload) => {
        dispatch(updateStripeDataBoxSize(payload));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(KeyData));
