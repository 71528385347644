import React from 'react';
import PropTypes from 'prop-types';
import { CancelButton, StoryModal, ValidateButton } from 'components';
import { withTranslation } from 'react-i18next';

import styles from './index.module.scss';

const ConfirmationModal = ({ isOpen, onRequestClose, children, onValidate, t }) => (
    <StoryModal isOpen={isOpen} onRequestClose={onRequestClose}>
        {children}
        <div className={styles.buttons}>
            <CancelButton onClick={onRequestClose} />
            <ValidateButton onClick={onValidate}>{t('button.delete')}</ValidateButton>
        </div>
    </StoryModal>
);

ConfirmationModal.propTypes = {
    isOpen: PropTypes.bool,
    onRequestClose: PropTypes.func,
    onValidate: PropTypes.func,
    children: PropTypes.node,
    t: PropTypes.func,
};

ConfirmationModal.defaultProps = {
    isOpen: false,
    onRequestClose: () => {},
};

export default withTranslation()(ConfirmationModal);
