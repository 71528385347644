import { compare } from 'fast-json-patch';
import { call, put, select, delay, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
    updateStory,
    createStory,
    getMyStories,
    getStory,
    downloadPPTX,
    patchStory,
    deletePPTX,
    deleteImage,
} from 'common/story/api';
import auth from 'common/auth';
import { storySelector } from 'common/story/reducers';
import { isExportLoading } from 'common/export/reducers';
import { currentStripeSelector } from 'common/currentStripe/reducers';
import { setStory, addStripe, resetStripeDimensions, removeStripe, setSaveError } from 'common/story/actions';
import { setRemoteStory } from 'common/remoteStory/actions';
import { remoteStorySelector } from 'common/remoteStory/reducers';
import { openDesignAccessModal } from 'common/design/actions';
import { setStories, addStory } from 'common/stories/actions';
import { updateLoading } from 'common/loading/actions';
import { setCurrentStripe } from 'common/currentStripe/actions';
import { updateExport, incrementExportNumber } from 'common/export/actions';
import { toastr } from 'react-redux-toastr';
import FileSaver from 'file-saver';
import storyItems from 'config/storyItems';
import paths from 'config/paths';
import { PlainStripe } from 'models';
import { isInStoryTunnel } from 'services/storyService';
import { authenticatedRequest } from 'common/middlewares';
import allDimensionsAvailable from 'services/dimensionService';
import { capture } from 'services/errorMonitoring';
import { displayToastrFromSaga as displayToastr } from 'services/toastrService';
import storyActionTypes from './actionTypes';

export function* patchStoryRequest(action = {}) {
    const { message, redirectUrl } = action;
    try {
        const remoteStory = yield select(remoteStorySelector);
        const story = yield select(storySelector);
        // there is no selected story
        if (!story.id) {
            yield call(displayToastr, message?.error || 'save.error', toastr.error);
            return;
        }
        // DON'T update a different story
        if (story.id !== remoteStory.id) {
            yield call(displayToastr, message?.error || 'save.error', toastr.error);
            throw new Error();
        }
        const storyToSend = {
            ...story,
            stripes: story.stripes.map((stripe) => {
                const updatedStripe = { ...stripe };
                delete updatedStripe.titleBoxDimensions;
                delete updatedStripe.dataBoxDimensions;
                delete updatedStripe.quotationBoxDimensions;
                return updatedStripe;
            }),
        };

        const patch = compare(remoteStory, storyToSend);
        console.log(story, remoteStory);
        const updatedStory = yield authenticatedRequest(patchStory, story.id, JSON.stringify(patch));
        yield put(setRemoteStory(updatedStory));

        // keep dimensions for ongoing export
        const upToDateCurrrentStory = yield select(storySelector);
        const localStory = {
            ...updatedStory,
            stripes: updatedStory.stripes.map((stripe) => {
                const localStripe = upToDateCurrrentStory.stripes.find((locStripe) => locStripe.id === stripe.id);
                return {
                    ...stripe,
                    titleBoxDimensions: localStripe ? localStripe.titleBoxDimensions : {},
                    dataBoxDimensions: localStripe ? localStripe.dataBoxDimensions : [],
                    quotationBoxDimensions: localStripe ? localStripe.quotationBoxDimensions : [],
                };
            }),
        };
        yield put(setStory(localStory));
        if (!message?.isSilent) {
            yield call(displayToastr, message?.success || 'save.saved');
        }
    } catch (error) {
        capture(error, "Couldn't patch story");
        yield put(setSaveError());
    } finally {
        if (redirectUrl) {
            yield put(push(redirectUrl));
        }
    }
}

export function* updateStoryRequest(action = {}) {
    // keep dimensions and dont send size
    const { payload, message, redirectUrl } = action;
    try {
        const updatedStory = yield authenticatedRequest(updateStory, payload);
        yield put(setStory(updatedStory));
        yield put(setRemoteStory(updatedStory));
        if (!message?.isSilent) {
            yield call(displayToastr, message?.success || 'save.saved');
        }
    } catch (error) {
        capture(error, "Couldn't update story");
        yield call(displayToastr, message?.error || 'save.error', toastr.error);
    } finally {
        if (redirectUrl) {
            yield put(push(redirectUrl));
        }
    }
}

export function* deleteStoryRequest(action = {}) {
    const { storyId } = action;
    yield call(updateStoryRequest, {
        payload: { id: storyId, isDeleted: true },
        message: { success: 'homepage.story_delete.success', error: 'homepage.story_delete.error' },
    });
    const stories = yield authenticatedRequest(getMyStories);
    yield put(setStories(stories));
}

function* launchExport(story) {
    const { fileStream, fileKey } = yield call(downloadPPTX, story);
    const file = new Blob([fileStream], {
        type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    });
    const currentDate = new Date(Date.now());
    const date = currentDate.toLocaleDateString().replace(/\//g, '-');
    const time = currentDate.toLocaleTimeString().replace(/:/g, '').substring(0, 4);
    FileSaver.saveAs(file, `storymakers_pitch_${date}_${time}.pptx`);
    yield call(displayToastr, 'export.downloaded');
    yield put(resetStripeDimensions());
    yield put(updateExport({ isLoading: false, isModalOpen: false }));
    yield call(deletePPTX, fileKey);
}

function* checkIfDimensionsAreCalculated(iteration = 0) {
    const storyWithDimensions = yield select(storySelector);
    const allDimensionsReady = storyWithDimensions.stripes.every((stripe) => allDimensionsAvailable(stripe));
    if (!allDimensionsReady && iteration > 5) throw new Error('Unable to calculate all dimensions');
    if (!allDimensionsReady) {
        yield delay(500);
        yield call(checkIfDimensionsAreCalculated, iteration + 1);
    } else {
        yield launchExport(storyWithDimensions);
    }
}

export function* exportPPTXRequest() {
    try {
        yield put(updateExport({ isLoading: true, isModalOpen: true }));
        yield put(incrementExportNumber());
        yield call(checkIfDimensionsAreCalculated);
    } catch (error) {
        capture(error, "Couldn't export pptx");
        yield put(updateExport({ isLoading: false, isModalOpen: false }));
        yield call(displayToastr, 'export.error', toastr.error);
    }
}

export function* storyNextStepRequest() {
    yield put(updateLoading({ story: true }));
    try {
        yield call(patchStoryRequest, { message: { isSilent: true } });
        const updatedStory = yield select(storySelector);
        if (!isInStoryTunnel(updatedStory)) {
            yield put(updateLoading({ story: false }));
            return yield put(push(`/design/${updatedStory.id}`));
        }
        const currentStripe = yield select(currentStripeSelector);
        const currentPositionInStory = storyItems.indexOf(currentStripe.type) + 1;
        if (currentPositionInStory === storyItems.length) {
            yield put(updateLoading({ story: false }));
            return yield put(openDesignAccessModal());
        }
        // if the next step is not yet present in the story we have to add it at the end of the stripes
        // otherwise we have to chose it as current stripe
        const nextStripe = updatedStory.stripes.find((stripe) => {
            return stripe.type === storyItems[currentPositionInStory];
        });
        if (!nextStripe) {
            yield put(addStripe(storyItems[currentPositionInStory]));
        } else {
            yield put(setCurrentStripe(nextStripe));
        }
        yield put(updateLoading({ story: false }));
    } catch (error) {
        capture(error, "Couldn't go to story next step");
        yield put(updateLoading({ story: false }));
    }
}

export function* storyPreviousStepRequest() {
    const story = yield select(storySelector);
    yield call(patchStoryRequest, { message: { isSilent: true } });
    if (!isInStoryTunnel(story)) return yield put(push(`/facts/${story.id}`));
    const currentStripe = yield select(currentStripeSelector);
    const isValidStripe = storyItems.includes(currentStripe.type);
    if (!isValidStripe || currentStripe.type === storyItems[0]) {
        return yield put(push(`/facts/${story.id}`));
    }
    const previousPositionInStory = storyItems.indexOf(currentStripe.type) - 1;
    const previousStripe = story.stripes.find((stripe) => {
        return stripe.type === storyItems[previousPositionInStory];
    });
    return yield put(setCurrentStripe(previousStripe));
}

export function* addStripeRequest() {
    const story = yield select(storySelector);
    const currentStripe = yield select(currentStripeSelector);
    const stripeIndex = story.stripes.findIndex((stripe) => {
        return stripe.id === currentStripe.id;
    });
    yield put(addStripe(currentStripe.type || storyItems[0], stripeIndex));
}

export function* createStoryRequest(action) {
    try {
        const story = yield authenticatedRequest(createStory, action.payload);
        yield put(addStory(story));
        yield put(push(`/intro/${story.id}`));
    } catch (error) {
        capture(error, "Couldn't create story");
        yield call(displayToastr, 'save.error', toastr.error);
    }
}

export function* saveStoryPeriodically() {
    yield takeLatest(
        [
            storyActionTypes.ADD_STRIPE,
            storyActionTypes.UPDATE_STRIPE,
            storyActionTypes.MOVE_STRIPE,
            storyActionTypes.SET_STRIPE_DESIGN,
            storyActionTypes.REMOVE_STRIPE,
            storyActionTypes.UPDATE_STORY_FACTS,
            storyActionTypes.UPDATE_STORY_PITCH,
        ],
        function* () {
            yield delay(3000);
            const isExportActive = yield select(isExportLoading);
            if (!isExportActive) {
                yield call(displayToastr, 'save.in_progress');
                yield delay(1000);
                yield call(patchStoryRequest);
            } else {
                yield delay(3000);
            }
        }
    );
}

export function* getStoriesRequest() {
    try {
        const stories = yield authenticatedRequest(getMyStories);
        yield put(setStories(stories));
    } catch (error) {
        capture(error, 'Error at getting my stories');
    }
}

export function* setCurrentStoryRequest(action) {
    try {
        const story = yield authenticatedRequest(getStory, action.payload);
        if (story.stripes.length === 0) {
            story.stripes.push(new PlainStripe());
        }
        const isPreviewPage = window.location.pathname.includes(paths.PREVIEW);
        if (!isPreviewPage && auth.getUserId() !== story.ownerId) yield put(push(paths.LOGIN));
        yield put(setStory(story));
        yield put(setRemoteStory(story));
        yield put(setCurrentStripe(story.stripes[0]));
    } catch (err) {
        capture(err, `Error getting story: ${action.payload}`);
        yield put(push('/'));
    }
}

export function* removeStripeRequest({ stripe }) {
    const currentStripe = yield select(currentStripeSelector);
    const { stripes, id: storyId } = yield select(storySelector);
    if (currentStripe.id === stripe.id) {
        const currentStripeIndex = stripes.findIndex((element) => element.id === stripe.id);
        if (currentStripeIndex > 0) {
            yield put(setCurrentStripe(stripes[currentStripeIndex - 1]));
        } else if (stripes.length > 1) {
            yield put(setCurrentStripe(stripes[1]));
        } else {
            yield put(setCurrentStripe({}));
        }
    }
    // delete backgroundImage from cloudinary before deleting the stripe
    if (stripe.backgroundImage) {
        yield authenticatedRequest(deleteImage, storyId, stripe.id, stripe.backgroundImage.public_id, null);
    }
    yield put(removeStripe(stripe));
}
