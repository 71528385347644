import actionTypes from './actionTypes';

const initialState = null;

export default function remoteStoryReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.SET_REMOTE_STORY:
            return {
                ...action.payload,
            };

        case actionTypes.RESET_REMOTE_STORY:
            return initialState;
        default:
            return state;
    }
}

export function remoteStorySelector(state) {
    return state.remoteStory;
}
