const actionTypes = {
    SET_STORY: 'STORY/SET',
    UPDATE_STORY: 'STORY/UPDATE',
    RESET_STORY: 'STORY/RESET',
    UPDATE_STRIPE: 'STORY/UPDATE_STRIPE',
    ADD_STRIPE: 'STORY/ADD_STRIPE',
    REMOVE_STRIPE: 'STORY/REMOVE_STRIPE',
    MOVE_STRIPE: 'STORY/MOVE_STRIPE',
    SET_STRIPE_DESIGN: 'STORY/SET_STRIPE_DESIGN',
    RESET_STRIPE_DIMENSIONS: 'STORY/RESET_STRIPE_DIMENSIONS',
    UPDATE_STRIPE_TITLE_BOX_SIZE: 'STORY/UPDATE_STRIPE_TITLE_BOX_SIZE',
    UPDATE_STRIPE_DATA_BOX_SIZE: 'STORY/UPDATE_STRIPE_DATA_BOX_SIZE',
    UPDATE_STRIPE_QUOTATION_SIZE: 'STORY/UPDATE_STRIPE_QUOTATION_SIZE',
    UPDATE_STORY_FACTS: 'STORY/UPDATE_FACTS',
    UPDATE_STORY_PITCH: 'STORY/UPDATE_PITCH',
    SET_SAVE_ERROR: 'STORY/SET_SAVE_ERROR',

    CREATE_STORY_REQUEST: 'STORY/CREATE_STORY_REQUEST',
    UPDATE_STORY_REQUEST: 'STORY/UPDATE_REQUEST',
    PATCH_STORY_REQUEST: 'STORY/PATCH_REQUEST',
    DELETE_STORY_REQUEST: 'STORY/DELETE_REQUEST',
    SET_CURRENT_STORY_REQUEST: 'STOR/SET_CURRENT_STORY_REQUEST',
    ADD_STRIPE_REQUEST: 'STORY/ADD_STRIPE_REQUEST',
    REMOVE_STRIPE_REQUEST: 'STORY/REMOVE_STRIPE_REQUEST',
    EXPORT_PPT_REQUEST: 'STORY/EXPORT_PPT_REQUEST',
    STORY_NEXT_STEP_REQUEST: 'STORY/NEXT_STEP_REQUEST',
    STORY_PREVIOUS_STEP_REQUEST: 'STORY/PREVIOUS_STEP_REQUEST',
};

export default actionTypes;
