const actionTypes = {
    SET_USER: 'USER/SET',
    SET_LOGIN_ERROR: 'USER/SET_LOGIN_ERROR',
    RESET_LOGIN_ERROR: 'USER/RESET_LOGIN_ERROR',

    LOGIN_REQUEST: 'USER/LOGIN_REQUEST',
    LOGOUT_REQUEST: 'USER/LOGOUT_REQUEST',
};

export default actionTypes;
