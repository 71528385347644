import React from 'react';
import PropTypes from 'prop-types';

const Select = ({ value, onChange, options, className }) => {
    const handleChange = (event) => {
        onChange(event.target.value);
    };
    return (
        <select value={value} onChange={handleChange} className={`select ${className}`}>
            {options.map((option) => (
                <option key={option.value} value={option.value}>
                    {option.label}
                </option>
            ))}
        </select>
    );
};

Select.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        })
    ),
    className: PropTypes.string,
};

export default Select;
