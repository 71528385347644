import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'components';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';

import styles from './index.module.scss';

const AddButton = ({ t, label, disabled, onClick }) => (
    <button
        onClick={() => !disabled && onClick()}
        className={classNames(styles.button, disabled ? styles.disabled : {})}
    >
        <Icon className={styles.addIcon} iconName="add" />
        {t(label)}
    </button>
);

AddButton.propTypes = {
    onClick: PropTypes.func,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    t: PropTypes.func,
};

AddButton.defaultProps = {
    label: 'button.add_stripe',
    disabled: false,
};

export default withTranslation()(AddButton);
