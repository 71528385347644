import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { isInStoryTunnel } from 'services/storyService';
import { Icon, StripeFactory } from 'components';
import { PlainStripe } from 'models';

import styles from './index.module.scss';

class Story extends Component {
    selectStory() {
        const { story, history } = this.props;
        const nextStep = !isInStoryTunnel(story) ? 'design' : 'story';
        history.push(`/${nextStep}/${story.id}`);
    }

    deleteStory = (event) => {
        const { onStoryDelete } = this.props;
        event.stopPropagation();
        onStoryDelete();
    };

    render() {
        const { story } = this.props;
        return (
            <div className={styles.story}>
                <div className={styles.firstStripe} onClick={() => this.selectStory()}>
                    {StripeFactory.sidebar(story.stripes[0] || new PlainStripe(), story.title)}
                    <div onClick={this.deleteStory} className="deleteIconContainer">
                        <Icon iconName="delete" />
                    </div>
                </div>
            </div>
        );
    }
}

Story.propTypes = {
    history: PropTypes.object.isRequired,
    story: PropTypes.object.isRequired,
    onStoryDelete: PropTypes.func,
};

export default withRouter(Story);
