import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Story from '../Story';

import styles from './index.module.scss';

const StoryList = ({ onStoryDelete, stories }) => (
    <div className={styles.container}>
        {stories.map((story, index) => (
            <Story key={`${index}-${story.id}`} story={story} onStoryDelete={() => onStoryDelete(story)} />
        ))}
    </div>
);

StoryList.propTypes = {
    stories: PropTypes.array.isRequired,
    onStoryDelete: PropTypes.func,
};

const mapStateToProps = (state) => ({
    stories: state.stories,
});

export default connect(mapStateToProps)(StoryList);
