import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { moveStripe } from 'common/actions';
import DraggableStripe from '../DraggableStripe';

import styles from './index.module.scss';

class DraggableStripeContainer extends Component {
    constructor(props) {
        super(props);
        this.moveCard = this.moveCard.bind(this);
        this.state = {
            cards: props.stripes,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            cards: nextProps.stripes,
        });
    }

    moveCard(dragIndex, hoverIndex) {
        this.props.moveStripe(dragIndex, hoverIndex);
    }

    render() {
        const { cards } = this.state;

        return (
            <div className={styles.container}>
                {cards.map((card, i) => {
                    const hasSameTypeThanPreviousStripe = i > 0 && card.type === cards[i - 1].type;

                    return (
                        <DraggableStripe
                            key={`${i}-${card.id}`}
                            index={i}
                            stripe={card}
                            deletable={this.props.allStripesDeletable || hasSameTypeThanPreviousStripe}
                            isDropTarget={i > 0}
                            draggable={this.props.allStripesDraggable || hasSameTypeThanPreviousStripe}
                            moveCard={this.moveCard}
                        />
                    );
                })}
            </div>
        );
    }
}

DraggableStripeContainer.propTypes = {
    stripes: PropTypes.array.isRequired,
    moveStripe: PropTypes.func.isRequired,
    allStripesDraggable: PropTypes.bool,
    allStripesDeletable: PropTypes.bool,
};

const mapStateToProps = (state) => ({
    stripes: state.story.stripes,
});

const mapDispatchToProps = {
    moveStripe,
};

export default connect(mapStateToProps, mapDispatchToProps)(DraggableStripeContainer);
