import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Icon } from 'components';

import styles from './index.module.scss';

const BackButton = ({ t, disabled, onClick, className }) => (
    <button
        onClick={() => !disabled && onClick()}
        className={classNames(styles.button, disabled ? styles.disabled : {}, className)}
    >
        <Icon className={styles.icon} iconName="arrow" />
        {t('button.back')}
    </button>
);

BackButton.propTypes = {
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    t: PropTypes.func,
};

BackButton.defaultProps = {
    disabled: false,
};

export default withTranslation()(BackButton);
