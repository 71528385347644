import moment from 'moment';
import { isDatePassed, getDurationEndDate } from './dateService';

const DATE_FORMAT = 'YYYY-MM-DD';

export function hasLicense(user) {
    return Array.isArray(user.licenses) && user.licenses.length > 0;
}

export function shouldAcceptProlongation(user) {
    if (!hasLicense(user)) return false;
    const license = user.licenses[0];
    const { startDate, duration, unlimitedAccess, endDate, prolongationStartDate, prolongationDuration } = license;
    // has origin license expired ?
    if (unlimitedAccess) return false;
    if (endDate) {
        const isEndDatePassed = isDatePassed(endDate);
        if (!isEndDatePassed) return false;
    }
    if (duration) {
        if (!startDate) return false;
        const durationEndDate = getDurationEndDate(duration, startDate);
        const isDurationEndDatePassed = isDatePassed(durationEndDate);
        if (!isDurationEndDatePassed) return false;
    }
    const isStartDateMissing = !!prolongationDuration && !prolongationStartDate;
    return isStartDateMissing;
}

export function getCurrentLicense(user) {
    return hasLicense(user) ? user.licenses[0] : {};
}

export const isDurationValid = (duration) => {
    return !!duration && moment.duration(duration).isValid() && moment.duration(duration).asDays() > 0;
};

export function formatCurrentUserLicense(license) {
    const { startDate, endDate, prolongationStartDate, ...rest } = license;
    const formattedLicense = {
        ...rest,
    };
    if (endDate) formattedLicense.endDate = moment(endDate).format(DATE_FORMAT);
    if (startDate) {
        formattedLicense.startDate = moment(startDate).format(DATE_FORMAT);
    }
    if (prolongationStartDate) {
        formattedLicense.prolongationStartDate = moment(prolongationStartDate).format(DATE_FORMAT);
    }
    return formattedLicense;
}

export const buildUserLicense = (updatedLicense, currentLicense) => {
    const { origin, duration, endDate, unlimitedAccess, prolongationDuration } = updatedLicense;
    // cannot be modified
    const { startDate, prolongationStartDate } = currentLicense;
    const isInitialDurationValid = isDurationValid(duration);
    const isProlongationDurationValid = isDurationValid(prolongationDuration);
    if (!isInitialDurationValid && !endDate && !unlimitedAccess && !isProlongationDurationValid) {
        throw new Error('License does not have a valid duration');
    }
    const newLicense = {
        startDate,
        prolongationStartDate,
        origin,
        unlimitedAccess,
    };
    if (!unlimitedAccess && endDate) {
        newLicense.endDate = moment(endDate).endOf('day').toISOString();
    }
    if (!unlimitedAccess && !endDate && isInitialDurationValid) {
        newLicense.duration = duration;
    }
    if (isProlongationDurationValid) {
        newLicense.prolongationDuration = prolongationDuration;
    }
    return newLicense;
};

// simplified logic that only works since the iso durations have only on unit
// P15D, P2W, P12M...
export const getDurationUnit = (isoDuration = '') =>
    !!isoDuration && moment.duration(isoDuration).isValid() ? isoDuration.substring(isoDuration.length - 1) : 'D';

export const getDurationValue = (isoDuration = '') =>
    !!isoDuration && moment.duration(isoDuration).isValid()
        ? parseInt(isoDuration.substring(1, isoDuration.length - 1), 10)
        : 0;

export const buildIsoDuration = (value, unit = 'D') => {
    const duration = `P${value}${unit}`;
    return moment.duration(duration).isValid() ? duration : undefined;
};

export const getDefaultLicense = (licenseConfig) => {
    return {
        origin: licenseConfig.origins[0],
        duration: licenseConfig.defaultDuration,
        prolongationDuration: licenseConfig.defaultProlongationDuration,
    };
};
