import React from 'react';
import PropTypes from 'prop-types';
import config from 'config/design';
import styles from './index.module.scss';

const Loader = ({ color, className, fullPage }) => (
    <div className={`${styles.container} ${className} ${fullPage ? styles.fullpageContainer : ''}`}>
        <div className={styles.content}>
            <div className={styles.loader}>
                <div className={styles.bubbles}>
                    <div className={styles.bubbleContainer}>
                        <div style={{ backgroundColor: color }} className={styles.bubble} />
                    </div>
                    <div className={styles.bubbleContainer}>
                        <div style={{ backgroundColor: color }} className={`${styles.bubble} ${styles.bubbleSecond}`} />
                    </div>
                    <div className={styles.bubbleContainer}>
                        <div style={{ backgroundColor: color }} className={`${styles.bubble} ${styles.bubbleThird}`} />
                    </div>
                </div>
            </div>
        </div>
    </div>
);

Loader.propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
    fullPage: PropTypes.bool,
};

Loader.defaultProps = {
    color: config.HIGHLIGHT_COLOR,
    fullPage: false,
};

export default Loader;
