import { PlainStripe, Story } from 'models';
import stripeBuilder from 'models/stripeFactory';
import actionTypes from './actionTypes';

export function setStory(payload) {
    return {
        type: actionTypes.SET_STORY,
        payload,
    };
}

export function resetStory() {
    return {
        type: actionTypes.RESET_STORY,
    };
}

export function updateStory(payload) {
    return {
        type: actionTypes.UPDATE_STORY,
        payload,
    };
}

export function updateStoryFacts(payload) {
    return {
        type: actionTypes.UPDATE_STORY_FACTS,
        payload,
    };
}

export function updateStoryPitch(payload) {
    return {
        type: actionTypes.UPDATE_STORY_PITCH,
        payload,
    };
}

export function updateStripe(payload) {
    return {
        type: actionTypes.UPDATE_STRIPE,
        payload,
    };
}

export function updateStripeTitleBoxSize(payload) {
    return {
        type: actionTypes.UPDATE_STRIPE_TITLE_BOX_SIZE,
        payload,
    };
}

export function setStripeDesign(payload) {
    return {
        type: actionTypes.SET_STRIPE_DESIGN,
        payload: stripeBuilder(payload),
    };
}

export function updateStripeDataBoxSize(payload) {
    return {
        type: actionTypes.UPDATE_STRIPE_DATA_BOX_SIZE,
        payload,
    };
}

export function updateStripeQuotationSize(payload) {
    return {
        type: actionTypes.UPDATE_STRIPE_QUOTATION_SIZE,
        payload,
    };
}

export function patchStoryRequest(redirectUrl, message) {
    return {
        type: actionTypes.PATCH_STORY_REQUEST,
        redirectUrl,
        message,
    };
}

export function updateStoryRequest(payload, redirectUrl, message) {
    return {
        type: actionTypes.UPDATE_STORY_REQUEST,
        payload,
        redirectUrl,
        message,
    };
}

export function deleteStoryRequest(storyId) {
    return {
        type: actionTypes.DELETE_STORY_REQUEST,
        storyId,
    };
}

export function exportPPTXRequest() {
    return {
        type: actionTypes.EXPORT_PPT_REQUEST,
    };
}

export const removeStripe = (stripe) => ({
    type: actionTypes.REMOVE_STRIPE,
    stripe,
});

export const removeStripeRequest = (stripe) => ({
    type: actionTypes.REMOVE_STRIPE_REQUEST,
    stripe,
});

export const createStoryRequest = () => ({
    type: actionTypes.CREATE_STORY_REQUEST,
    payload: new Story(),
});

export const addStripe = (type, position) => {
    const stripe = new PlainStripe();
    return {
        type: actionTypes.ADD_STRIPE,
        payload: {
            ...stripe,
            type,
        },
        position,
    };
};

export const resetStripeDimensions = () => ({
    type: actionTypes.RESET_STRIPE_DIMENSIONS,
});

export const addStripeRequest = () => ({
    type: actionTypes.ADD_STRIPE_REQUEST,
});

export const storyNextStepRequest = () => ({
    type: actionTypes.STORY_NEXT_STEP_REQUEST,
});

export const storyPreviousStepRequest = () => ({
    type: actionTypes.STORY_PREVIOUS_STEP_REQUEST,
});

export const moveStripe = (oldStripePosition, newStripePosition) => ({
    type: actionTypes.MOVE_STRIPE,
    oldStripePosition,
    newStripePosition,
});

export const setCurrentStoryRequest = (storyId) => ({
    type: actionTypes.SET_CURRENT_STORY_REQUEST,
    payload: storyId,
});

export const setSaveError = () => ({
    type: actionTypes.SET_SAVE_ERROR,
});
