import designItems from 'config/designItems';
import actionTypes from './actionTypes';

const initialState = {
    popinType: designItems[0],
    publishModalIsOpen: false,
    designAccessModalIsOpen: false,
    titleModalIsOpen: false,
    isCustomizationModalBlocked: false,
};

export default function update(state = initialState, action) {
    switch (action.type) {
        case actionTypes.SET_DESIGN_POPIN_TYPE:
            return {
                ...state,
                popinType: action.popinType,
            };
        case actionTypes.OPEN_PUBLISH_MODAL:
            return {
                ...state,
                publishModalIsOpen: true,
            };
        case actionTypes.CLOSE_PUBLISH_MODAL:
            return {
                ...state,
                publishModalIsOpen: false,
            };
        case actionTypes.OPEN_TITLE_MODAL:
            return {
                ...state,
                titleModalIsOpen: true,
            };
        case actionTypes.CLOSE_TITLE_MODAL:
            return {
                ...state,
                titleModalIsOpen: false,
            };
        case actionTypes.OPEN_DESIGN_ACCESS_MODAL:
            return {
                ...state,
                designAccessModalIsOpen: true,
            };
        case actionTypes.CLOSE_DESIGN_ACCESS_MODAL:
            return {
                ...state,
                designAccessModalIsOpen: false,
            };
        case actionTypes.SET_CUSTOMIZATION_MODAL_BLOCKED:
            return {
                ...state,
                isCustomizationModalBlocked: action.payload,
            };
        default:
            return state;
    }
}

export function currentPopinTypeSelector(state) {
    return state.design.popinType;
}
