import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { isInStoryTunnel } from 'services/storyService';

import {
    AddButton,
    BackButton,
    NextButton,
    DraggableStripeContainer,
    Storybar,
    EditorContainer,
    StripeFactory,
    Toastr,
    StoryModal,
    Loader,
} from 'components';

import DesignAccessModal from './components/DesignAccessModal';

import {
    updateStripe,
    storyNextStepRequest,
    storyPreviousStepRequest,
    addStripeRequest,
    closeDesignAccessModal,
} from 'common/actions';
import { STORY } from 'config/steps';
import storyItems from 'config/storyItems';

import styles from './index.module.scss';

class StoryPage extends Component {
    componentWillUnmount() {
        this.props.closeDesignAccessModal();
    }

    handleChange(title) {
        this.props.updateStripe({
            id: this.props.currentStripe.id,
            title,
        });
    }

    render() {
        const { currentStripe, story, designAccessModalIsOpen, closeDesignAccessModal, isStoryLoading } = this.props;
        const actionsDisabled = isStoryLoading && isInStoryTunnel(story);
        if (!this.props.currentStripe.type) return <Loader fullpage />;
        return (
            <div className={styles.storyContainer}>
                <StoryModal isOpen={designAccessModalIsOpen} onRequestClose={() => closeDesignAccessModal()}>
                    <DesignAccessModal />
                </StoryModal>
                <Storybar>
                    <DraggableStripeContainer
                        allStripesDraggable={!isInStoryTunnel(story)}
                        allStripesDeletable={!isInStoryTunnel(story)}
                    />
                    {(!isInStoryTunnel(story) || story.stripes.length > 1) && (
                        <AddButton disabled={actionsDisabled} onClick={() => this.props.addStripe()} />
                    )}
                </Storybar>
                <EditorContainer
                    page={STORY}
                    type={this.props.currentStripe.type}
                    subTypes={!isInStoryTunnel(story) ? storyItems : []}
                    currentStep={STORY}
                >
                    {StripeFactory.editor(currentStripe, story.hasDesignAccess)}
                    <Toastr />
                </EditorContainer>
                <div className="buttons">
                    <div className="button">
                        <BackButton disabled={actionsDisabled} onClick={() => this.props.previousStep()} />
                    </div>
                    <div className="button">
                        <NextButton disabled={actionsDisabled} onClick={() => this.props.nextStep()} />
                    </div>
                </div>
            </div>
        );
    }
}

StoryPage.propTypes = {
    currentStripe: PropTypes.object.isRequired,
    story: PropTypes.object.isRequired,
    designAccessModalIsOpen: PropTypes.bool,
    updateStripe: PropTypes.func.isRequired,
    nextStep: PropTypes.func.isRequired,
    previousStep: PropTypes.func.isRequired,
    addStripe: PropTypes.func.isRequired,
    closeDesignAccessModal: PropTypes.func.isRequired,
    isStoryLoading: PropTypes.bool,
};

StoryPage.defaultProps = {
    isStoryLoading: false,
};

const mapStateToProps = (state) => ({
    currentStripe: state.currentStripe,
    story: state.story,
    designAccessModalIsOpen: state.design.designAccessModalIsOpen,
    isStoryLoading: state.loading.story,
});

const mapDispatchToProps = (dispatch) => ({
    updateStripe: (stripe) => {
        dispatch(updateStripe(stripe));
    },
    nextStep: () => {
        dispatch(storyNextStepRequest());
    },
    previousStep: () => {
        dispatch(storyPreviousStepRequest());
    },
    addStripe: () => {
        dispatch(addStripeRequest());
    },
    closeDesignAccessModal: () => dispatch(closeDesignAccessModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(StoryPage);
