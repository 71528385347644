import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { updateBannerHeight } from 'common/actions';
import getTextColor from 'services/colorService';
import config from 'config/design';
import { DisplayedTitle, EditableTitle } from '../components';

import styles from './index.module.scss';

class TransitionStripe extends Component {
    componentDidMount() {
        const { preview, stripe } = this.props;
        if (preview) {
            this.props.updateBannerHeight({ [stripe.id]: this.banner.offsetHeight });
        }
    }
    render() {
        const { editable, stripe, preview, customizable, dimensionAware } = this.props;
        const stripeColor = getTextColor(stripe);
        return (
            <div className={styles.container}>
                <div
                    ref={(node) => (this.banner = node)}
                    className={classNames({
                        [styles.banner]: true,
                        bannerLight: !preview && stripeColor === config.DARK,
                        bannerDark: !preview && !(stripeColor === config.DARK),
                    })}
                >
                    {editable ? (
                        <EditableTitle
                            stripe={stripe}
                            size="large"
                            className={styles.transitionTitle}
                            customizable={customizable}
                        />
                    ) : (
                        <DisplayedTitle
                            size="large"
                            className={styles.transitionTitle}
                            title={stripe.title}
                            dimensionAware={dimensionAware}
                            stripe={stripe}
                        />
                    )}
                </div>
            </div>
        );
    }
}

TransitionStripe.propTypes = {
    updateBannerHeight: PropTypes.func,
    editable: PropTypes.bool,
    stripe: PropTypes.object,
    preview: PropTypes.bool,
    customizable: PropTypes.bool,
    dimensionAware: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => ({
    updateBannerHeight: (height) => {
        dispatch(updateBannerHeight(height));
    },
});

TransitionStripe.defaultProps = {
    dimensionAware: false,
};

export default connect(null, mapDispatchToProps)(TransitionStripe);
