import React from 'react';
import styles from '../../index.module.scss';

const Header = () => (
    <div className={`${styles.container} ${styles.header}`}>
        <div className={`${styles.item} ${styles.dateItem}`}>Created</div>
        <div className={`${styles.item} ${styles.mediumItem}`}>Origin</div>
        <div className={`${styles.item} ${styles.dateItem}`}>Start</div>
        <div className={`${styles.item} ${styles.mediumItem}`}>Duration/End</div>
        <div className={`${styles.item} ${styles.dateItem}`}>Offer start</div>
        <div className={`${styles.item} ${styles.mediumItem}`}>Offer duration</div>
        <div className={`${styles.item} ${styles.dateItem}`}>Last connection </div>
        <div className={`${styles.item} ${styles.firstNameItem}`}>First name</div>
        <div className={`${styles.item} ${styles.nameItem}`}>Last name</div>
        <div className={styles.item}>Email</div>
        <div className={`${styles.item} ${styles.itemShort}`}>Status</div>
        <div className={`${styles.item} ${styles.numberItem}`}>Story count</div>
        <div className={`${styles.item} ${styles.dateItem}`}>Latest update</div>
        <div className={styles.button} />
    </div>
);

export default Header;
