import debounce from 'lodash.debounce';
import queryString from 'query-string';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import auth from 'common/auth';
import { fetchUsers, countUsers } from 'common/user/api';
import { logoutRequest } from 'common/user/actions';
import { Input } from 'components';
import UserListItem from './components/UserListItem';
import Header from './components/Header';
import styles from './index.module.scss';
import { capture } from 'services/errorMonitoring';

const limit = 10;

class UserList extends Component {
    constructor(props) {
        super(props);
        this.fetchPaginatedUserList = debounce(this.fetchPaginatedUserList.bind(this), 300);
        const search = queryString.parse(props.location.search);
        const skip = search.skip ? parseInt(search.skip, 10) : 0;
        this.state = { users: [], skip, count: 0, search: '' };
        countUsers(auth.getToken())
            .then(({ count }) => {
                this.setState({ count });
                if (count > 0) this.fetchPaginatedUserList(skip);
            })
            .catch((err) => {
                capture(err, "countUsers: couldn't fetch users");
                if (err.status === 401) return this.props.logoutRequest();
            });
    }

    onSearch = (search) => {
        this.setState({ search }, () => this.fetchPaginatedUserList());
    };

    fetchPaginatedUserList = (currentSkip) => {
        const search = this.shouldDisplaySearchResult() ? this.state.search : '';
        const skip = currentSkip !== undefined ? currentSkip : this.state.skip;
        fetchUsers(limit, skip, search, auth.getToken())
            .then((users) => {
                this.setState({ users });
            })
            .catch((err) => {
                capture(err, "fetchUsers: couldn't fetch users");
                if (err.status === 401) return this.props.logoutRequest();
            });
    };

    shouldDisplaySearchResult = () => {
        const { search } = this.state;
        return search.length > 2;
    };

    isPreviousActive = () => {
        const { skip } = this.state;
        return skip > 0;
    };

    isNextActive = () => {
        const { count, skip } = this.state;
        return count > skip + limit;
    };

    handleFirst = (evt) => {
        evt.preventDefault();
        if (!this.isPreviousActive()) return;
        this.setState({ skip: 0 }, () => this.fetchPaginatedUserList(0));
    };

    handlePrevious = (evt) => {
        evt.preventDefault();
        if (!this.isPreviousActive()) return;
        this.setState(
            (prevState) => ({
                skip: prevState.skip - limit,
            }),
            () => this.fetchPaginatedUserList(this.state.skip)
        );
    };

    handleNext = (evt) => {
        evt.preventDefault();
        if (!this.isNextActive()) return;
        this.setState(
            (prevState) => ({
                skip: prevState.skip + limit,
            }),
            () => this.fetchPaginatedUserList(this.state.skip)
        );
    };

    handleLast = (evt) => {
        evt.preventDefault();
        if (!this.isNextActive()) return;
        const { count } = this.state;
        const numberOfPages = Math.floor(count / limit) + (count % limit > 0 ? 1 : 0);
        const skip = (numberOfPages - 1) * limit;
        this.setState({ skip }, () => this.fetchPaginatedUserList(skip));
    };

    render() {
        const { skip, count, search } = this.state;
        return (
            <div className={styles.list}>
                <div className={styles.titleContainer}>
                    <div className="title">Utilisateurs</div>
                    <Input
                        className={styles.searchInput}
                        handleChange={(value) => {
                            this.onSearch(value);
                        }}
                        value={search}
                        placeholder="Rechercher..."
                    />
                    <div style={{ minWidth: '365px' }}>
                        {!this.shouldDisplaySearchResult() && (
                            <div className={styles.pagination}>
                                <button
                                    className={!this.isPreviousActive() ? styles.disabled : undefined}
                                    onClick={(e) => this.handleFirst(e)}
                                >
                                    ❮❮
                                </button>
                                <button
                                    href="#"
                                    className={!this.isPreviousActive() ? styles.disabled : undefined}
                                    onClick={(e) => this.handlePrevious(e)}
                                >
                                    ❮
                                </button>
                                <button
                                    href="#"
                                    className={!this.isNextActive() ? styles.disabled : undefined}
                                    onClick={(e) => this.handleNext(e)}
                                >
                                    ❯
                                </button>
                                <button
                                    href="#"
                                    className={!this.isNextActive() ? styles.disabled : undefined}
                                    onClick={(e) => this.handleLast(e)}
                                >
                                    ❯❯
                                </button>
                                <span className={styles.paginationInfo}>{`${skip / limit + 1}/${Math.ceil(
                                    count / limit
                                )}`}</span>
                            </div>
                        )}
                    </div>
                </div>
                <Header />
                <div>
                    {this.state.users.map((user, key) => (
                        <UserListItem key={key} user={user} skip={skip} />
                    ))}
                </div>
            </div>
        );
    }
}

UserList.propTypes = {
    logoutRequest: PropTypes.func,
    location: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
    logoutRequest: () => {
        dispatch(logoutRequest());
    },
});

export default connect(null, mapDispatchToProps)(withRouter(UserList));
