import React from 'react';
import PropTypes from 'prop-types';
import BookOnAmazon from './components/BookOnAmazon';

import styles from './index.module.scss';

const LoginBox = ({ children, withVisual, height }) => {
    return (
        <div className={styles.container} style={{ height }}>
            <div className={styles.formContainer}>{children}</div>
            {withVisual && <BookOnAmazon />}
        </div>
    );
};

LoginBox.propTypes = {
    children: PropTypes.node,
    withVisual: PropTypes.bool,
    height: PropTypes.string,
};

LoginBox.defaultProps = {
    children: null,
    withVisual: true,
};

export default LoginBox;
