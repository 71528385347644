import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { updateBookAccessCredentials, updateBookAccessRequest } from 'common/bookAccess/actions';
import PitchItem from 'pages/PitchPage/PitchItem';
import ValidateButton from 'components/buttons/ValidateButton';

const fields = [
    { label: 'page_number', name: 'pageNumber' },
    { label: 'english_word', name: 'englishWord' },
    { label: 'french_word', name: 'frenchWord' },
];

class BookAccessForm extends Component {
    handleChange(key, val) {
        this.props.updateBookAccessCredentials({ [key]: val });
    }

    render() {
        const { bookAccess, t } = this.props;
        return (
            <form>
                {fields.map((field, index) => (
                    <PitchItem
                        key={index}
                        label={t(`book_acces.${field.label}`)}
                        name={field.name}
                        index={index + 1}
                        value={bookAccess[field.name]}
                        handleChange={(key, val) => this.handleChange(key, val)}
                    />
                ))}

                <ValidateButton onClick={() => this.props.updateBookAccessRequest(bookAccess)}>Submit</ValidateButton>
            </form>
        );
    }
}

BookAccessForm.propTypes = {
    updateBookAccessCredentials: PropTypes.func,
    updateBookAccessRequest: PropTypes.func,
    t: PropTypes.func,
    bookAccess: PropTypes.object,
};

BookAccessForm.defaultProps = {
    bookAccess: {},
};

const mapStateToProps = (state) => ({
    bookAccess: state.bookAccess.credentials,
});

const mapDispatchToProps = (dispatch) => ({
    updateBookAccessCredentials: (payload) => {
        dispatch(updateBookAccessCredentials(payload));
    },
    updateBookAccessRequest: (payload) => {
        dispatch(updateBookAccessRequest(payload));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BookAccessForm));
