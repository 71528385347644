import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { Input } from 'components';
import Label from './StoryModalLabel';

const StoryModalInput = (props) => (
    <div className={styles.titleLine}>
        <Label label={props.label} comment={props.comment} name={props.name} />
        <Input
            className={styles.titleInput}
            id={props.name}
            handleChange={props.handleChange}
            value={props.value}
            placeholder={props.placeholder}
        />
    </div>
);

StoryModalInput.propTypes = {
    label: PropTypes.string.isRequired,
    comment: PropTypes.string,
    name: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    value: PropTypes.string,
    placeholder: PropTypes.string,
};

export default StoryModalInput;
