import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Icon } from 'components';
import styles from './index.module.scss';

const ActionButton = ({ onClick, className, children, disabled, iconName, flat }) => (
    <button
        onClick={onClick}
        disabled={disabled}
        className={classNames(styles.button, className, flat ? styles.flatButton : '')}
    >
        {iconName && <Icon className={styles.icon} iconName={iconName} />}
        {children}
    </button>
);

ActionButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    iconName: PropTypes.string,
    disabled: PropTypes.bool,
    flat: PropTypes.bool,
};

ActionButton.defaultProps = {
    className: '',
    iconName: '',
    disabled: false,
    flat: false,
};

export default ActionButton;
