import { KEY_DATA, PLAIN, QUOTATION, TRANSITION } from 'config/stripeTypes';

import { KeyDataStripe, PlainStripe, QuotationStripe, TransitionStripe } from './index';

export default function build(payload) {
    const stripes = {
        [KEY_DATA]: () => new KeyDataStripe(payload),
        [PLAIN]: () => new PlainStripe(payload),
        [QUOTATION]: () => new QuotationStripe(payload),
        [TRANSITION]: () => new TransitionStripe(payload),
        default: () => new PlainStripe(payload),
    };
    return (stripes[payload.design] || stripes.default)();
}
