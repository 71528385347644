import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import auth from 'common/auth';
import { changeLocale, logoutRequest } from 'common/actions';
import { Icon } from 'components';
import styles from './index.module.scss';

const Sidebar = (props) => {
    const { history } = props;
    const onClick = (e) => {
        e.preventDefault();
        props.changeLocale();
    };

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.section}>
                    <div className={`${styles.icon} ${styles.iconHome}`} onClick={() => history.push('/')}>
                        <Icon iconName="homeWhite" />
                    </div>
                    {auth.loggedIn() && (
                        <div className={`${styles.icon} ${styles.iconLogout}`} onClick={() => props.logoutRequest()}>
                            <Icon iconName="logout" />
                        </div>
                    )}
                </div>
                <div className={styles.section}>
                    <button className={styles.lang} onClick={onClick}>
                        {props.nextLocale}
                    </button>
                    <a
                        className={`${styles.icon}`}
                        href="https://storymakers.eu/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Icon iconName="logo" />
                    </a>
                </div>
            </div>
        </div>
    );
};

Sidebar.propTypes = {
    changeLocale: PropTypes.func.isRequired,
    nextLocale: PropTypes.string,
    history: PropTypes.object.isRequired,
    logoutRequest: PropTypes.func,
};

const mapStateToProps = (state) => ({
    nextLocale: state.translations.nextLocale,
});

const mapDispatchToProps = (dispatch) => ({
    changeLocale: () => {
        dispatch(changeLocale());
    },
    logoutRequest: () => {
        dispatch(logoutRequest());
    },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sidebar));
