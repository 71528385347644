import actionTypes from './actionTypes';

const initialState = [];

export default function update(state = initialState, action) {
    switch (action.type) {
        case actionTypes.SET_STORIES:
            return action.payload;
        case actionTypes.ADD_STORY:
            return [...state, action.payload];
        default:
            return state;
    }
}
