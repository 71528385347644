import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import styles from './index.module.scss';

const AddStoryButton = ({ onClick, label, t }) => (
    <button onClick={onClick} className={styles.button}>
        {t(label)}
    </button>
);

AddStoryButton.propTypes = {
    onClick: PropTypes.func,
    label: PropTypes.string,
    t: PropTypes.func,
};

AddStoryButton.defaultProps = {
    label: 'button.add_story',
};

export default withTranslation()(AddStoryButton);
