import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import i18n from 'i18n';
import { Icon } from 'components';

import styles from './index.module.scss';

class Popin extends Component {
    constructor(props) {
        super(props);
        this.state = { open: false };
    }

    toggle() {
        this.setState({ open: !this.state.open });
    }

    render() {
        const { open } = this.state;
        const { t, type, page } = this.props;
        return (
            <div className={styles.container}>
                <div onClick={() => this.toggle()} className={styles.titleContainer}>
                    <div className={styles.title}>{t(`${page}.items.${type}.title`)}</div>
                    <div className={styles.icon}>
                        <Icon iconName={open ? 'minimize' : 'maximize'} />
                    </div>
                </div>
                {open && (
                    <div className={styles.contentContainer}>
                        <div className={styles.subTitle}>{t('story.instructions')}</div>
                        <div className={styles.description}>{t(`${page}.items.${type}.instructions`)}</div>
                        <div className={styles.subTitle}>{t('story.tip')}</div>
                        <div className={styles.description}>{t(`${page}.items.${type}.tip`)}</div>
                        {i18n.exists(`${page}.items.${type}.example`) && (
                            <div className={styles.subTitle}>{t('story.example')}</div>
                        )}
                        {i18n.exists(`${page}.items.${type}.example`) && (
                            <div className={styles.description}>{t(`${page}.items.${this.props.type}.example`)}</div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

Popin.propTypes = {
    page: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    t: PropTypes.func,
};

export default withTranslation()(Popin);
