import React, { lazy, Suspense } from 'react';
import { Loader } from 'components';

import style from './index.module.scss';

const Storybar = lazy(() => import('./Storybar'));

const LazyLoadedStorybar = (props) => (
    <Suspense fallback={<Loader className={`${style.container} ${props.className}`} />}>
        <Storybar {...props} />
    </Suspense>
);

export default LazyLoadedStorybar;
