import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Dimensions from 'components/hocs/Dimensions';
import design from 'config/design';
import { TRANSITION } from 'config/stripeTypes';
import getTextColor from 'services/colorService';
import classNames from 'classnames';
import { getFileUrl } from 'services/imageService';
import scaleCalculator from 'services/scaleCalculator';
import { ColorFilter } from '../components';

import styles from './index.module.scss';

function StripeContainer(props) {
    const { currentStripe, children, bannerHeights, containerWidth, containerHeight, preview } = props;
    const scale = scaleCalculator(containerWidth, containerHeight, design.BASE_STRIPE_WIDTH, design.BASE_STRIPE_HEIGHT);
    const { backgroundImage, backgroundColor, colorFilter, backgroundImageCropValues } = currentStripe;
    const imageUrl = backgroundImage
        ? getFileUrl(backgroundImage.secure_url, backgroundImageCropValues, design.IMAGE_TYPE.PREVIEW)
        : null;

    const backgroundStyle = {
        backgroundColor,
        backgroundImage: `url(${imageUrl})`,
    };
    const stripeStyle = {
        transform: `translate(-50%, -50%) scale(${scale})`,
        width: `${design.BASE_STRIPE_WIDTH}px`,
        height: `${design.BASE_STRIPE_HEIGHT}px`,
    };

    const bannerHeight = bannerHeights[currentStripe.id] * scale;
    const bannerTop = (containerHeight - bannerHeight) / 2;
    const bannerStyle = {
        height: `${bannerHeight}px`,
        top: `${bannerTop}px`,
    };

    const stripeColor = getTextColor(currentStripe);
    return (
        <div
            style={backgroundStyle}
            className={classNames(styles.background, {
                [styles.backgroundFixed]: preview && currentStripe.backgroundBehaviour === design.BACKGROUND_FIXED,
                [styles.backgroundImageContained]: currentStripe.imageBehaviour === design.IMAGE_CONTAIN,
            })}
        >
            {colorFilter && backgroundImage && <ColorFilter type={colorFilter} />}
            {currentStripe.design === TRANSITION && (
                <div
                    style={bannerStyle}
                    className={classNames({
                        [styles.banner]: true,
                        bannerLight: stripeColor === design.DARK,
                        bannerDark: !(stripeColor === design.DARK),
                    })}
                />
            )}
            <div style={stripeStyle} className={styles.childContainer}>
                {children}
            </div>
        </div>
    );
}

StripeContainer.defaultProps = {
    preview: false,
};

StripeContainer.propTypes = {
    currentStripe: PropTypes.object.isRequired,
    preview: PropTypes.bool,
    children: PropTypes.node,
    containerWidth: PropTypes.number,
    containerHeight: PropTypes.number,
    bannerHeights: PropTypes.object,
};

const mapStateToProps = (state) => ({
    bannerHeights: state.preview.bannerHeights,
});

const connectedContainer = connect(mapStateToProps)(StripeContainer);

export default Dimensions()(connectedContainer);
