import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { ValidateButton, CancelButton, Textarea } from 'components';
import { updateStory, updateStoryRequest } from 'common/actions';
import { StoryModalTitle, StoryModalInput, StoryModalLabel } from 'components/StoryModal';

import styles from './index.module.scss';

class TitleForm extends Component {
    onSubmit(e) {
        const { updateStoryRequest, onRequestClose, story } = this.props;
        e.preventDefault();
        updateStoryRequest({
            id: story.id,
            published: true,
            title: story.title,
            subtitle: story.subtitle,
            credits: story.credits,
        });
        onRequestClose();
    }

    handleChange(key, value) {
        this.props.updateStory({
            [key]: value,
        });
    }
    render() {
        const {
            story: { title, subtitle, credits },
            t,
        } = this.props;
        return (
            <form onSubmit={(e) => this.onSubmit(e)} className={styles.container}>
                <StoryModalTitle>{t('name.title')}</StoryModalTitle>
                <StoryModalInput
                    label={t('name.label.title')}
                    name="title"
                    handleChange={(value) => this.handleChange('title', value)}
                    value={title}
                />
                <StoryModalInput
                    label={t('name.label.subtitle')}
                    name="subtitle"
                    handleChange={(value) => this.handleChange('subtitle', value)}
                    value={subtitle}
                />
                <StoryModalLabel label={t('name.label.credits')} name="credits" />
                <Textarea
                    handleChange={(value) => this.handleChange('credits', value)}
                    value={credits}
                    borderSolid
                    borderRadius
                    size="xsmall"
                    minRows={3}
                />
                <div className={styles.buttons}>
                    <CancelButton onClick={this.props.onRequestClose}>{t('button.later')}</CancelButton>
                    <ValidateButton type="submit" className={styles.validateButton}>
                        {t('button.save')}
                    </ValidateButton>
                </div>
            </form>
        );
    }
}

TitleForm.propTypes = {
    updateStory: PropTypes.func,
    updateStoryRequest: PropTypes.func,
    story: PropTypes.object,
    onRequestClose: PropTypes.func,
    t: PropTypes.func,
};

const mapStateToProps = (state) => ({
    story: state.story,
});

const mapDispatchToProps = (dispatch) => ({
    updateStory: (story) => {
        dispatch(updateStory(story));
    },
    updateStoryRequest: (data, redirectUrl) => {
        dispatch(updateStoryRequest(data, redirectUrl));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TitleForm));
