import types from './actionTypes';

const initialState = {
    credentials: {},
    error: false,
    success: false,
};

export default function update(state = initialState, action) {
    switch (action.type) {
        case types.SET_BOOK_ACCESS_CREDENTIALS:
            return { ...initialState, credentials: { ...initialState.credentials, ...action.payload } };
        case types.UPDATE_BOOK_ACCESS_CREDENTIALS:
            return { ...initialState, credentials: { ...state.credentials, ...action.payload } };
        default:
            return state;
    }
}

export function accessSelector(state) {
    return state.bookAccess.credentials;
}
