import storyItems from 'config/storyItems';

// @todo: write unit tests
function stripesHaveCorrectOrder(stripes) {
    if (!stripes || stripes.lenght === 0) return true;
    let currentStoryStepIndex = 0;
    let isOrderCorrect = true;
    stripes.forEach((stripe, stripeIndex) => {
        if (!isOrderCorrect) return;
        if (stripe.type === storyItems[currentStoryStepIndex]) return;
        if (
            stripe.type === storyItems[currentStoryStepIndex + 1] &&
            stripes[stripeIndex - 1] &&
            stripes[stripeIndex - 1].type === storyItems[currentStoryStepIndex]
        ) {
            currentStoryStepIndex += 1;
            return;
        }
        isOrderCorrect = false;
    });
    return isOrderCorrect;
}

export function isInStoryTunnel(story) {
    return stripesHaveCorrectOrder(story.stripes) && !story.hasDesignAccess;
}
