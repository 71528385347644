import platform from 'platform';
export { isMobile } from 'react-device-detect';

export const CHROME = 'Chrome';
export const SAFARI = 'Safari';
export const FIREFOX = 'Firefox';
export const IE = 'IE';

export const isBrowserCompatible = (function () {
    const { name, version } = platform;
    const [majorVersion] = version.split('.');
    if (!(name === CHROME || name === SAFARI || name === FIREFOX)) return false;
    switch (name) {
        case CHROME:
            return majorVersion >= 51;
        case SAFARI:
            return majorVersion >= 11;
        case FIREFOX:
            return majorVersion >= 51;
        default:
            return false;
    }
})();

export const platformName = platform.name;
