import React from 'react';
import PropTypes from 'prop-types';
import style from './index.module.scss';

const PageContainer = ({ children }) => <div className={style.container}>{children}</div>;

PageContainer.propTypes = {
    children: PropTypes.node,
};

export default PageContainer;
