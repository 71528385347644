import React from 'react';
import PropTypes from 'prop-types';
import { default as IconBase } from 'react-icon-base';
import icons from './icons';

const Icon = (props) => {
    const icon = icons[props.iconName];
    return (
        <IconBase {...icon.props} className={props.className}>
            {icon.svg}
        </IconBase>
    );
};

Icon.propTypes = {
    iconName: PropTypes.string.isRequired,
    className: PropTypes.string,
};

export default Icon;
