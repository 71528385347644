import types from './actionTypes';

const initialState = {
    isLoading: false,
    exportNumber: 0,
    isModalOpen: false,
};

export default function update(state = initialState, action) {
    switch (action.type) {
        case types.UDPATE_EXPORT:
            return { ...state, ...action.payload };
        case types.INCREMENT_EXPORT_NUMBER:
            return { ...state, exportNumber: state.exportNumber + 1 };
        default:
            return state;
    }
}

export function isExportLoading(state) {
    return state.export.isModalOpen && state.export.isLoading;
}
