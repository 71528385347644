import React, { lazy, Suspense } from 'react';
import { Loader } from 'components';
import styles from './index.module.scss';

const BookOnAmazon = lazy(() => import('./BookOnAmazon'));

const LazyLoadedBookOnAmazon = (props) => (
    <Suspense fallback={<Loader className={`${styles.container} ${props.className}`} />}>
        <BookOnAmazon {...props} />
    </Suspense>
);

export default LazyLoadedBookOnAmazon;
