import React from 'react';
import PropTypes from 'prop-types';

import { HelpZone, CombinedHelpZone } from 'components';
import TopBar from './components/TopBar';
import styles from './index.module.scss';

const EditorContainer = ({ currentStep, page, type, isStep, children, subTypes }) => (
    <div className={styles.container}>
        <TopBar currentStep={currentStep} />
        <div className={styles.content}>
            <div className={styles.editor}>{children}</div>
            {subTypes.length === 0 && <HelpZone page={page} type={type} className={styles.helpzone} isStep={isStep} />}
            {subTypes.length !== 0 && <CombinedHelpZone page={page} types={subTypes} className={styles.helpzone} />}
        </div>
    </div>
);

EditorContainer.propTypes = {
    children: PropTypes.node,
    page: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    subTypes: PropTypes.array,
    currentStep: PropTypes.string.isRequired,
    isStep: PropTypes.bool,
};

EditorContainer.defaultProps = {
    isStep: true,
    subTypes: [],
};

export default EditorContainer;
