import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { reducer as toastrReducer } from 'react-redux-toastr';
import { reducer as formReducer } from 'redux-form';
import currentStripeReducers from 'common/currentStripe/reducers';
import storyReducers from 'common/story/reducers';
import remoteStoryReducers from 'common/remoteStory/reducers';
import storiesReducers from 'common/stories/reducers';
import designReducers from 'common/design/reducers';
import userReducers from 'common/user/reducers';
import exportReducers from 'common/export/reducers';
import previewReducers from 'common/preview/reducers';
import translationsReducer from 'common/translations/reducers';
import bookAccessReducer from 'common/bookAccess/reducers';
import loadingReducer from 'common/loading/reducers';

const translationPersistConfig = {
    key: 'translations',
    storage,
    debug: true,
};

const reducers = (history) =>
    combineReducers({
        router: connectRouter(history),
        form: formReducer,
        currentStripe: currentStripeReducers,
        story: storyReducers,
        remoteStory: remoteStoryReducers,
        stories: storiesReducers,
        design: designReducers,
        user: userReducers,
        export: exportReducers,
        preview: previewReducers,
        toastr: toastrReducer,
        translations: persistReducer(translationPersistConfig, translationsReducer),
        bookAccess: bookAccessReducer,
        loading: loadingReducer,
    });

export default reducers;
