import actionTypes from './actionTypes';

export const setDesignPopinType = (popinType) => ({
    type: actionTypes.SET_DESIGN_POPIN_TYPE,
    popinType,
});

export const openPublishModal = () => ({
    type: actionTypes.OPEN_PUBLISH_MODAL,
});

export const closePublishModal = () => ({
    type: actionTypes.CLOSE_PUBLISH_MODAL,
});

export const openTitleModal = () => ({
    type: actionTypes.OPEN_TITLE_MODAL,
});

export const closeTitleModal = () => ({
    type: actionTypes.CLOSE_TITLE_MODAL,
});

export const setCustomizationModalBlocked = (payload) => ({
    type: actionTypes.SET_CUSTOMIZATION_MODAL_BLOCKED,
    payload,
});

export const designNextStepRequest = () => ({
    type: actionTypes.DESIGN_NEXT_STEP_REQUEST,
});

export const designPreviousStepRequest = () => ({
    type: actionTypes.DESIGN_PREVIOUS_STEP_REQUEST,
});

export const openDesignAccessModal = () => ({
    type: actionTypes.OPEN_DESIGN_ACCESS_MODAL,
});

export const closeDesignAccessModal = () => ({
    type: actionTypes.CLOSE_DESIGN_ACCESS_MODAL,
});

export const grantDesignAccessRequest = () => ({
    type: actionTypes.GRANT_DESIGN_ACCESS_REQUEST,
});
