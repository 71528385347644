import request from 'superagent';
import config from '../config';

export function getBookAccess(tokenKey) {
    return request
        .get(`${config.baseUrl}/bookaccesses`)
        .retry(2)
        .set('Authorization', tokenKey)
        .then((response) => response.body);
}

export function updateBookAccess(bookAccess, tokenKey) {
    return request
        .put(`${config.baseUrl}/bookaccesses`)
        .set('Authorization', tokenKey)
        .send(bookAccess)
        .then((response) => response.body);
}
