import actionTypes from './actionTypes';

export const setBookAccessCredentials = (payload) => ({
    type: actionTypes.SET_BOOK_ACCESS_CREDENTIALS,
    payload,
});

export const updateBookAccessCredentials = (payload) => ({
    type: actionTypes.UPDATE_BOOK_ACCESS_CREDENTIALS,
    payload,
});

export const getBookAccessRequest = (payload) => ({
    type: actionTypes.GET_BOOK_ACCESS_REQUEST,
    payload,
});

export const updateBookAccessRequest = (payload) => ({
    type: actionTypes.UPDATE_BOOK_ACCESS_REQUEST,
    payload,
});
