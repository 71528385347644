import React from 'react';
import { PLAIN, KEY_DATA, TRANSITION, QUOTATION } from 'config/stripeTypes';
import PlainStripe from './PlainStripe';
import KeyDataStripe from './KeyDataStripe';
import QuotationStripe from './QuotationStripe';
import TransitionStripe from './TransitionStripe';
import SidebarStripeContainer from './SidebarStripeContainer';
import StripeContainer from './StripeContainer';
import EditorStripeContainer from './EditorStripeContainer';

export default class StripeFactory {
    static getStripe(stripe, { editable, customizable, preview, dimensionAware }) {
        switch (stripe.design) {
            case PLAIN:
                return (
                    <PlainStripe
                        stripe={stripe}
                        editable={editable}
                        customizable={customizable}
                        preview={preview}
                        dimensionAware={dimensionAware}
                    />
                );
            case KEY_DATA:
                return (
                    <KeyDataStripe
                        stripe={stripe}
                        editable={editable}
                        customizable={customizable}
                        dimensionAware={dimensionAware}
                    />
                );
            case TRANSITION:
                return (
                    <TransitionStripe
                        stripe={stripe}
                        editable={editable}
                        preview={preview}
                        customizable={customizable}
                        dimensionAware={dimensionAware}
                    />
                );
            case QUOTATION:
                return (
                    <QuotationStripe
                        stripe={stripe}
                        editable={editable}
                        customizable={customizable}
                        dimensionAware={dimensionAware}
                    />
                );
            default:
                return undefined;
        }
    }
    static sidebar(stripe, title) {
        return (
            <SidebarStripeContainer currentStripe={stripe} title={title}>
                {this.getStripe(stripe, { dimensionAware: true })}
            </SidebarStripeContainer>
        );
    }
    static editor(stripe, customizable) {
        return (
            <EditorStripeContainer customizable={customizable}>
                <StripeContainer currentStripe={stripe}>
                    {this.getStripe(stripe, { editable: true, customizable, preview: true })}
                </StripeContainer>
            </EditorStripeContainer>
        );
    }
    static preview(stripe) {
        return (
            <StripeContainer currentStripe={stripe} preview>
                {this.getStripe(stripe, { preview: true })}
            </StripeContainer>
        );
    }
}
