const actionTypes = {
    SET_DESIGN_POPIN_TYPE: 'DESIGN/SET_POPIN_TYPE',
    OPEN_PUBLISH_MODAL: 'DESIGN/OPEN_PUBLISH_MODAL',
    CLOSE_PUBLISH_MODAL: 'DESIGN/CLOSE_PUBLISH_MODAL',
    OPEN_TITLE_MODAL: 'DESIGN/OPEN_TITLE_MODAL',
    CLOSE_TITLE_MODAL: 'DESIGN/CLOSE_TITLE_MODAL',
    OPEN_DESIGN_ACCESS_MODAL: 'DESIGN/OPEN_ACCESS_MODAL',
    CLOSE_DESIGN_ACCESS_MODAL: 'DESIGN/CLOSE_ACCESS_MODAL',
    SET_CUSTOMIZATION_MODAL_BLOCKED: 'DESIGN/SET_CUSTOMIZATION_MODAL_BLOCKED',

    DESIGN_NEXT_STEP_REQUEST: 'DESIGN/NEXT_STEP_REQUEST',
    DESIGN_PREVIOUS_STEP_REQUEST: 'DESIGN/PREVIOUS_STEP_REQUEST',
    GRANT_DESIGN_ACCESS_REQUEST: 'DESIGN/GRANT_DESIGN_ACCESS_REQUEST',
};

export default actionTypes;
