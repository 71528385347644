import React, { lazy, Suspense } from 'react';
import { Loader } from 'components';
import styles from './index.module.scss';

const CustomizationBar = lazy(() => import('./CustomizationBar'));

const LazyLoadedCustomizationBar = (props) => (
    <Suspense
        fallback={
            <div className={styles.container}>
                <Loader />
            </div>
        }
    >
        <CustomizationBar {...props} />
    </Suspense>
);

export default LazyLoadedCustomizationBar;
