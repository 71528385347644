const CONFIG = {
    THUMBNAIL: {
        width: 200 * window.devicePixelRatio,
        optimisation: ['f_auto', 'q_auto:low'],
    },
    EDITOR: {
        width: (window.innerWidth - 470) * window.devicePixelRatio, // ~220 (sidebar) + ~255 (helpzone)
        optimisation: ['f_auto', 'q_auto:low'],
    },
    PREVIEW: {
        width: window.innerWidth * window.devicePixelRatio,
        optimisation: ['f_auto', 'q_auto'],
    },
    CROP: {
        optimisation: ['f_auto', 'q_20'],
    },
};

const IMAGE_SIZES = [
    200,
    400,
    640, // Apple iPhone SE
    768, // Apple iPad Mini
    828, // Apple iPhone XR
    1024,
    1080, // Apple iPhone 6/7/8 Plus
    1242, // Apple iPhone XS Max
    1366, // Acer Chromebook
    1440, // Apple MacBook Air 13-inch
    1600,
    1920, // Dell XPS 13
    2048, // Apple iPad Air
    2560, // Apple MacBook Pro 13-inch
    2736, // Apple iPad Pro
    2880, // Microsoft Surface Book
    3000,
    3840,
    5120, // Apple iMac 27-inch (Retina 5K Display)
];

export function isCropped(cropValues) {
    if (!cropValues) return false;
    const { x, y, width, height } = cropValues;
    return !(!x && !y && !width && !height);
}

function findImageSize(width) {
    return IMAGE_SIZES.find((imageSize) => width <= imageSize) || IMAGE_SIZES[IMAGE_SIZES.length - 1];
}

function getCropParams(cropValues) {
    const { x, y, width, height } = cropValues;
    // by adding a "/" add the end we can chain futher operations
    return `x_${x},y_${y},w_${width},h_${height},c_crop/`;
}

function getDownloadParams(cropValues, type) {
    const { optimisation, width } = CONFIG[type];
    let params = '';
    // crop params
    if (isCropped(cropValues)) {
        params += getCropParams(cropValues);
    }
    // optimisation params
    params += optimisation.join(',');
    // size param
    if (width) {
        params += `,w_${findImageSize(width)}`;
    }
    return params;
}

export function getFileUrl(url, cropValues, type) {
    if (!url) return '';
    const params = getDownloadParams(cropValues, type);
    const splitValues = url.split('/');
    const fileName = splitValues[splitValues.length - 1];
    const version = splitValues[splitValues.length - 2];
    const baseUrl = url.replace(`/${version}/${fileName}`, '');
    return params ? `${baseUrl}/${params}/${fileName}` : url;
}
