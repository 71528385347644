import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import i18n from 'i18n';

import styles from './index.module.scss';

const HelpZone = ({ className, page, type, isStep, t }) => (
    <div className={`${styles.container} ${className}`}>
        {isStep && (
            <div className={styles.header}>
                <div className={styles.step}>{t(`step.${page}`)}</div>
                <div className={styles.titleContainer}>
                    <div className={styles.title}>{t(`${page}.items.${type}.title`)}</div>
                    <div className={styles.position}>{t(`${page}.items.${type}.position`)}</div>
                </div>
            </div>
        )}
        {!isStep && (
            <div className={`${styles.header} ${styles.headerCentered}`}>
                <div className={styles.title}>{t(`${page}.items.${type}.title`)}</div>
            </div>
        )}
        <div className={styles.contentContainer}>
            <div className={styles.subTitle}>{t('story.instructions')}</div>
            <div className={styles.description}>{t(`${page}.items.${type}.instructions`)}</div>
            <div className={styles.subTitle}>{t('story.tip')}</div>
            <div className={styles.description}>{t(`${page}.items.${type}.tip`)}</div>
            {i18n.exists(`${page}.items.${type}.example`) && (
                <div className={styles.subTitle}>{t('story.example')}</div>
            )}
            {i18n.exists(`${page}.items.${type}.example`) && (
                <div className={styles.description}>{t(`${page}.items.${type}.example`)}</div>
            )}
        </div>
    </div>
);

HelpZone.propTypes = {
    page: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    className: PropTypes.string,
    isStep: PropTypes.bool,
    t: PropTypes.func,
};

HelpZone.defaultProps = {
    isStep: true,
};

export default withTranslation()(HelpZone);
