import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateStripe } from 'common/actions';
import classNames from 'classnames';
import KeyData from './components/KeyData';
import { DisplayedTitle, EditableTitle } from '../components';

import styles from './index.module.scss';

class KeyDataStripe extends Component {
    updateData(x, y, type, value, dimensions) {
        const { data } = this.props.stripe;
        const newData = { ...data[x][y], ...dimensions, [type]: value };
        data[x][y] = newData;
        this.props.updateStripe({
            id: this.props.stripe.id,
            data,
        });
    }

    renderKeyDataRow(line, x) {
        return line.map((item, y) => {
            return (
                <KeyData
                    columns={this.props.stripe.data[0].length}
                    lines={this.props.stripe.data.length}
                    editable={this.props.editable}
                    value={this.props.stripe.data[x][y]}
                    handleChange={(type, value, dimensions) => this.updateData(x, y, type, value, dimensions)}
                    key={y}
                    dimensionAware={this.props.dimensionAware}
                    stripe={this.props.stripe}
                    x={x}
                    y={y}
                />
            );
        });
    }

    render() {
        const { stripe, editable, customizable, dimensionAware } = this.props;
        return (
            <div
                className={classNames(styles.container, {
                    [styles.containerSingleColumn]: stripe.data[0].length === 1,
                })}
            >
                <div className={styles.keyDataContainer}>
                    {editable ? (
                        <EditableTitle
                            stripe={stripe}
                            size="large"
                            className={styles.title}
                            customizable={customizable}
                        />
                    ) : (
                        <DisplayedTitle
                            size="large"
                            className={styles.title}
                            title={stripe.title}
                            dimensionAware={dimensionAware}
                            stripe={stripe}
                        />
                    )}
                    {stripe.data.map((line, i) => {
                        return (
                            <div key={i} className={styles.keyDataRow}>
                                {this.renderKeyDataRow(line, i)}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

KeyDataStripe.propTypes = {
    editable: PropTypes.bool,
    stripe: PropTypes.object.isRequired,
    updateStripe: PropTypes.func.isRequired,
    customizable: PropTypes.bool,
    dimensionAware: PropTypes.bool,
};

KeyDataStripe.defaultProps = {
    dimensionAware: false,
};

const mapDispatchToProps = (dispatch) => ({
    updateStripe: (stripe) => {
        dispatch(updateStripe(stripe));
    },
});

export default connect(null, mapDispatchToProps)(KeyDataStripe);
