import React from 'react';
import PropTypes from 'prop-types';

import { CustomizationBar } from 'components';
import styles from './index.module.scss';

const EditorStripeContainer = (props) => (
    <div className={styles.container}>
        {props.customizable && (
            <div className={styles.customizationBar}>
                <CustomizationBar />
            </div>
        )}
        <div className={styles.stripeContainer}>{props.children}</div>
    </div>
);

EditorStripeContainer.propTypes = {
    children: PropTypes.node,
    customizable: PropTypes.bool,
};

export default EditorStripeContainer;
