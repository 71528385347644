import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import { ValidateButton } from 'components';
import Input from 'components/ReduxForm/Input';
import { required, email } from 'services/validator';

import styles from './index.module.scss';

const validate = (values) => ({
    ...required(values, 'email'),
    ...email(values, 'email'),
});

const LostPasswordForm = ({ handleSubmit, submitting, requestError, t }) => {
    return (
        <form onSubmit={handleSubmit}>
            <Field
                component={Input}
                placeholder={t('lost_password.placeholder')}
                type="email"
                className="loginInput"
                name="email"
            />
            <div className="errorMessage">{requestError}</div>
            <div className={styles.button}>
                <ValidateButton type="submit" disabled={submitting}>
                    {t('lost_password.button')}
                </ValidateButton>
            </div>
        </form>
    );
};

LostPasswordForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    valid: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    requestError: PropTypes.string,
    t: PropTypes.func,
};

LostPasswordForm.defaultProps = {
    requestError: '',
};

export default reduxForm({ form: 'lost-password', validate })(withTranslation()(LostPasswordForm));
