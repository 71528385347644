import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PageContainer, Sidebar, SaveErrorModal } from 'components';
import style from './index.module.scss';

const AppPageContainer = ({ children, saveErrorOccured }) => {
    return (
        <div className={style.container}>
            <SaveErrorModal isOpen={saveErrorOccured} />
            <Sidebar />
            <PageContainer>{children}</PageContainer>
        </div>
    );
};

AppPageContainer.propTypes = {
    children: PropTypes.node,
    saveErrorOccured: PropTypes.bool,
};

AppPageContainer.defaultProps = {
    saveErrorOccured: false,
};

const mapStateToProps = (state) => ({
    saveErrorOccured: state.story.saveErrorOccured,
});

export default connect(mapStateToProps)(AppPageContainer);
