import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import auth from 'common/auth';
import { sendUser } from 'common/user/api';
import { setUser } from 'common/user/actions';
import { logoutRequest } from 'common/actions';
import { shouldAcceptProlongation, getCurrentLicense } from 'services/licenseService';
import { CancelButton, StoryModal, ValidateButton, Icon, Link } from 'components';
import styles from './index.module.scss';
import { capture } from 'services/errorMonitoring';

class LicenseProlongationModal extends Component {
    constructor(props) {
        super(props);
        this.state = { isOpen: false, errorMessage: '' };
    }

    static getDerivedStateFromProps(props) {
        const { currentUser } = props;
        return { isOpen: shouldAcceptProlongation(currentUser) };
    }

    sendResponse = (offerAccepted) => {
        const { t } = this.props;
        if (!offerAccepted) {
            this.setState({
                isOpen: false,
            });
            this.props.logoutRequest();
        } else {
            const { currentUser, setUserAction } = this.props;
            const currentLicense = getCurrentLicense(currentUser);
            const updatedUser = {
                id: currentUser.id,
                licenses: [{ ...currentLicense, prolongationStartDate: new Date() }],
            };
            sendUser(updatedUser, auth.getToken())
                .then((user) => {
                    setUserAction(user);
                    this.setState({
                        isOpen: false,
                        errorMessage: '',
                    });
                })
                .catch((error) => {
                    capture(error, "Couldn't make prologation update");
                    this.setState({
                        errorMessage: t('license.modal.error'),
                    });
                });
        }
    };

    render() {
        const { isOpen, errorMessage } = this.state;
        const { currentUser, locale, t } = this.props;
        const { prolongationDuration } = getCurrentLicense(currentUser);
        return (
            <StoryModal className={styles.modal} isOpen={isOpen} onRequestClose={() => {}}>
                <div className={styles.topContainer}>
                    <Icon className={styles.icon} iconName="expired" />
                </div>
                <div className={styles.content}>
                    <div className={styles.title}>{t('license.modal.title')}</div>
                    <div className={styles.subTitle}>
                        {t('license.modal.subTitle', {
                            duration: moment.duration(prolongationDuration).locale(locale).humanize(),
                        })}
                    </div>
                </div>
                <div className={styles.buttons}>
                    <ValidateButton className={styles.licenseButtons} onClick={() => this.sendResponse(true)}>
                        {t('license.modal.button.accept')}
                    </ValidateButton>
                    <CancelButton className={styles.licenseButtons} onClick={() => this.sendResponse(false)}>
                        {t('license.modal.button.decline')}
                    </CancelButton>
                </div>
                <div className={styles.footer}>
                    <Link
                        external
                        message={t('license.modal.footer.contactUs')}
                        to="https://storymakers.eu/#contact-us"
                        target="blank"
                        link={t('license.modal.footer.contactUsLink')}
                    />
                    <span>{t('license.modal.footer.exportPPTX')}</span>
                </div>
                <div className={`errorMessage ${styles.licenseErrorMessagge}`}>{errorMessage}</div>
            </StoryModal>
        );
    }
}

LicenseProlongationModal.propTypes = {
    currentUser: PropTypes.object,
    setUserAction: PropTypes.func,
    logoutRequest: PropTypes.func,
    locale: PropTypes.string,
    t: PropTypes.func,
};

const mapStateToProps = (state) => ({
    currentUser: state.user.user,
    locale: state.translations.locale,
});

const mapDispatchToProps = (dispatch) => ({
    setUserAction: (stripe) => {
        dispatch(setUser(stripe));
    },
    logoutRequest: () => {
        dispatch(logoutRequest());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LicenseProlongationModal));
