import Stripe from './Stripe';
import KeyData from './KeyData';

export default class KeyDataStripe extends Stripe {
    constructor(payload = {}) {
        super(payload);
        this.data = this.getDataArray(payload.lines, payload.columns, payload.data);
    }

    getDataArray(lines, columns, currentData) {
        let data = new Array(lines);
        for (let i = 0; i < lines; i++) {
            data[i] = new Array(columns);
            for (let j = 0; j < columns; j++) {
                if (currentData && currentData[i] && currentData[i][j]) {
                    data[i][j] = currentData[i][j];
                } else {
                    data[i][j] = new KeyData();
                }
            }
        }
        return data;
    }
}
