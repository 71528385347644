import actionTypes from './actionTypes';

export const setStories = (payload) => ({
    type: actionTypes.SET_STORIES,
    payload,
});

export const addStory = (payload) => ({
    type: actionTypes.ADD_STORY,
    payload,
});

export const getStoriesRequest = () => ({
    type: actionTypes.GET_STORIES_REQUEST,
});
