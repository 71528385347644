const trans = {
    congratulations: 'Congratulations!',
    column: 'column',
    line: 'line',
    configure_your_stripe: 'Configure your slide',
    public: 'Public',
    private: 'Private',
    french: 'French',
    english: 'English',
    update: {
        success: 'Successfully updated',
        failure: 'Updating failed',
    },
    button: {
        my_pitches: 'All my pitches',
        add_story: 'Start a new pitch',
        export: 'Export in PPTX',
        next: 'Next step',
        back: 'Back',
        preview: 'Preview',
        add_stripe: 'Add a new slide',
        customize_stripe: 'Customise this slide',
        browse: 'Browse',
        ok: 'ok',
        cancel: 'cancel',
        delete: 'delete',
        remove_background_image: 'delete image',
        save_crop: 'Confirm crop',
        share: 'Share',
        name: 'Name',
        copy: 'Copy',
        save: 'Save',
        later: 'Later',
        start: 'Start',
        reset: 'Reset',
        reload: 'Reload',
    },
    app: {
        error: 'Oops, there has been an error, please reload the page',
        offline: 'Caution: your connection seems unstable',
    },
    browerNotSupported: {
        title: 'Sorry, your browser is not compatible with the Storymakers App.',
        subTitle:
            'We recommend you build your pitches on desktop computers, with updated versions of Chrome or Firefox.',
    },
    mobileWarning: {
        title: 'Sorry, the Storymakers App is not designed for mobiles or tablets.',
        subTitle:
            'We recommend you build your pitches on desktop computers, with updated versions of Chrome or Firefox.',
    },
    saveError: {
        title: 'An error has occured.',
        subTitle: 'Please reload the page.',
    },
    homepage: {
        welcome: '{{name}}, \n welcome.',
        your_stories: 'my pitches',
        logout: 'Logout',
        story_delete: {
            message: 'Do you really want to delete this pitch?',
            success: 'Your pitch has been deleted',
            error: "Your pitch couldn't be deleted",
        },
    },
    login: {
        title: 'Log into your account',
        email: 'E-mail address',
        password: 'Password',
        error:
            'Oops, looks like you got the wrong account or password there. Having trouble recovering your username? We can help at hello@storymakers.eu',
        terms_and_conditions_accept: 'I accept the',
        terms_and_conditions: 'Terms and Conditions',
        book_image_title: "You don't have the book yet?",
        book_image_sub_title: 'Discover all you need to know to make a pitch people remember.',
        button: {
            amazon: 'I need the book',
            login: 'Login',
        },
        lost_password: 'Forgot your password?',
        new_password: {
            title: 'All set! Now ready to log in to your account',
            subtitle: 'You can now use your new password',
        },
        register: {
            link: 'Register here',
            message: "Don't have an account yet?",
        },
    },
    start_with_book: {
        title: 'Test the app with my book',
        subtitle:
            'With your book you get a free 7-day premium trial of the app to create your pitches. No payment details asked!',
        placeholder: {
            first_name: 'First name',
            last_name: 'Last name',
            email: 'E-mail',
            password: 'Choose password',
            password_confirmation: 'Confirm password',
            word_from_book: 'In your book, what is the last word on page {{pageNumber}}?',
        },
        button: 'Start with my book',
        error: {
            default: 'Oops, un error occured',
            key_word: "Oops, seems you don't have the right word there...",
            email:
                'An account already exists for this email address. If this is yours, you can log in with your password',
        },
        login: {
            link: 'Login here',
            message: 'Already have an account?',
        },
    },
    lost_password: {
        title: 'Reset your Password',
        subtitle:
            'No worries! We’ll email you instructions to reset your password. If you don’t have access to your email anymore, drop us a message at hello@storymakers.eu',
        button: 'Reset password',
        placeholder: 'Your email',
        error: {
            default: 'Oops, un error occured',
            email:
                'Sorry, we couldn’t find an account with that email. Having trouble recovering your username? We can help at hello@storymakers.eu',
        },
        confirmation: {
            title: 'Done!',
            subtitle:
                'We’ve sent an email to {{email}} with password reset instructions.\n\nIf the email doesn’t show up soon, please check your spam folder.',
        },
    },
    reset_password: {
        title: 'Choose a new password',
        button: 'Change your password',
        placeholder: {
            password: 'New password',
            password_confirmation: 'Confirm password',
        },
        error: {
            default: 'This link is no longer active. Please request a new one.',
            link: 'Click here',
        },
    },
    license: {
        modal: {
            title: 'Your subscription has expired…',
            subTitle:
                'But it’s great to see you back – and you might want to access your pitches – so we’re happy to extend your licence for {{duration}}.',
            expirationSubTitle:
                'We’re so sad that you’re no longer with us. If you want to create new pitches with the app, contact us to renew your subscription.',
            footer: {
                contactUs: 'To get a new subscription: ',
                contactUsLink: 'contact-us',
                exportPPTX: 'If you’re leaving us, don’t’ forget to export your pitches in PPTX.',
            },
            button: {
                accept: 'Yes, perfect!',
                interested: 'Yes, I’m interested!',
                decline: 'No thanks',
            },
            error: 'Oops, there has been an error, please try again',
        },
    },
    step: {
        facts: 'FACTS',
        story: 'STORY',
        design: 'DESIGN',
    },
    intro: {
        title_line1: 'Use the 3-step',
        title_line2: 'FAST DESIGN Canvas™',
    },
    pitch: {
        fill_notes: {
            title: 'Now, you can \n sum up your project by \n filling in the blanks.',
        },
        label: {
            subject: 'I am creating this pitch to present',
            innovation: 'The key innovation / remarkable idea I want to share is',
            hero: 'The innovator(s) of my pitch is (are)',
            target: 'The message is addressed to',
            action: 'And at the end of my pitch I would like my audience to',
        },
    },
    facts: {
        items: {
            facts: {
                title: 'Brief',
                position: '1/2',
                instructions:
                    "This is a brainstorming phase. You can fill in these boxes in the order you like. \n\nThe 6 questions will help you focus on the key information you need to include in your pitch. Once you've clarified your ideas here, you'll find it much easier to build a story in the next stage. ",
                tip:
                    "If you're preparing your pitch with several people, we recommend you first answer these questions alone. Ideally, spend 5 to 15 minutes on this page.",
                example: `- Subject: The Firm's new innovation programme
        - Innovation: Spur culture of innovation in The Firm through  design workshops
        - Target: The Firm's 1500 employees
        - Action: register for next training session
        - Content: quotes of CEO and Programme participants, short video of launch, link to registration form for next session of innovation programme
        - Pictures: photos of Programme launch event, close-ups of participants and CEO, pictures of prototypes created by participants`,
            },
            pitch: {
                title: 'Basic Pitch',
                position: '2/2',
                instructions:
                    "Once you've defined your target audience and  purpose of your pitch, see how you can sum it all up in 4 short sentences. \n\n These are the first building blocks of your story, you're getting there!",
                tip:
                    'For those working as a group, this is the moment when you get to compare and adjust your answers to make sure you all agree on the essentials of your pitch.',
                example: `- I am creating this pitch to present The Firm's new innovation programme
        - The key innovation / remarkable idea I want to share is the Firm's new culture of innovation
        - The message is addressed to The Firm's 1500 employees
        - And at the end of my pitch I would like my audience to register for the next training session`,
            },
        },
        fill_notes: {
            title: 'Fill in these notes \n to focus on what matters',
            what: {
                title: 'What?',
                help:
                    'What is the subject you want to pitch about – and what remarkable feature or idea will you share?',
            },
            for_whom: {
                title: 'For Whom?',
                help:
                    'For whom are you making this pitch? Be specific about your audience and the action you want them to take at the end of your pitch.',
            },
            with_what: {
                title: 'With What?',
                help:
                    'With what resources will you build the pitch? List any relevant content and pictures that could be useful.',
            },
        },
        facts_items: {
            subject: {
                title: 'subject',
                help: 'What project, event, product…do you want to make a pitch about? \n Sum it up in 3 to 5 words.',
            },
            innovation: {
                title: 'innovation',
                help: 'What key innovation or remarkable idea is implemented in this project, event, product…?',
            },
            target: {
                title: 'target',
                help: 'To what audience will this pitch be presented? \n Be specific.',
            },
            action: {
                title: 'action',
                help: 'What action do you want your audience to take?',
            },
            hero: {
                title: 'innovator',
                help: 'Who carries the innovation or remarkable idea? It can be a person, team, organisation…',
            },
            content: {
                title: 'content',
                help:
                    'Are there any quotes (from customers, users, partners), documents, videos or web pages that your could add to your pitch? \n Build a list here.',
            },
            pictures: {
                title: 'pictures',
                help:
                    'Identify a broad range of visuals you could use for this pitch (photos, simple charts, illustrations) or get ready to create some. \n Build a list here.',
            },
        },
        type_here: 'Type here',
    },
    story: {
        instructions: 'Instructions',
        tip: 'Tip',
        example: 'Example',
        items: {
            title: {
                placeholder: 'Type your text here',
                title: 'Title',
                position: '',
                instructions: 'Give a title to your Story. Make it short and crisp',
                tip: 'You can come back to all your stripes later, so keep them simple for now.',
                example: "The Firm's Innovation Programme",
            },
            context: {
                placeholder: 'Type your text here',
                title: 'Context',
                position: '1/7',
                instructions:
                    'Write 1-2 lines on the context of the pitch (a situation, trend, circumstance) related to the problem or pain point that you are addressing with your solution (i.e. project, event, product).\nThe start of your pitch must answer at least 2 out of 3 questions: Who? When? Where?',
                tip:
                    'Use one or two slides for this. 1 slide = 1 idea, never more! If you have 2 ideas, use 2 slides, using the "add a new slide" button at the bottom left of the screen.\n You can come back to all your slides later, so keep them simple for now.',
                example: 'Today, The Firm is launching a new Programme to accelerate innovation.',
            },
            challenge: {
                placeholder: 'Type your text here',
                title: 'Problem',
                position: '2/7',
                instructions: 'What is the problem to be solved? This problem must stem directly from the context.',
                tip: 'Ideally, this is a challenge that your audience is also faced with or can feel empathy about.',
                example: 'For we are all faced with a major challenge: driving innovation in a context of disruption.',
            },
            news: {
                placeholder: 'Type your text here',
                title: 'Good news \n& superior mission',
                position: '3/7',
                instructions:
                    'Break the good news: you have a solution! Say briefly that the problem has been addressed (or that it can be) and by who (a person, team, organisation…). State also what drives you (or your team, organisation, partners) to address the problem. This is your superior mission.',
                tip:
                    'Do not go into detail here. The purpose of this slide is to make your audience curious about your solution and your approach. Create a little suspense, make them want to scroll on!',
                example: 'At The Firm we have addressed this challenge. We believe everyone should  drive innovation.',
            },
            proposition: {
                placeholder: 'Type your text here',
                title: 'Value proposition',
                position: '4/7',
                instructions: "Sum up your solution's value proposition in just one sentence.",
                tip:
                    'Your value proposition should be both short and comprehensive. Here is the most common structure: <Name of project> is a tool/method/solution/event for <target users> to <accomplish such a task>.',
                example:
                    'The Innovation Programme is a training course for managers to develop entrepreneurial leadership skills.',
            },
            solution: {
                placeholder: 'Type your text here',
                title: 'Path to the solution',
                position: '5/7',
                instructions: 'Explain how your solution works.',
                tip:
                    'Give an overview of the process or path to the solution on one slide. Then detail briefly the different steps and actions taken on several slides, using the "add a new slide" button at the bottom left of the screen.',
                example: `Slide 1: Mr X - the Firm's CEO - has launched the Innovation Programme with Harvard! A 3-month session for our top managers
  Slide 2: The method? A 5-step process
  Slide 3: #1 Entrepreneurial reasoning
  Slide 4: #2 …`,
            },
            outcome: {
                placeholder: 'Type your text here',
                title: 'Happy outcome',
                position: '6/7',
                instructions:
                    'What is the positive outcome (expected or experienced) for people who might adopt this solution?',
                tip:
                    'You can relate this outcome to another project, event, product…that opens up new possibilities for your audience.',
                example:
                    '50 top managers have already joined our first Programme session. They launched 10 remarkable projects that will now be developed in-house.',
            },
            action: {
                placeholder: 'Type your text here',
                title: 'Call to action',
                position: '7/7',
                instructions:
                    'Create a call to action for your audience.\nWhat role can your audience have in this story? How can people join, support, follow this project, event, company…?',
                tip: 'Phrase the call to action very clearly and simply.',
                example:
                    "Want to join The Firm's next innovators? We are now opening applications for The Programme's session #2! Contact us! ",
            },
        },
    },
    design: {
        access_modal: {
            title: 'Well done! You have reached the end of the Story section - this was the hardest bit!',
            content:
                'If you are ready to design your pitch, move on to the next step.\nYou can return to the Facts and Story sections any time you like.',
        },
        data: 'Data',
        caption: 'Caption',
        background_image: 'Background image',
        background_color: 'Background color',
        stripe_behaviour: 'Slide behaviour',
        image_behaviour: 'Image behaviour',
        color_filter: 'Colour filter',
        scroll: 'Scroll',
        fixed: 'Fixed',
        dark: 'Dark',
        light: 'Light',
        dark_color: 'Black',
        light_color: 'White',
        none: 'None',
        cover: 'In background',
        contain: 'Uncut',
        image_drag_and_drop: 'Chose your picture',
        error: {
            file_size: 'This file is too heavy, pictures must be max. 20 Mo.',
            file_type: 'Please upload pictures only',
        },
        iframe_label: 'Embed a video',
        iframe_sublabel: 'It has to be hosted on Dailymotion, Vimeo or YouTube',
        iframe_placeholder: 'Paste your iframe code here',
        choose_stripe: 'Choose your slide',
        stripe_type: {
            plain: 'Plain',
            key_data: 'Key data',
            transition: 'Transition',
            quotation: 'Quotation',
            video: 'Video',
        },
        link_modal: {
            title: 'Create a link',
            input: 'Enter a URL',
            validate: 'OK',
            placeholder: 'https://',
        },
        quotation: {
            placeholder: {
                title: 'Type your text here',
                quotation: 'Type your quotation here',
                author: "Author's name",
            },
            button: {
                new: 'Add a new\nquotation',
            },
        },
        editor: {
            title: 'Title',
            subtitle: 'Subtitle',
            paragraph: 'Paragraph',
        },
        items: {
            add_pictures: {
                title: 'Pictures',
                position: '1/9',
                example: 'Photos of the Programme location, participants (portrait and group pictures), prototypes',
                instructions:
                    'Insert pictures into your pitch. \nWhat visuals can you add to your existing slides to make them more meaningful?',
                tip: `- You can upload visuals to your slides at all time using the "customise this slide" button at the top right of the screen. Feel free to come back to this later if you don't have all your visuals ready
   - Find great free photos on unsplash.com
   - Combine different types of visuals: close-up and wide-angle photographs, infographics, illustrations, simple background pictures.
    When possible, select photographs of people who have really taken part in the project, event, product…you are presenting.`,
            },
            insert_key_data: {
                title: 'Key data',
                position: '2/9',
                instructions: `Make key facts and key words stand out.
   To increase the visual impact of your message, emphasize concepts, figures, actions.`,
                tip: `- Use at least 2 Key Data slides in your pitch. You can transform an existing slide into a Key Data slide by selecting a slide in the sidebar and clicking on the "Key Data" button in the top menu. You can also add new slides to your pitch.
   - Key data slides usually work very well in the "Context", "The path to the solution" and "Happy outcome" sections.`,
            },
            shape_your_story: {
                title: 'Layout',
                position: '3/9',
                instructions: `Format your content and make space.
   Improve the visual quality of your content. What words can you remove, shorten, reorder and re-format?`,
                tip:
                    'Blanks are as important as content: seek space! Leave out detail so that your audience can imagine a new story in their own context.',
            },
            core_message: {
                title: 'Core message',
                position: '4/9',
                example:
                    "Start your pitch with an engaging statement or question: 'The Firm is ready for change. Are you?' ",
                instructions: `Improve your storyline.
   Scroll through your pitch and check your transitions between the main titles are smooth. They must be read as sentences that link together.`,
                tip: `- Can your audience understand the core message of the pitch in 20 seconds?
   - Use connectors (but, because, so…), indicators of time and space (today, in 2017, next month…), and Transition slides to make a structured and seamless narrative. You can transform an existing slide into a Transition slide by selecting it in the sidebar and clicking on the "Transition" button in the top menu. You can also add new slides to your pitch.
   - Take particular care when rephrasing your very first slide: it must be appealing and intriguing, your audience must want to start scrolling.`,
            },
            secondary_message: {
                title: 'Secondary message',
                position: '5/9',
                example:
                    'Add a few extra facts about the 10 remarkable projects the managers initiated (In what fields of innovation are they? And at what stage of development? What are their titles?…). This can be presented in a « Key data » slide for instance.',
                instructions: `Focus on the facts again.
   Can your audience find all the important information they might need when reading the sub-titles, captions and small print?`,
                tip: `- On your last slide make sure you insert contact details, registration forms or any other relevant information to complete your call to action.
   - Use hyperlinks for quick access to email addresses, online registration forms and other web pages.`,
            },
            videos_and_resources: {
                title: 'Resources',
                position: '6/9',
                example: 'Insert resources linked to The Programme (case studies, further reading, blog pages…).',
                instructions: `Put a cherry on the cake!
   What extra information (web pages or PDF files) could your audience be really excited to find?`,
                tip:
                    "At this stage you must focus more than ever on your audience's interests. Identify online resources or files they could download on a specific topic.",
            },
            lists: {
                title: 'Lists',
                position: '7/9',
                instructions: `Create short lists.
   Audiences are attracted by new and relevant information. Give salience to key information by using bullet points or creating numbered lists.`,
                tip: 'Three items per list are the perfect number!',
            },
            references: {
                title: 'References',
                position: '8/9',
                example: "Select a short quotation of The Firm's CEO sharing his vision of the company's future.",
                instructions: `Add testimonials.
   What positive feedback can you share about your project, event, product…?`,
                tip:
                    'References and positive customer feedback have more weight than facts. Add short quotations of your partners, customers, sponsors… To insert a quotation, click on the "Add a new slide" button at the bottom left of the screen and then on the "Quotation" button in the top menu.',
            },
            engagement: {
                title: 'Engagement',
                position: '9/9',
                instructions: `Give your audience the opportunity to have a role in your story.
   Tell your audience how they can join, subscribe, support your project or idea (as a participant, client, spectator, partner…)`,
                tip:
                    'Make them dream with you! And be engaging: What if we developed together a new…? If you join… / If you subscribe… / we could do … and … together!',
            },
            give_a_title: {
                title: 'Nearly finished! \n Name your pitch',
                instructions:
                    'Give a short title and sub-title to your pitch. They will appear in the footer of your online pitch.',
                tip:
                    "Looking for a good title (and sub-title) can be a good opportunity to find a slogan or baseline for your project. Give yourself a few minutes to think about that short sentence that says it all. A simple title also works well (after all it's just for the footer here).",
            },
            publish: {
                title: 'Your pitch is ready to share!',
                instructions:
                    "Whether this is your first draft or an advanced version of your pitch, the best way to know if it is clear, memorable and convincing is to share it with others. So don't wait and gather all the feedback you can get!",
                tip:
                    'Before you start making more improvements on the first version of your pitch, get a few - nice but tough - people to challenge it. Ask them to point out the weaknesses and suggest improvements.',
            },
        },
    },
    name: {
        title: 'Name your pitch',
        label: {
            title: 'Give your pitch a title (it will appear in the page footer)',
            subtitle: 'Now add a subtitle (it will come just under the title)',
            credits: "Add credits (here's the place for photo and production credits)",
        },
    },
    publish: {
        title: 'Share your pitch',
        description_public:
            "Hurray, your pitch is ready to go out into the world! \n Here's a link to share your pitch with the people you choose.",
        description_private:
            'This pitch is just for you (for the moment!). \n In private mode, the URL link will not be accessible for others.',
        label_public: 'Copy link to share',
        label_private: 'You must select public mode to share your pitch',
        message: {
            copied: 'Copied to clipboard',
        },
    },
    footer: {
        credits: 'Credits',
        propelled_by: 'Proudly propelled by',
    },
    register: {
        title: 'Register a new user',
    },
    save: {
        in_progress: 'Save in progress…',
        saved: 'Changes Saved',
        error_reload: "Your updates haven't been saved. If the issue remains, please reload the page.",
        error: "Connection error, your changes haven't been saved",
    },
    export: {
        downloaded: 'Pitch downloaded',
        error: 'Oops download error, please try again!',
    },
    preview: {
        title: 'CONGRATULATIONS, YOU CAN NOW SHARE YOUR PITCH',
        content:
            'Just copy the URL above and share it with your contacts.\n\n' +
            'If you want an offline version of your pitch, we can export a ZIP file for you.',
        warning: 'FOR WINDOWS USERS:',
        warning_content: " don't forget to unzip (extract) the file!",
        validate_button: 'Yes, please export',
        cancel_button: 'No thanks',
        download: {
            title: "We've zipped everything, your pitch is ready to be downloaded.",
            validate_button: 'Download your pitch!',
            cancel_button: 'Cancel',
        },
    },
    book_acces: {
        title: 'Access via the book',
        page_number: 'Page number',
        english_word: 'English word',
        french_word: 'French word',
    },
    form: {
        error: {
            required_field: 'Required field',
            min_character: 'Please enter at least {{charNumber}} characters',
            identical_passwords: 'Please enter 2 identical passwords',
            invalid_email: 'Invalid email address',
        },
    },
};

export default trans;
