import React from 'react';

const icons = {
    add: {
        svg: (
            <g>
                <path
                    d="M12,0C5.373,0,0,5.373,0,12c0,6.627,5.373,12,12,12c6.627,0,12-5.373,12-12C24,
          5.373,18.627,0,12,0z M18,13h-5v5h-2v-5H6v-2 h5V6h2v5h5V13z"
                />
            </g>
        ),
        props: {
            viewBox: '0 0 24 24',
        },
    },
    minus: {
        svg: (
            <g>
                <path
                    d="M9,0C4.029,0,0,4.029,0,9c0,4.971,4.029,9,9,9s9-4.029,9-9C18,
          4.029,13.971,0,9,0z M8,10H5V8h3h2h3v2h-3H8z"
                />
            </g>
        ),
        props: {
            viewBox: '0 0 18 18',
        },
    },
    minimize: {
        svg: (
            <g>
                <path
                    d="M16,5v13H2V5H16 M17,3H1C0.448,3,0,3.448,0,4v15c0,0.553,0.448,1,1,1h16c
          0.553,0,1-0.447,1-1V4C18,3.448,17.553,3,17,3L17,3z"
                />
                <rect x="4" y="14" width="10" height="2" />
            </g>
        ),
        props: {
            viewBox: '0 0 20 20',
        },
    },
    maximize: {
        svg: (
            <g>
                <path
                    d="M16,5v13H2V5H16 M17,3H1C0.448,3,0,3.448,0,4v15c0,0.553,0.448,1,1,1h16c0.553,
          0,1-0.447,1-1V4C18,3.448,17.553,3,17,3L17,3z"
                />
                <rect x="4" y="7" width="10" height="2" />
            </g>
        ),
        props: {
            viewBox: '0 0 20 20',
        },
    },
    check: {
        svg: (
            <g>
                <polygon points="5,10.431 0.277,5.708 1.691,4.294 5,7.603 11.848,0.755 13.262,2.169 " />
            </g>
        ),
        props: {
            viewBox: '0 0 14 11.001',
        },
    },
    arrow: {
        svg: (
            <g>
                <polygon points="1.342,15.356 0.635,14.649 7.284,8 0.641,1.356 1.348,0.649 8.698,8 " />
            </g>
        ),
        props: {
            viewBox: '0 0 9 16',
        },
    },
    quotation_mark: {
        svg: (
            <g>
                <path
                    d="M0,25.342l0.586-13.379L3.125,0h10.059l-2.588,11.963L5.469,25.342H0z M16.162,
          25.342l0.586-13.379L19.287,0h10.059l-2.588,11.963l-5.127,13.379H16.162z"
                />
            </g>
        ),
        props: {
            viewBox: '0 0 29.346 25.342',
        },
    },
    config: {
        svg: (
            <g>
                <path
                    d="M17.926,8.964c-0.423-0.108-0.843-0.228-1.257-0.366c-0.098-0.033-0.196-0.147-0.24-0.248
          c-0.249-0.572-0.488-1.149-0.715-1.729c-0.041-0.103-0.051-0.252-0.005-0.346c0.195-0.389,0.405-0.771,0.628-1.146
          c-0.424-0.55-0.917-1.042-1.467-1.466c-0.37,0.217-0.746,0.428-1.131,0.619c-0.1,0.049-0.26,0.041-0.369-0.001
          c-0.582-0.225-1.158-0.463-1.73-0.712c-0.101-0.044-0.208-0.149-0.241-0.249c-0.136-0.411-0.253-0.827-0.363-1.246
          C10.696,2.03,10.352,2,10,2C9.648,2,9.305,2.03,8.966,2.074C8.855,2.494,8.738,2.912,8.602,3.323
          c-0.033,0.1-0.143,0.205-0.243,0.248c-0.58,0.252-1.166,0.492-1.756,0.723c-0.094,0.037-0.233,0.043-0.319,0
          C5.893,4.096,5.509,3.883,5.13,3.663C4.58,4.086,4.088,4.579,3.665,5.128c0.216,0.369,0.411,0.75,0.626,1.12
          c0.085,0.146,0.06,0.254-0.007,0.404c-0.25,0.562-0.467,1.137-0.712,1.701C3.528,8.454,3.428,8.566,3.329,8.599
          C2.917,8.737,2.497,8.857,2.075,8.964C2.03,9.304,2,9.648,2,10s0.03,0.696,0.075,1.036c0.418,0.106,0.833,
          0.226,1.244,0.361
          c0.1,0.033,0.207,0.138,0.25,0.237c0.249,0.571,0.468,1.156,0.723,1.725c0.07,0.156,0.072,0.265-0.008,0.405
          c-0.211,0.367-0.413,0.74-0.616,1.111c0.42,0.544,0.908,1.033,1.453,1.453c0.389-0.196,0.762-0.421,1.151-0.616
          c0.093-0.047,0.243-0.039,0.345,0.001c0.572,0.225,1.132,0.48,1.708,0.694c0.171,0.063,0.251,0.142,0.295,0.308
          c0.109,0.404,0.238,0.805,0.345,1.21C9.305,17.97,9.648,18,10,18c0.352,0,0.696-0.03,1.036-0.074
          c0.108-0.416,0.226-0.827,0.359-1.234c0.033-0.102,0.135-0.214,0.232-0.257c0.58-0.253,1.166-0.493,1.756-0.722
          c0.102-0.041,0.252-0.047,0.346,0c0.387,0.193,0.766,0.403,1.14,0.625c0.551-0.425,1.043-0.917,1.468-1.468
          c-0.215-0.363-0.406-0.74-0.621-1.104c-0.092-0.157-0.063-0.271,0.009-0.43c0.248-0.552,0.477-1.113,0.689-1.681
          c0.056-0.148,0.121-0.227,0.268-0.267c0.416-0.115,0.827-0.245,1.244-0.354C17.97,10.695,18,10.352,18,10
          C18,9.648,17.97,9.304,17.926,8.964z M10,13c-1.657,0-3-1.343-3-3c0-1.657,1.343-3,3-3c1.657,0,3,1.343,3,3
          C13,11.657,11.657,13,10,13z"
                />
            </g>
        ),
        props: {
            viewBox: '0 0 20 20',
        },
    },
    bold: {
        svg: (
            <g>
                <path
                    d="M11.461,7.036c0.391,0.269,0.708,0.606,0.952,1.013c0.243,0.406,0.365,0.884,0.365,1.433c0,0.66-0.139,1.217-0.416,1.672
          c-0.277,0.456-0.676,0.813-1.193,1.078c-0.549,0.279-1.182,0.479-1.898,0.595S7.711,13,6.742,13H0v-0.714
          c0.189-0.02,0.426-0.047,0.713-0.083C1,12.167,1.201,12.124,1.317,12.075c0.226-0.093,0.38-0.212,0.462-0.363
          c0.083-0.148,0.124-0.343,0.124-0.581V1.969c0-0.22-0.034-0.403-0.101-0.549C1.734,1.273,1.573,1.145,1.317,1.035
          C1.128,0.956,0.909,0.89,0.659,0.838C0.408,0.787,0.189,0.745,0,0.714V0h7.053c1.774,0,3.055,0.244,3.841,0.732
          c0.787,0.489,1.181,1.209,1.181,2.162c0,0.44-0.089,0.827-0.265,1.159c-0.178,0.333-0.431,0.622-0.76,0.866
          c-0.299,0.226-0.655,0.421-1.07,0.587C9.564,5.67,9.12,5.809,8.644,5.918v0.174c0.477,0.049,0.97,0.154,1.482,0.316
          C10.639,6.569,11.083,6.78,11.461,7.036z M8.516,3.124c0-0.721-0.203-1.283-0.608-1.686C7.502,1.035,6.894,0.833,6.083,0.833
          c-0.116,0-0.267,0.004-0.453,0.014C5.444,0.856,5.284,0.864,5.15,0.87v4.819h0.475c0.988,0,1.717-0.23,2.187-0.691
          C8.281,4.537,8.516,3.912,8.516,3.124z M9.156,9.367c0-0.905-0.269-1.599-0.806-2.082C7.814,6.801,7.043,6.559,6.037,6.559
          c-0.117,0-0.27,0.004-0.462,0.015C5.383,6.582,5.241,6.591,5.15,6.596v4.763c0.055,0.226,0.226,0.417,0.512,0.572
          c0.287,0.157,0.634,0.235,1.043,0.235c0.726,0,1.316-0.246,1.771-0.735C8.929,10.943,9.156,10.255,9.156,9.367z"
                />
            </g>
        ),
        props: {
            viewBox: '0 0 14 12.989',
        },
    },
    italic: {
        svg: (
            <g>
                <path
                    d="M8.841,0L8.703,0.604C8.516,0.61,8.269,0.638,7.963,0.687C7.659,0.736,7.434,0.781,7.288,0.825
          C7.032,0.904,6.849,1.026,6.74,1.19C6.63,1.356,6.551,1.537,6.502,1.735l-2.199,9.499c-0.012,0.041-0.021,0.089-0.027,0.144
          c-0.006,0.057-0.009,0.105-0.009,0.147c0,0.14,0.03,0.26,0.091,0.357c0.062,0.097,0.171,0.182,0.33,0.257
          c0.091,0.043,0.292,0.093,0.603,0.15c0.311,0.058,0.542,0.094,0.694,0.105L5.847,13H0l0.137-0.604
          c0.17-0.012,0.414-0.031,0.73-0.056c0.317-0.024,0.542-0.061,0.677-0.109c0.237-0.084,0.417-0.204,0.539-0.353
          c0.122-0.15,0.204-0.331,0.247-0.546l2.192-9.509c0.012-0.055,0.021-0.11,0.027-0.165c0.006-0.055,0.009-0.109,0.009-0.165
          c0-0.122-0.026-0.229-0.078-0.32c-0.052-0.092-0.16-0.174-0.324-0.248C3.992,0.852,3.769,0.783,3.485,0.719
          C3.202,0.655,2.993,0.617,2.859,0.604L2.997,0H8.841z"
                />
            </g>
        ),
        props: {
            viewBox: '0 0 10 12.989',
        },
    },
    move: {
        svg: (
            <g>
                <polygon points="11,6.501 9,9.252 7,12 7,6.501 7,1 9,3.751 		" />
                <rect x="4" y="5" width="4" height="3" />
            </g>
        ),
        props: {
            viewBox: '0 0 14 12.989',
        },
    },
    delete: {
        svg: (
            <g>
                <path
                    d="M9,1C4.582,1,1,4.582,1,9c0,4.417,3.582,8,8,8c4.419,0,8-3.583,8-8C17,4.582,13.419,1,9,1z M12.707,11.293
          c0.391,0.391,0.391,1.023,0,1.414C12.512,12.902,12.256,13,12,13s-0.512-0.098-0.707-0.293L9,10.414l-2.293,2.293
          C6.512,12.902,6.256,13,6,13s-0.512-0.098-0.707-0.293c-0.391-0.391-0.391-1.023,0-1.414L7.586,9L5.293,6.707
          c-0.391-0.391-0.391-1.023,0-1.414s1.023-0.391,1.414,0L9,7.586l2.293-2.293c0.391-0.391,1.023-0.391,1.414,0s0.391,1.023,0,1.414
          L10.414,9L12.707,11.293z"
                />
            </g>
        ),
        props: {
            viewBox: '0 0 20 20',
        },
    },
    align_center: {
        svg: (
            <g>
                <rect y="1" width="10" height="1" />
                <rect x="2" y="3" width="6" height="1" />
                <rect y="5" width="10" height="1" />
                <rect x="2" y="7" width="6" height="1" />
            </g>
        ),
        props: {
            viewBox: '0 0 10 9',
        },
    },
    align_left: {
        svg: (
            <g>
                <rect y="1" width="10" height="1" />
                <rect y="3" width="6" height="1" />
                <rect y="5" width="10" height="1" />
                <rect y="7" width="6" height="1" />
            </g>
        ),
        props: {
            viewBox: '0 0 10 9',
        },
    },
    link: {
        svg: (
            <g>
                <path
                    d="M18.427,12.954l-3.382-3.381c-0.742-0.74-2.031-0.737-2.766,0l-0.685,0.685l-0.852-0.852l0.685-0.685
	c0.762-0.762,0.762-2.003-0.001-2.767L8.045,2.574c-0.738-0.74-2.027-0.74-2.767,0L2.573,5.279C2.204,5.648,2,6.14,2,6.662
	s0.204,1.014,0.573,1.383l3.382,3.384C6.325,11.797,6.816,12,7.338,12c0.522,0,1.014-0.203,1.384-0.572l0.607-0.607l0.852,0.852
	l-0.606,0.606C9.204,12.647,9,13.139,9,13.66c0,0.523,0.203,1.015,0.573,1.385l3.382,3.383c0.369,0.369,0.86,0.571,1.383,0.571
	s1.014-0.202,1.384-0.572l2.706-2.706C19.188,14.958,19.188,13.718,18.427,12.954z M7.338,9.983l-3.351-3.29l2.644-2.706
	l3.382,3.321L9.329,7.992L8.65,7.313c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414l0.679,0.679L7.338,9.983z
	 M14.338,16.982l-3.351-3.289l0.601-0.614l0.678,0.678c0.195,0.195,0.451,0.293,0.707,0.293s0.512-0.098,0.707-0.293
	c0.391-0.391,0.391-1.023,0-1.414l-0.694-0.694l0.646-0.661l3.382,3.32L14.338,16.982z"
                />
            </g>
        ),
        props: {
            viewBox: '0 1 20 20',
            fontSize: '1.1em',
        },
    },
    logo: {
        svg: (
            <g>
                <path d="M16 0c-8.837 0-16 7.163-16 16s7.163 16 16 16 16-7.164 16-16c0-8.837-7.164-16-16-16zM14.73 5.717c0.622-0.622 1.63-0.622 2.252 0l3.714 3.713c0.622 0.622 0.622 1.631 0 2.253s-1.631 0.622-2.253 0l-3.713-3.713c-0.622-0.622-0.622-1.631 0-2.253zM17.275 26.283c-0.622 0.622-1.63 0.622-2.253 0l-3.678-3.678c-0.622-0.622-0.622-1.631 0-2.253s1.63-0.622 2.252 0.001l3.678 3.678c0.622 0.622 0.622 1.63 0.001 2.252zM21.781 21.778c-0.622 0.622-1.63 0.622-2.252 0l-9.31-9.31c-0.622-0.622-0.622-1.63 0.001-2.253s1.63-0.622 2.252 0l9.309 9.308c0.623 0.623 0.623 1.632 0.001 2.254z" />
            </g>
        ),
        props: {
            viewBox: '0 0 32 32',
        },
    },
    logout: {
        svg: (
            <g>
                <path d="M5.27 5a1.38 1.38 0 0 0-1.81-2.1 10 10 0 1 0 14.11 1 11.68 11.68 0 0 0-1.06-1.05 1.38 1.38 0 0 0-1.79 2.1A7.24 7.24 0 1 1 5.27 5z" />
                <path className="cls-1" d="M11.39 11.1V1.45a1.38 1.38 0 1 0-2.75 0v9.65a1.38 1.38 0 0 0 2.75 0z" />
            </g>
        ),
        props: {
            viewBox: '0 0 20.03 20.36',
        },
    },
    share: {
        svg: (
            <g>
                <path d="M9.92 8a2.16 2.16 0 0 0-1.59.7l-3.89-2a2.85 2.85 0 0 0 0-.41 2.46 2.46 0 0 0 0-.28l4-2a2.19 2.19 0 1 0-.68-1.59 2.07 2.07 0 0 0 0 .35l-3.93 2a2.14 2.14 0 0 0-1.53-.61 2.19 2.19 0 1 0 0 4.37A2.22 2.22 0 0 0 3.75 8l4 2v.21A2.19 2.19 0 1 0 9.92 8z" />
            </g>
        ),
        props: {
            viewBox: '0 0 12.28 12.81',
        },
    },
    preview: {
        svg: (
            <g>
                <path d="M7.92 4.15L1.24.3a.65.65 0 0 0-.9.24.63.63 0 0 0-.09.33v7.71a.67.67 0 0 0 .66.66.63.63 0 0 0 .33-.09L7.92 5.3a.66.66 0 0 0 .24-.91.69.69 0 0 0-.24-.24z" />
            </g>
        ),
        props: {
            viewBox: '0 0 8.59 9.53',
        },
    },
    export: {
        svg: (
            <g>
                <path d="M5.33 8.74a.69.69 0 0 0 .46.18.65.65 0 0 0 .46-.18l3-3a.65.65 0 0 0 0-.93.64.64 0 0 0-.92 0L6.45 6.69V1.05A.66.66 0 0 0 5.79.4a.65.65 0 0 0-.65.65v5.66l-1.88-1.9a.66.66 0 0 0-.93.93z" />
                <path d="M10.63 8.22a.66.66 0 0 0-.66.65v2H1.6v-2a.66.66 0 0 0-.66-.65.63.63 0 0 0-.64.64v2.63a.66.66 0 0 0 .65.66h9.69a.67.67 0 0 0 .66-.66V8.86a.66.66 0 0 0-.67-.64z" />
            </g>
        ),
        props: {
            viewBox: '0 0 11.55 12.33',
        },
    },
    customize: {
        svg: (
            <g>
                <path d="M2.42 4.87v.06c0 .01 0 0 0 0l.58.91a1.67 1.67 0 0 1 .06 1.93l-2.67 4A1.67 1.67 0 0 0 .24 13 1.59 1.59 0 0 0 1 14a1.64 1.64 0 0 0 1.23.1 1.56 1.56 0 0 0 .93-.83l1.93-4.5a1.67 1.67 0 0 1 1.57-1h.94l.67-1.3a.29.29 0 0 0 0-.22l1.57-3A.36.36 0 0 0 10 2.6L5.34.18a.36.36 0 0 0-.48.15l-1.58 3a.28.28 0 0 0-.16.16l-.68 1.33zM7.36 7L3.25 4.82l.34-.66 4.12 2.13zm-3 1.55L2.53 13a.91.91 0 0 1-.51.44.81.81 0 0 1-.68 0 1 1 0 0 1-.45-.53.85.85 0 0 1 .1-.68l2.66-4A2.34 2.34 0 0 0 3.87 6L6.1 7.11a2.41 2.41 0 0 0-1.7 1.4zM5.33 1l.67.32-.28.52a.36.36 0 0 0 .16.48.36.36 0 0 0 .48-.15l.27-.52.28.14-.14.29a.34.34 0 0 0 .15.48.36.36 0 0 0 .48-.15l.15-.29L9.3 3 8 5.64 4 3.57z" />
            </g>
        ),
        props: {
            viewBox: '0 0 10.41 14.38',
        },
    },
    allpitches: {
        svg: (
            <g>
                <path d="M7.55 8.44H1.21a.79.79 0 0 1-.79-.79V1.31a.79.79 0 0 1 .79-.79h6.34a.79.79 0 0 1 .8.79v6.34a.79.79 0 0 1-.8.79zM2 6.86h4.76V2.1H2zM16.63 8.44h-6.34a.79.79 0 0 1-.79-.79V1.31a.79.79 0 0 1 .79-.79h6.34a.79.79 0 0 1 .79.79v6.34a.79.79 0 0 1-.79.79zm-5.55-1.58h4.76V2.1h-4.76zM7.55 17.58H1.21a.79.79 0 0 1-.79-.79v-6.34a.79.79 0 0 1 .79-.79h6.34a.79.79 0 0 1 .8.79v6.34a.79.79 0 0 1-.8.79zM2 16h4.76v-4.76H2zM16.63 17.58h-6.34a.79.79 0 0 1-.79-.79v-6.34a.79.79 0 0 1 .79-.79h6.34a.79.79 0 0 1 .79.79v6.34a.79.79 0 0 1-.79.79zM11.08 16h4.76v-4.76h-4.76z" />
            </g>
        ),
        props: {
            viewBox: '0 0 17.85 18.06',
        },
    },
    fullscreen: {
        svg: (
            <g id="picto-fullscreen">
                <path
                    className="cls-1"
                    d="M15.84 8.49a.57.57 0 0 0-.56.56v6.14H9.14a.56.56 0 1 0 0 1.12h6.7a.58.58 0 0 0 .56-.56v-6.7a.58.58 0 0 0-.56-.56zM7.65.31H1a.57.57 0 0 0-.6.56v6.69a.57.57 0 0 0 .6.56.57.57 0 0 0 .56-.56V1.42h6.09a.57.57 0 0 0 .56-.55.57.57 0 0 0-.56-.56zM16.36.68a.62.62 0 0 0-.3-.3.38.38 0 0 0-.19 0h-3.79a.58.58 0 0 0-.56.56.57.57 0 0 0 .56.56h2.42L10.93 5a.53.53 0 0 0 0 .78.56.56 0 0 0 .4.15.56.56 0 0 0 .41-.15l3.54-3.53v2.37a.57.57 0 0 0 .56.56.58.58 0 0 0 .56-.56V.87a.27.27 0 0 0-.04-.19zM5.05 10.84l-3.54 3.53V12a.57.57 0 0 0-.51-.6.57.57 0 0 0-.6.6v3.8a.36.36 0 0 0 0 .19c.08.14.15.26.3.29a.38.38 0 0 0 .19 0h3.78a.56.56 0 0 0 0-1.12H2.26l3.53-3.53a.54.54 0 0 0 0-.78.51.51 0 0 0-.74-.01z"
                />
            </g>
        ),
        props: {
            viewBox: '0 0 16.56 16.81',
        },
    },
    homeWhite: {
        svg: (
            <g transform="translate(0,-952.36218)">
                <path
                    style={{
                        stroke: '#fff',
                        strokeWidth: 3,
                        strokeMiterlimit: 10,
                    }}
                    d="M39.8,960.8l-31,24c-0.5,0.4-0.8,1-0.8,1.6v44c0,1,1,2,2,2h21c1,0,2-1,2-2v-14c0-4.5,3.5-8,8-8s8,3.5,8,8v14
		c0,1,1,2,2,2h21c1,0,2-1,2-2v-44c0-0.6-0.3-1.2-0.8-1.6l-31-24C41.5,960.2,40.4,960.3,39.8,960.8z M41,964.9l29,22.5v41H53v-12
		c0-6.6-5.4-12-12-12s-12,5.4-12,12v12H12v-41L41,964.9z"
                />
            </g>
        ),
        props: {
            viewBox: '0 0 79 89',
        },
    },
    expired: {
        svg: (
            <g>
                <path
                    d="M86.4,53.6C91,30.9,76.3,8.8,53.6,4.3S8.8,14.4,4.3,37.1s10.1,44.8,32.8,49.3c5.4,1.1,11,1.1,16.5,0
        c7.5,10.6,22.2,13.2,32.8,5.7s13.2-22.2,5.7-32.8C90.6,57.1,88.6,55.2,86.4,53.6z M45.4,83.3c-20.9,0-37.9-16.9-37.9-37.8
        S24.5,7.6,45.4,7.6s37.9,16.9,37.9,37.8c0,0,0,0,0,0c0,2-0.2,4-0.5,6C71,46,57,51.1,51.5,62.9c-2.9,6.3-2.9,13.6,0,19.9
        C49.5,83.1,47.4,83.3,45.4,83.3L45.4,83.3z M72.9,92.4c-10.8,0-19.6-8.7-19.6-19.5c0-10.8,8.7-19.6,19.5-19.6S92.4,62,92.4,72.8
        c0,0,0,0,0,0C92.4,83.7,83.7,92.4,72.9,92.4L72.9,92.4z"
                />
                <path d="M72.8,79.7c-1.1,0-2-0.9-2-2V61.5c0-1.1,0.9-2,2-2s2,0.9,2,2v16.2C74.8,78.8,74,79.7,72.8,79.7z" />
                <path d="M45.9,17.6c-1.1,0-2,0.9-2,2v25.2H26.5c-1.1,0-2,0.9-2,2s0.9,2,2,2h19.4c1.1,0,2-0.9,2-2V19.6C47.9,18.5,47,17.6,45.9,17.6z" />
            </g>
        ),
        props: {
            viewBox: '0 0 100 99.5',
        },
    },
    browser: {
        svg: (
            <g>
                <path
                    className="st0"
                    d="M77.9,72.3l8.7-8.9c0.5-0.5,0.5-1.3,0-1.8c-0.5-0.5-1.3-0.5-1.8,0l-8.7,8.9l-8.9-8.7c-0.5-0.5-1.3-0.5-1.8,0
		c-0.5,0.5-0.5,1.3,0,1.8l8.9,8.7l-8.7,8.9c-0.5,0.5-0.5,1.3,0,1.8c0.2,0.2,0.6,0.4,0.9,0.4c0.3,0,0.6-0.1,0.9-0.4l8.7-8.9l8.9,8.7
		c0.2,0.2,0.6,0.4,0.9,0.4c0.3,0,0.6-0.1,0.9-0.4c0.5-0.5,0.5-1.3,0-1.8L77.9,72.3z"
                />
                <path
                    className="st0"
                    d="M87.6,51.5c0.3-2,0.5-4.1,0.5-6.2C88.1,21.4,68.7,2,44.9,2C21,2,1.6,21.4,1.6,45.3c0,23.9,19.4,43.3,43.2,43.3
		c4.5,0,8.9-0.7,13-2c4.3,5.4,10.9,8.8,18.3,8.8c12.9,0,23.4-10.5,23.4-23.4C99.5,63.2,94.7,55.5,87.6,51.5z M12.8,20.1
		C18.4,13,26.3,7.9,35.1,5.7l2.4-0.6L35.8,7c-2,2.1-3.8,4.9-5.3,8.4c-0.8,1.7-1.5,3.5-2.1,5.5l-0.2,0.5H11.8L12.8,20.1z M4.2,43.2
		c0.3-6.7,2.3-13.3,5.8-19l0.2-0.4h17.3l-0.2,0.9c-1.4,5.7-2.2,12-2.4,18.5l0,0.7H4.1L4.2,43.2z M9.8,66.1
		c-3.4-5.7-5.3-12.2-5.7-18.8l0-0.8h20.8l0,0.7c0.1,6.5,0.9,12.6,2.3,18.3l0.2,0.9h-1H10L9.8,66.1z M35.1,84.8
		c-8.9-2.2-16.9-7.4-22.5-14.7L11.7,69l16.5,0l0.2,0.5c0.6,2,1.4,4,2.2,5.7c1.5,3.4,3.3,6.3,5.3,8.4l1.7,1.8L35.1,84.8z M43.6,85.6
		l-1-0.3c-1.4-0.4-2.8-1.3-4.2-2.6c-2.1-2-4-4.9-5.7-8.5c-0.6-1.3-1.1-2.7-1.7-4.2l-0.3-1h12.8V85.6z M43.6,66.5H30l-0.1-0.6
		c-1.5-5.7-2.3-12.1-2.5-18.6l0-0.8h16.2V66.5z M43.6,44H27.4l0-0.8c0.1-6.6,1-13.2,2.6-18.9l0.1-0.6h13.5V44z M43.6,21.3H30.8
		l0.3-1c0.5-1.4,1-2.7,1.6-4c1.6-3.6,3.6-6.6,5.7-8.5c1.4-1.3,2.8-2.2,4.2-2.6l1-0.3V21.3z M76.1,48.5c-4.2,0-8.1,1.1-11.5,3
		c0.1-1.4,0.2-2.8,0.2-4.3l0-0.7h20.8l0,0.8c0,1-0.2,2-0.3,3C82.5,49.1,79.4,48.5,76.1,48.5z M79.8,24.2c3.5,5.7,5.5,12.3,5.8,19
		l0,0.8H64.8l0-0.7c-0.1-6.6-0.9-12.8-2.4-18.5l-0.2-0.9h17.3L79.8,24.2z M54.7,5.7c8.8,2.2,16.7,7.3,22.3,14.4l1,1.2H61.5l-0.2-0.5
		c-0.6-1.9-1.3-3.8-2.1-5.5c-1.5-3.4-3.3-6.3-5.3-8.4l-1.7-1.8L54.7,5.7z M46.1,4.9l1,0.3c1.4,0.4,2.8,1.3,4.2,2.6
		c2.1,2,4,4.9,5.7,8.5c0.6,1.2,1.1,2.6,1.6,4l0.3,1H46.1V4.9z M46.1,23.8h13.5l0.1,0.6c1.5,5.7,2.4,12.2,2.6,18.9l0,0.8H46.1V23.8z
		 M46.1,46.5h16.2l0,0.8c0,2-0.2,4-0.4,6c-4.2,3.2-7.3,7.9-8.6,13.2h-7.2V46.5z M47.1,85.3l-1,0.3V69h6.8c-0.1,1-0.2,1.9-0.2,2.9
		c0,2.7,0.5,5.2,1.3,7.6c-0.9,1.2-1.8,2.3-2.7,3.2C49.9,84,48.5,84.9,47.1,85.3z M54.7,84.8l-2.4,0.6l1.7-1.8
		c0.4-0.4,0.8-0.9,1.2-1.4c0.4,0.7,0.8,1.5,1.2,2.2C55.8,84.6,55.2,84.7,54.7,84.8z M76.1,92.8c-11.5,0-20.9-9.4-20.9-20.9
		c0-11.5,9.4-20.9,20.9-20.9S97,60.4,97,71.9C97,83.4,87.7,92.8,76.1,92.8z"
                />
            </g>
        ),
        props: {
            viewBox: '0 0 100 97.5',
        },
    },
    danger: {
        svg: (
            <g fill-rule="evenodd">
                <path
                    d="M13.416 4.417a2.002 2.002 0 0 0-2.832 0l-6.168 6.167a2.002 2.002 0 0 0 0 2.833l6.168 6.167a2.002 2.002 0 0 0 2.832 0l6.168-6.167a2.002 2.002 0 0 0 0-2.833l-6.168-6.167z"
                    fill="red"
                ></path>
                <path
                    d="M12 14a1 1 0 0 1-1-1V8a1 1 0 0 1 2 0v5a1 1 0 0 1-1 1m0 3a1 1 0 0 1 0-2 1 1 0 0 1 0 2"
                    fill="white"
                ></path>
            </g>
        ),
        props: {
            viewBox: '0 0 24 24',
            width: 48,
            height: 48,
        },
    },
};

export default icons;
