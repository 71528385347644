const localStorage = global.window.localStorage;

// @todo move to store
const auth = {
    set(token, userId) {
        localStorage.setItem('token', token);
        localStorage.setItem('userId', userId);
    },
    reset() {
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
    },
    getToken() {
        return localStorage.getItem('token');
    },
    getUserId() {
        return localStorage.getItem('userId');
    },
    loggedIn() {
        return !!localStorage.getItem('token') && !!localStorage.getItem('userId');
    },
};

export default auth;
