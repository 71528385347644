import actionTypes from './actionTypes';

const initialState = {
    bannerHeights: {},
};

export default function update(state = initialState, action) {
    switch (action.type) {
        case actionTypes.UPDATE_BANNER_HEIGHT:
            return {
                ...state,
                bannerHeights: {
                    ...state.bannerHeights,
                    ...action.payload,
                },
            };
        default:
            return state;
    }
}
