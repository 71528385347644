import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import config from 'app-config';

const DISABLE_ON_LOCALHOST = true;
const TRACES_SAMPLE_RATE = 0.25;

export function setUser(user) {
    Sentry.setUser(user);
}

export function initMonitoring(history) {
    const sentryConfig = {
        dsn: config.sentry.dns,
        normalizeDepth: 10,
        integrations: [
            new Integrations.BrowserTracing({
                routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
            }),
        ],

        tracesSampleRate: TRACES_SAMPLE_RATE,
    };
    if (DISABLE_ON_LOCALHOST) {
        config.denyUrls = 'localhost';
    }
    Sentry.init(sentryConfig);
}

export const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

export function capture(err, message) {
    if (DISABLE_ON_LOCALHOST && process.env.NODE_ENV === 'development') return;
    Sentry.captureException(err);
    if (message) {
        Sentry.captureMessage(message);
    }
}

export const ErrorBoundary = Sentry.ErrorBoundary;
