import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import ValidateButton from 'components/buttons/ValidateButton';
import { getCurrentLicense, isDurationValid } from 'services/licenseService';

import styles from '../../index.module.scss';

const getDisplayedDate = (date) => {
    if (!date) return '';
    const dateObject = new Date(date);
    return `${dateObject.toLocaleDateString('fr-FR')} ${dateObject.toLocaleTimeString('fr-FR')}`;
};

const getDisplayedDuration = (duration) => {
    return isDurationValid(duration) ? moment.duration(duration).humanize() : '';
};

const UserListItem = ({
    user,
    user: { firstName, lastName, email, suspended, id, storyCount, latestStoryUpdate, latestConnection, created },
    skip,
    history,
}) => {
    const {
        origin,
        startDate,
        duration,
        endDate,
        unlimitedAccess,
        prolongationStartDate,
        prolongationDuration,
    } = getCurrentLicense(user);

    const getLicenseDuration = () => {
        if (unlimitedAccess) return 'UNLIMITED';
        if (endDate) return getDisplayedDate(endDate);
        return getDisplayedDuration(duration);
    };
    return (
        <div className={styles.container}>
            <div className={`${styles.item} ${styles.dateItem}`}>{getDisplayedDate(created)}</div>
            <div className={`${styles.item} ${styles.mediumItem}`}>{origin}</div>
            <div className={`${styles.item} ${styles.dateItem}`}>{getDisplayedDate(startDate)}</div>
            <div className={`${styles.item} ${styles.mediumItem}`}>{getLicenseDuration()}</div>
            <div className={`${styles.item} ${styles.dateItem}`}>{getDisplayedDate(prolongationStartDate)}</div>
            <div className={`${styles.item} ${styles.mediumItem}`}>{getDisplayedDuration(prolongationDuration)}</div>
            <div className={`${styles.item} ${styles.dateItem}`}>{getDisplayedDate(latestConnection)}</div>
            <div className={`${styles.item} ${styles.firstNameItem}`}>{firstName}</div>
            <div className={`${styles.item} ${styles.nameItem}`}>{lastName}</div>
            <div className={styles.item}>{email}</div>
            <div className={`${styles.item} ${styles.itemShort}`}>
                {suspended && <div className={styles.inactive} />}
                {!suspended && <div className={styles.active} />}
            </div>
            <div className={`${styles.item} ${styles.numberItem}`}>{storyCount}</div>
            <div className={`${styles.item} ${styles.dateItem}`}>{getDisplayedDate(latestStoryUpdate)}</div>
            <ValidateButton
                className={styles.button}
                onClick={() =>
                    history.push({
                        pathname: `/account/${id}`,
                        state: { skip },
                    })
                }
            >
                Edit
            </ValidateButton>
        </div>
    );
};

UserListItem.propTypes = {
    user: PropTypes.object,
    history: PropTypes.object,
};

export default withRouter(UserListItem);
