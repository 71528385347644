import i18n from 'i18n';

export const validatePassword = (values, key) => {
    const errors = {};
    if (!values[key]) errors[key] = i18n.t('form.error.required_field');
    else if (values[key].length < 6) errors[key] = i18n.t('form.error.min_character', { charNumber: 6 });
    return errors;
};

export const validatePasswordConfirmation = (values, key) => {
    const errors = {};
    if (!values[key]) errors[key] = i18n.t('form.error.required_field');
    else if (values.password !== values.passwordConfirmation && values.password && values.password.length >= 6) {
        errors[key] = i18n.t('form.error.identical_passwords');
    }
    return errors;
};

export function required(values, key) {
    const errors = {};
    if (!values[key]) {
        errors[key] = i18n.t('form.error.required_field');
    }
    return errors;
}

export function email(values, key) {
    const errors = {};
    if (values[key] && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,12}$/i.test(values[key])) {
        errors[key] = i18n.t('form.error.invalid_email');
    }
    return errors;
}
