import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { findDOMNode } from 'react-dom';
import { DragSource, DropTarget } from 'react-dnd';
import { removeStripeRequest, setCurrentStripe } from 'common/actions';
import { Icon, StripeFactory } from 'components';
import classNames from 'classnames';

import styles from './index.module.scss';

const cardSource = {
    beginDrag(props) {
        return {
            id: props.stripe.id,
            index: props.index,
        };
    },
};

const cardTarget = {
    hover(props, monitor, component) {
        const dragIndex = monitor.getItem().index;
        const hoverIndex = props.index;

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
            return;
        }

        // Determine rectangle on screen
        const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

        // Get vertical middle
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

        // Determine mouse position
        const clientOffset = monitor.getClientOffset();

        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;

        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%

        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
            return;
        }

        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
            return;
        }
    },
    drop(props, monitor, component) {
        const dragIndex = monitor.getItem().index;
        const hoverIndex = props.index;

        props.moveCard(dragIndex, hoverIndex);
        return { moved: true };
    },
};

class DraggableStripe extends Component {
    static propTypes = {
        connectDragSource: PropTypes.func.isRequired,
        connectDropTarget: PropTypes.func.isRequired,
        stripe: PropTypes.object.isRequired,
        currentStripe: PropTypes.object.isRequired,
        setCurrentStripe: PropTypes.func.isRequired,
        removeStripe: PropTypes.func.isRequired,
        deletable: PropTypes.bool,
        draggable: PropTypes.bool,
        isDropTarget: PropTypes.bool,
    };

    removeStripe = (event) => {
        event.stopPropagation();
        this.props.removeStripe(this.props.stripe);
    };

    renderStripe() {
        const { stripe, deletable } = this.props;

        return (
            <div className={styles.stripe}>
                <div
                    onClick={() => this.props.setCurrentStripe(stripe)}
                    className={classNames(styles.content, {
                        [styles.contentSelected]: stripe.id === this.props.currentStripe.id,
                    })}
                >
                    {StripeFactory.sidebar(stripe)}
                    <div onClick={this.removeStripe} className="deleteIconContainer">
                        {deletable && <Icon iconName="delete" />}
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { connectDragSource, connectDropTarget, draggable, isDropTarget } = this.props;

        let stripe = this.renderStripe();

        if (isDropTarget) stripe = connectDropTarget(stripe);
        if (draggable) stripe = connectDragSource(stripe);

        return stripe;
    }
}

const mapStateToProps = (state) => ({
    currentStripe: state.currentStripe,
});

const mapDispatchToProps = {
    setCurrentStripe,
    removeStripe: removeStripeRequest,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    DropTarget('stripe', cardTarget, (connect, monitor) => ({
        connectDropTarget: connect.dropTarget(),
    }))(
        DragSource('stripe', cardSource, (connect, monitor) => ({
            connectDragSource: connect.dragSource(),
        }))(DraggableStripe)
    )
);
