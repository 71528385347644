import PlainStripe from './PlainStripe';

export default class Story {
    constructor() {
        this.stripes = [new PlainStripe()];
        this.facts = {};
        this.pitch = {};
        this.hasDesignAccess = false;
        this.published = false;
    }
}
