import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from './index.module.scss';

const AppLink = ({ message, link, to, className, linkClassName, external, target, onClick }) => (
    <div className={`${styles.container} ${className}`}>
        {message && <div className={styles.message}>{message}</div>}
        {!external && to && (
            <Link target={target} to={to} className={`${styles.link} ${linkClassName}`}>
                {link}
            </Link>
        )}
        {!external && onClick && (
            <div onClick={onClick} className={`${styles.link} ${linkClassName}`}>
                {link}
            </div>
        )}
        {external && (
            <a target={target} href={to} className={`${styles.link} ${linkClassName}`}>
                {link}
            </a>
        )}
    </div>
);

AppLink.propTypes = {
    message: PropTypes.string,
    className: PropTypes.string,
    linkClassName: PropTypes.string,
    target: PropTypes.string,
    to: PropTypes.string,
    link: PropTypes.string.isRequired,
    external: PropTypes.bool,
    onClick: PropTypes.func,
};

AppLink.defaultProps = {
    message: '',
    className: '',
    linkClassName: '',
    target: '',
    to: '',
    external: false,
    onClick: null,
};

export default AppLink;
